import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import FilledInButton from './variants/FilledInButton';
import styles from './ButtonComponent.module.css';
import OutlineButton from './variants/OutlineButton';
import ActionButton from './variants/ActionButton';
import TextButton from './variants/TextButton';
import IconActionButton from './variants/IconActionButton';

type variantType = 'FILLED_IN' | 'OUTLINE' | 'ACTION' | 'TEXT' | 'ACTION_ICON';
export type colorSchemeType = 'LIGHT' | 'DARK' | 'ACTION';
export type sizeType = 'BIG' | 'SMALL' | 'SUPER_SMALL';

export interface ButtonComponentProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  variant?: variantType;
  size?: sizeType;
  colorScheme?: colorSchemeType;
}

enum variants {
  FILLED_IN = 'FILLED_IN',
  OUTLINE = 'OUTLINE',
  ACTION = 'ACTION',
  TEXT = 'TEXT',
  ACTION_ICON = 'ACTION_ICON',
}

const ButtonComponent: FC<ButtonComponentProps> = ({
  children,
  variant = variants.FILLED_IN,
  size = 'BIG',
  colorScheme = 'DARK',
  ...props
}) => {
  const colorSchemeClasses = {
    [styles.dark]: colorScheme === 'DARK',
    [styles.light]: colorScheme === 'LIGHT',
    [styles.action]: colorScheme === 'ACTION',
  };

  const sizeClasses = {
    [styles.big]: size === 'BIG',
    [styles.small]: size === 'SMALL',
    [styles.superSmall]: size === 'SUPER_SMALL',
  };
  switch (variant) {
    case variants.FILLED_IN:
      return (
        <FilledInButton
          children={children}
          classes={{
            base: { [styles.base]: true },
            colorScheme: colorSchemeClasses,
            size: sizeClasses,
          }}
          {...props}
        />
      );
    case variants.OUTLINE:
      return (
        <OutlineButton
          children={children}
          classes={{
            base: { [styles.base]: true },
            colorScheme: colorSchemeClasses,
            size: sizeClasses,
          }}
          {...props}
        />
      );
    case variants.ACTION:
      return (
        <ActionButton
          children={children}
          size={size}
          colorScheme={colorScheme}
          classes={{
            base: { [styles.base]: true },
            colorScheme: colorSchemeClasses,
            size: sizeClasses,
          }}
          {...props}
        />
      );
    case variants.TEXT:
      return (
        <TextButton
          children={children}
          classes={{
            base: { [styles.base]: true },
            colorScheme: colorSchemeClasses,
            size: sizeClasses,
          }}
          {...props}
        />
      );
    case variants.ACTION_ICON:
      return (
        <IconActionButton
          children={children}
          size={size}
          classes={{
            base: { [styles.base]: true },
          }}
          {...props}
        />
      );
    default:
      return (
        <FilledInButton
          children={children}
          classes={{
            base: { [styles.base]: true },
            colorScheme: colorSchemeClasses,
            size: sizeClasses,
          }}
          {...props}
        />
      );
  }
};

export default ButtonComponent;
