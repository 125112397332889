import React, { FC, InputHTMLAttributes } from 'react';
import InputRounded from './InputRounded';
import InputUnderline from './InputUnderline';
import InputMultiline from './InputMultiline';
import { ClassNameMap } from '@mui/styles/withStyles';
import { FormControlClassKey } from '@mui/material';

export type themeType = 'LIGHT' | 'DARK';

type variantType = 'UNDERLINE' | 'ROUNDED' | 'MULTILINE';

enum Variants {
  UNDERLINE = 'UNDERLINE',
  ROUNDED = 'ROUNDED',
  MULTILINE = 'MULTILINE',
}

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  value?: string | number;
  variant?: variantType;
  description?: string;
  hideLabel?: boolean;
  hideDescription?: boolean;
  error?: string;
  formControlClasses?: Partial<ClassNameMap<FormControlClassKey>>;
}
const InputField: FC<InputProps> = ({
  name,
  label,
  value,
  variant = 'UNDERLINE',
  hideLabel = false,
  hideDescription = false,
  error,
  description,
  ...rest
}) => {
  switch (variant) {
    case Variants.UNDERLINE:
      return (
        <InputUnderline
          name={name}
          label={label}
          value={value}
          error={error}
          description={description}
          {...rest}
        />
      );
    case Variants.ROUNDED:
      return (
        <InputRounded
          name={name}
          label={label}
          value={value}
          error={error}
          hideLabel={hideLabel}
          {...rest}
        />
      );
    case Variants.MULTILINE:
      return (
        <InputMultiline
          name={name}
          label={label}
          value={value}
          error={error}
          description={description}
          {...rest}
        />
      );
    default:
      return (
        <InputUnderline
          name={name}
          label={label}
          value={value}
          error={error}
          description={description}
          {...rest}
        />
      );
  }
};

export default InputField;
