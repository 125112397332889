import { ITokenProvider } from 'shared';
import { injectable } from 'tsyringe';

@injectable()
export class AuthenticationService {
  constructor(private tokenProvider: ITokenProvider) {}

  async isAuthenticated(): Promise<boolean> {
    return await this.tokenProvider.isAuthenticated();
  }
}
