import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroup,
} from '@mui/material';
import classNames from 'classnames';
import { FC, InputHTMLAttributes, ReactElement } from 'react';
import styles from './RadioButton.module.css';
import RadioOptionComponent from './RadioOption';

export type themeType = 'LIGHT' | 'DARK';

type directionType = 'HORIZONTAL' | 'VERTICAL';

export interface RadioOption {
  label: string;
  value: string | number;
  isDisabled: boolean;
}

interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string | ReactElement;
  name: string;
  options: RadioOption[];
  value?: string | number;
  theme?: themeType;
  hideLabel?: boolean;
  direction?: directionType;
  error?: string;
  description?: string;
}

const RadioButton: FC<RadioButtonProps> = ({
  label,
  name,
  options,
  value,
  theme = 'LIGHT',
  hideLabel,
  direction = 'VERTICAL',
  error,
  description,
  ...rest
}) => {
  const themeClasses = {
    [styles.light]: theme === 'LIGHT',
    [styles.dark]: theme === 'DARK',
  };

  const directionClasses = {
    [styles.horizontal]: direction === 'HORIZONTAL',
    [styles.vertical]: direction === 'VERTICAL',
  };

  return (
    <FormControl error={!!error}>
      <FormLabel
        classes={{
          root: classNames(
            styles.root,
            { [styles.hideLabel]: hideLabel },
            themeClasses
          ),
          error: styles.error,
        }}
      >
        {label}
      </FormLabel>
      <RadioGroup
        aria-label={name}
        name={name}
        value={value}
        classes={{
          root: classNames(directionClasses),
        }}
        {...rest}
      >
        {options.map(({ label, value, isDisabled }) => (
          <FormControlLabel
            key={value}
            label={label}
            value={value}
            control={
              <RadioOptionComponent
                {...{ value, label, isDisabled, theme, error }}
              />
            }
            classes={{
              label: classNames(styles.root, themeClasses),
              root: styles.row,
            }}
          />
        ))}
      </RadioGroup>
      {error && <FormHelperText>{error}</FormHelperText>}
      {description && <FormHelperText>{description}</FormHelperText>}
    </FormControl>
  );
};

export default RadioButton;
