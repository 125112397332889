import React, { FC, ReactNode } from 'react';
import styles from './StepWithNavigation.module.css';
import StepNavigationBar, { StepNavigationBarProps } from './StepNavigationBar';

export interface StepWithNavigationProps extends StepNavigationBarProps {
  children: ReactNode;
  hideNavigation?: boolean;
}

const StepWithNavigation: FC<StepWithNavigationProps> = (props) => {
  const { children, hideNavigation, ...otherProps } = props;
  return (
    <>
      <div className={styles.content}>{children}</div>
      {!hideNavigation && <StepNavigationBar {...otherProps} />}
    </>
  );
};

export default StepWithNavigation;
