import {
  ReactNode,
  useState,
  createContext,
  forwardRef,
  useImperativeHandle,
} from 'react';
import styles from './CardWithSteps.module.css';
import Loading from '../Loading';
import CardTitle from './CardTitle';

export interface StepComponent {
  title?: string;
  subheader?: string;
  component: ReactNode;
}

interface CardWithStepsProps {
  steps: StepComponent[];
  currentStep?: number;
  stepUnit: string;
  onCancel?: () => void;
  onFinishSteps: () => void;
  loadingDescription?: string;
  showLoadingScreen?: boolean;
}

export interface StepsController {
  goNext: () => void;
  goBack: () => void;
  goToLast: () => void;
  goToStep: (x: number) => void;
  finish: () => void;
  onCancel: () => void;
  hasNextStep: boolean;
  hasPreviousStep: boolean;
}

export const StepsControllerContext = createContext<
  StepsController | undefined
>(undefined);

const CardWithSteps = forwardRef<StepsController, CardWithStepsProps>(
  (
    {
      steps,
      stepUnit,
      showLoadingScreen = false,
      loadingDescription,
      onCancel,
      onFinishSteps,
    }: CardWithStepsProps,
    ref
  ) => {
    const [currentStep, setCurrentStep] = useState(0);
    const { title, component, subheader } = steps[currentStep];

    const hasNextStep = currentStep < steps.length - 1;

    const goNext = () => {
      if (hasNextStep) {
        setCurrentStep(currentStep + 1);
      }
    };

    const hasPreviousStep = currentStep > 0;
    const goBack = () => {
      if (hasPreviousStep) {
        setCurrentStep(currentStep - 1);
      }
    };

    const goToLast = () => {
      const last = steps.length - 1;

      if (currentStep === last) {
        return;
      }

      goToStep(last);
    };

    const goToStep = (x: number) => {
      if (x >= 0 && x < steps.length) {
        setCurrentStep(x);
      }
    };

    const stepsControl = {
      goNext,
      goBack,
      hasNextStep,
      hasPreviousStep,
      goToLast,
      goToStep,
      onCancel: () => onCancel && onCancel(),
      finish: onFinishSteps,
    };
    useImperativeHandle(ref, () => stepsControl);

    return (
      <StepsControllerContext.Provider value={stepsControl}>
        <div className={styles.card}>
          <Loading
            isLoading={showLoadingScreen}
            type='BIG'
            verticalPosition={'center'}
            description={loadingDescription}
          >
            {title && (
              <CardTitle title={title} subheader={subheader}></CardTitle>
            )}
            {component}
          </Loading>
        </div>
      </StepsControllerContext.Provider>
    );
  }
);

export default CardWithSteps;
