import { CheckCircle } from 'phosphor-react';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import {
  IFileIdDto,
  Logger,
  MedialFileAction,
  ProjectDesignFilesProvider,
  useService,
} from 'shared';
import ButtonComponent from '../common/components/buttons/ButtonComponent';
import CardTitle from '../common/components/CardWithSteps/CardTitle';
import styles from './OrderConfirmation.module.css';

interface OrderConfirmationProps {
  file?: IFileIdDto;
  projectId: number;
}

const OrderConfirmation: FC<OrderConfirmationProps> = ({ file, projectId }) => {
  const { t } = useTranslation();

  const logger = useService<Logger>(Logger);
  const fileProvider = useService<ProjectDesignFilesProvider>(
    ProjectDesignFilesProvider
  );

  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const loadVisualization = async (fileId?: IFileIdDto) => {
      if (!fileId || !projectId) {
        return;
      }

      try {
        const file = await fileProvider.getFreshFileDownloadData(
          projectId,
          fileId.blobId,
          MedialFileAction.Preview
        );
        if (file && imageRef.current) {
          imageRef.current.src = file.uri;
        } else {
          throw new Error(
            `Sas token for desing file ${JSON.stringify(
              fileId.fileName
            )} is empty!`
          );
        }
      } catch (e) {
        logger.logException(e);
      }
    };

    loadVisualization(file);
  }, [file]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      <div className={styles.confirmation}>
        <CardTitle
          title={t('order.confirmation.orderConfirmed', 'Order confirmed!')}
          icon={CheckCircle}
        />
        <div className={styles.description}>
          {t(
            'order.confirmation.description',
            'We will let you know once your invoice is ready. It will be send to your email. You can access all order details from dashboard page.'
          )}
        </div>
        {file && (
          <img ref={imageRef} alt={file.fileName} className={styles.image} />
        )}
      </div>
      <NavLink to={`/project/${projectId}/order`}>
        <ButtonComponent variant='FILLED_IN' colorScheme='DARK' size='SMALL'>
          {t('order.confirmation.goToOrderDetails', 'Go to your order details')}
        </ButtonComponent>
      </NavLink>
    </div>
  );
};

export default OrderConfirmation;
