import { FC } from 'react';
import { Checkbox, List, ListItem } from '@mui/material';
import styles from './Designers.module.css';
import { useTranslation } from 'react-i18next';
import ProfilePicturePlaceHolder from '../../../../src/common/components/ProfilePicturePlaceholder';
import { DesignersStore } from '../../../../src/meeting/BookMeetingStore';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Info, CheckCircle } from 'phosphor-react';

interface DesignersProps {
  designersStore: DesignersStore;
}

const Designers: FC<DesignersProps> = observer(({ designersStore }) => {
  const { t } = useTranslation();

  return (
    <div>
      <h4 className={styles.title}>{t('meeting.designers.title')}</h4>
      <div className={styles.subTitleRoot}>
        <Info size={24} />
        <span className={styles.subTitleText}>
          {t('meeting.designers.subTitle')}
        </span>
      </div>
      <List component={'div'} className={styles.list}>
        <ListItem
          key={'evi_all'}
          role={undefined}
          dense
          button
          onClick={() => designersStore.setSelectedDesigner(null)}
          className={classNames(styles.item, {
            [styles.selected]: designersStore.selectedDesigner === null,
          })}
        >
          <Checkbox
            edge='start'
            checked={designersStore.selectedDesigner === null}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': 'checkbox-list-label-evi-all' }}
            className={styles.check}
            icon={<span />}
            checkedIcon={
              <CheckCircle size={24} weight='fill' className={styles.checked} />
            }
          />
          <ProfilePicturePlaceHolder
            size={'MEDIUM'}
            firstName={t('meeting.designers.any')}
          />
          <span className={styles.itemName}>{t('meeting.designers.any')}</span>
        </ListItem>
        {designersStore.designers.map((item) => {
          const labelId = `checkbox-list-label-${item.externalId}`;
          const itemStyle = classNames(styles.item, {
            [styles.selected]:
              designersStore.selectedDesigner === null
                ? false
                : designersStore.selectedDesigner?.externalId ===
                  item.externalId,
          });

          return (
            <ListItem
              key={item.externalId}
              role={undefined}
              dense
              button
              onClick={() => designersStore.setSelectedDesigner(item)}
              className={itemStyle}
            >
              <Checkbox
                edge='start'
                checked={
                  designersStore.selectedDesigner === null
                    ? false
                    : designersStore.selectedDesigner?.externalId ===
                      item.externalId
                }
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
                className={styles.check}
                icon={<span />}
                checkedIcon={
                  <CheckCircle
                    size={24}
                    weight='fill'
                    className={styles.checked}
                  />
                }
              />
              <ProfilePicturePlaceHolder
                size={'MEDIUM'}
                firstName={item.displayName}
                email={item.email}
              />
              <span className={styles.itemName}>{item.displayName}</span>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
});

export default Designers;
