import { makeAutoObservable } from 'mobx';
import { ILegalDocumentsDto, Logger, LegalConfigurationService } from 'shared';
import { singleton } from 'tsyringe';

@singleton()
export class LegalDocumentStore {
  constructor(
    private privacyPolicyService: LegalConfigurationService,
    private logger: Logger
  ) {
    makeAutoObservable(this);
  }

  legalDocuments?: ILegalDocumentsDto;

  loadingState: 'not-initialized' | 'loading' | 'loaded' | 'error' =
    'not-initialized';

  *ensureDocumentLoaded() {
    if (
      this.loadingState === 'not-initialized' ||
      this.loadingState === 'error'
    ) {
      try {
        this.legalDocuments =
          yield this.privacyPolicyService.getLegalDocuments();
        this.loadingState = 'loaded';
      } catch (error) {
        this.logger.logException(error);
        this.loadingState = 'error';
      }
    }
  }
}
