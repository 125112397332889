import { useCallback } from 'react';
import { useState } from 'react';
import { FC } from 'react';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import { useTranslation } from 'react-i18next';
import ActionButtons from '../common/components/modals/ActionButtons';
import styles from './CropProfilePicture.module.css';
import getCroppedImg from './cropImage';
import { useEffect } from 'react';
import { observer } from 'mobx-react';

interface CropProfilePictureProps {
  picture: File;
  onCancel: () => void;
  onSubmit: (picture: File) => void;
}

const CropProfilePicture: FC<CropProfilePictureProps> = observer(
  ({ picture, onCancel, onSubmit }) => {
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(
      null
    );
    const [selectedPictureUrl, setSelectedPictureUrl] = useState<string>('');

    useEffect(() => {
      setSelectedPictureUrl(URL.createObjectURL(picture));

      return () => {
        URL.revokeObjectURL(selectedPictureUrl);
      };
    }, [picture]);

    const onCropComplete = useCallback(
      (croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels);
      },
      []
    );

    const getCroppedImage = useCallback(async () => {
      const croppedImage = await getCroppedImg(
        selectedPictureUrl,
        picture.name,
        croppedAreaPixels!
      );
      onSubmit(croppedImage);
    }, [croppedAreaPixels, selectedPictureUrl, onSubmit, picture.name]);

    const { t } = useTranslation();
    return (
      <div className={styles.container}>
        <div className={styles.top}>
          <h2 className={styles.title}>
            {t('userSettings.profile.picture.modal.add')}
          </h2>
          <div className={styles.horizontalLine} />
        </div>
        <div className={styles.pictureContainer}>
          <p className={styles.text}>
            {t('userSettings.profile.picture.modal.crop')}
          </p>
          <div className={styles.cropContainer}>
            <Cropper
              image={selectedPictureUrl}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropShape='round'
              showGrid={false}
            />
          </div>
        </div>
        <ActionButtons
          onCancel={onCancel}
          onSubmit={getCroppedImage}
          canSubmit={true}
          onSubmitText={t('userSettings.profile.picture.modal.save')}
        />
      </div>
    );
  }
);

export default CropProfilePicture;
