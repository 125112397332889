import { useAuth0 } from '@auth0/auth0-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../../../common/components/buttons/ButtonComponent';
import styles from './CreateAccountCallToAction.module.css';

type CreateAccountCallToActionProps = {
  email?: string;
};

const CreateAccountCallToAction: FC<CreateAccountCallToActionProps> = ({
  email,
}) => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.caption}>
        {isAuthenticated ? (
          <p>
            {t('meeting.summary.sentEmail')}
            <b className={styles.bold}>{email}</b>
          </p>
        ) : (
          <>
            <p>{t('common.createAccountAndBookAMeeting')}</p>
            <ButtonComponent
              colorScheme='DARK'
              size='SMALL'
              className={styles.logout}
              onClick={() =>
                loginWithRedirect({ login_hint: email, screen_hint: 'signup' })
              }
            >
              {t('common.createAccount')}
            </ButtonComponent>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateAccountCallToAction;
