import { makeAutoObservable } from 'mobx';
import {
  AvatarStore,
  DesignStatus,
  IFileDto,
  IIdeaDto,
  IIterationDto,
  Logger,
} from 'shared';
import { Moment } from 'moment';

export class IterationStore {
  id: number = 0;
  uId: string;
  isInOrderableState: boolean;
  name: string;
  createdAt: Moment;
  lastChangedAt: Moment;
  status: DesignStatus;
  idea: IIdeaDto;
  offerValidUntil?: Moment;
  estimatedDeliveryTo: Moment;
  estimatedDeliveryFrom: Moment;
  offeringPrice: number;
  currencyCode: string;
  taxInOfferingPrice: number;
  isOfferExpired: boolean;

  plans: IFileDto[];
  documents: IFileDto[];
  priceDetails: IFileDto[];
  isCommentSectionExpanded = false;

  visualizationPreviewImage?: IFileDto;
  visualizationPreviewLink?: string;

  constructor(
    private avatarsStore: AvatarStore,
    private logger: Logger,
    iterationDto: IIterationDto,
    projectId: number
  ) {
    makeAutoObservable(this);

    this.id = iterationDto.id;
    this.uId = iterationDto.uId;
    this.isInOrderableState = iterationDto.isInOrderableState;
    this.name = iterationDto.name;
    this.createdAt = iterationDto.createdAt;
    this.lastChangedAt = iterationDto.lastChangedAt;
    this.status = iterationDto.status;
    this.idea = iterationDto.idea;
    this.offerValidUntil = iterationDto.offerValidUntil;
    this.estimatedDeliveryTo = iterationDto.estimatedDeliveryTo;
    this.estimatedDeliveryFrom = iterationDto.estimatedDeliveryFrom;
    this.offeringPrice = iterationDto.offeringPrice;
    this.taxInOfferingPrice = iterationDto.taxInOfferingPrice;
    this.currencyCode = iterationDto.currencyCode;
    this.isOfferExpired = iterationDto.isOfferExpired;
    this.plans = iterationDto.plans;
    this.documents = iterationDto.documents;
    this.priceDetails = iterationDto.priceDetails;
    this.visualizationPreviewImage = iterationDto.visualizationPreviewImage;
    this.visualizationPreviewLink = iterationDto.visualizationPreviewLink;
  }
}
