import { ChangeEvent, FC } from 'react';
import ModalWrapper from '../../common/components/modals/ModalWrapper';
import ActionModal from '../designs/ActionModal';
import { useTranslation } from 'react-i18next';
import DropdownComponent from '../../common/components/dropdowns/DropdownComponent';
import { ProjectStore } from '../../project/ProjectStore';
import { useHistory } from 'react-router';
import styles from './ChangeProjectModal.module.css';

interface ChangeProjectModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  projectStore: ProjectStore;
}

const ChangeProjectModal: FC<ChangeProjectModalProps> = ({
  isOpen,
  setIsOpen,
  projectStore,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const options =
    projectStore?.myProjects.map((p) => ({
      value: p.id,
      label: p.name,
    })) ?? [];

  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    history.replace({ search: `projectId=${event.target.value}` });
    setIsOpen(false);
  };

  return (
    <ModalWrapper
      open={isOpen}
      onClose={() => setIsOpen(false)}
      sizeVariant='SMALL'
    >
      <ActionModal
        onCancel={() => setIsOpen(false)}
        onSubmit={() => setIsOpen(false)}
        title={t('project.changeProject.title')}
      >
        <div className={styles.root}>
          <DropdownComponent
            name='project'
            theme='LIGHT'
            label={t('dashboard.project.dropdown.label')}
            value={projectStore?.project?.id}
            options={options}
            onChange={onChange}
          />
        </div>
      </ActionModal>
    </ModalWrapper>
  );
};

export default ChangeProjectModal;
