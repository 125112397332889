import { FormControl, FormControlLabel, Switch } from '@mui/material';
import { FC, InputHTMLAttributes, ReactElement } from 'react';
import styles from './ToggleComponent.module.css';
import classNames from 'classnames';

type themeType = 'LIGHT' | 'DARK';

interface ToggleProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string | ReactElement;
  name: string;
  checked?: boolean;
  theme?: themeType;
}

const ToggleComponent: FC<ToggleProps> = ({
  label,
  name,
  checked,
  theme = 'LIGHT',
  ...rest
}) => {
  const themeClasses = {
    [styles.light]: theme === 'LIGHT',
    [styles.dark]: theme === 'DARK',
  };

  return (
    <FormControl
      classes={{
        root: styles.formControlRoot,
      }}
    >
      <FormControlLabel
        control={
          <Switch
            name={name}
            id={name}
            inputProps={{ ...rest }}
            classes={{
              root: classNames(styles.root, themeClasses),
              track: classNames(styles.track, themeClasses),
              thumb: classNames(styles.thumb, themeClasses),
              checked: classNames(styles.checked, themeClasses),
              colorSecondary: styles.colorSecondary,
              sizeSmall: styles.small,
            }}
            size='small'
          />
        }
        checked={checked}
        label={label}
        labelPlacement='start'
        classes={{
          root: classNames(styles.labelRoot, themeClasses),
          label: styles.label,
          labelPlacementStart: classNames(styles.placement, themeClasses),
        }}
      />
    </FormControl>
  );
};

export default ToggleComponent;
