import { FormControl, Input, MenuItem, Select } from '@mui/material';
import classNames from 'classnames';
import { FC, useRef, useEffect } from 'react';
import { MeetingType } from 'shared';
import ValidationPopup from '../../../common/components/ValidationPopup';
import styles from './ChooseLocation.module.css';
import { CaretDown } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { ChooseLocationStore } from '../../BookMeetingStore';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import RadioButton from '../../../common/components/radio-buttons/RadioButton';

interface ChooseLocationProps {
  chooseLocationStore: ChooseLocationStore;
  isMeetingTypePopupShown: boolean;
  onSetIsMeetingTypePopupShown: (v: boolean) => void;
  isLocationPopupShown: boolean;
  onSetIsLocationPopupShown: (v: boolean) => void;
}

export interface Meeting {
  meetingType: MeetingType;
  name: string;
  description: string;
}

const ChooseLocation: FC<ChooseLocationProps> = observer(
  ({
    chooseLocationStore,
    isMeetingTypePopupShown,
    onSetIsMeetingTypePopupShown,
    isLocationPopupShown,
    onSetIsLocationPopupShown,
  }) => {
    const { t } = useTranslation();

    useEffect(() => {
      flowResult(chooseLocationStore.fetchLocation());
    }, [chooseLocationStore]);

    const meetingContainer = useRef(null);
    const formContainer = useRef(null);

    return (
      <div>
        <div
          className={classNames(styles.meetings, {
            [styles.error]: isLocationPopupShown || isMeetingTypePopupShown,
          })}
          ref={meetingContainer}
        >
          <RadioButton
            onChange={(e) =>
              chooseLocationStore.setSelectedMeeting(
                e.target.value as MeetingType
              )
            }
            name={'metingRadio'}
            direction={'HORIZONTAL'}
            label={''}
            theme={'LIGHT'}
            value={chooseLocationStore.selectedMeeting ?? ''}
            defaultValue={''}
            options={chooseLocationStore.meetings.map((x) => {
              return {
                label: x.name,
                value: x.meetingType,
                isDisabled: false,
              };
            })}
          />
        </div>
        <ValidationPopup
          error={t('meeting.validation.type')}
          isOpen={isMeetingTypePopupShown}
          onClose={() => onSetIsMeetingTypePopupShown(false)}
          element={meetingContainer.current!}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          arrowPosition={'TOP'}
        />
        <div className={styles.locations}>
          {chooseLocationStore.selectedMeeting === MeetingType.Showroom && (
            <FormControl className={styles.locationForm} ref={formContainer}>
              <ValidationPopup
                error={t('meeting.validation.location')}
                isOpen={isLocationPopupShown}
                onClose={() => onSetIsLocationPopupShown(false)}
                element={formContainer.current!}
              />
              <Select
                classes={{
                  select: styles.selectMenu,
                  icon: styles.selectIcon,
                }}
                IconComponent={CaretDown}
                value={chooseLocationStore.selectedLocation}
                onChange={(event) =>
                  chooseLocationStore.setSelectedLocation(
                    event.target.value as string
                  )
                }
                displayEmpty
                placeholder={t('meeting.location.choose')}
                input={
                  <Input
                    classes={{
                      root: styles.selectRoot,
                    }}
                  />
                }
                MenuProps={{
                  classes: {
                    paper: styles.selectDropdown,
                  },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                }}
              >
                {chooseLocationStore.locations?.map((x, index) => (
                  <MenuItem value={x.address} key={index}>
                    <div className={styles.selectItem}>
                      <span className={styles.selectText}>{x.address} </span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
      </div>
    );
  }
);

export default ChooseLocation;
