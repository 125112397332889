import React from 'react';
import styles from './LanguageSelector.module.css';
import { useLanguages } from '../hooks/useLanguages';
import { ButtonBase } from '@mui/material';

const LanguageSelector = () => {
  const { languages, language, changeLanguage } = useLanguages();

  return (
    <div className={styles.wrapper}>
      {Object.keys(languages).map((lang, index) => (
        <React.Fragment key={lang}>
          {index > 0 && <span className={styles.separator}></span>}
          {lang === language ? (
            <span className={styles.langOption}>{lang}</span>
          ) : (
            <ButtonBase
              classes={{ root: styles.langOption }}
              onClick={() => changeLanguage(lang)}
            >
              {lang}
            </ButtonBase>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default LanguageSelector;
