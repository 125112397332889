import classNames from 'classnames';
import moment from 'moment';
import {
  Check,
  Clock,
  DownloadSimple,
  File,
  WarningCircle,
} from 'phosphor-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IInvoiceDto, InvoiceStatus } from 'shared';
import styles from './OrderInvoice.module.css';

interface OrderInvoiceProps extends IInvoiceDto {
  handleDownload?: () => void;
}

const OrderInvoice: FC<OrderInvoiceProps> = ({
  dueDate,
  paidAt,
  status,
  file,
  handleDownload,
}) => {
  const { t } = useTranslation();

  const isPaymentOverdue =
    status === InvoiceStatus.NotPaid && moment().isAfter(dueDate);

  const paymentStateIcon =
    status === InvoiceStatus.Paid ? (
      <Check size={14} />
    ) : isPaymentOverdue ? (
      <WarningCircle size={14} />
    ) : (
      <Clock size={14} />
    );

  return (
    <div>
      <div className={styles.header}>
        <span>
          {t(
            'project.order.invoice.dueDate',
            'Due date: {{dueDate, DD/MM/YYYY}}',
            { dueDate }
          )}
        </span>
        <div
          className={classNames(styles.paymentState, {
            [styles.paid]: status === InvoiceStatus.Paid,
            [styles.overdue]: isPaymentOverdue,
          })}
        >
          {paymentStateIcon}
          <span>
            {isPaymentOverdue
              ? t('project.order.invoice.payment.overdue', 'Payment overdue')
              : t(`project.order.invoice.payment.${status}`, status)}
            {status === InvoiceStatus.Paid &&
              paidAt &&
              t('project.order.invoice.paidAt', ' {{paidAt, DD/MM/YYYY }}', {
                paidAt,
              })}
          </span>
        </div>
      </div>
      <div className={styles.invoiceFile}>
        <div className={styles.thumbnail}>
          <File color={'white'} size={24} />
        </div>
        <span className={styles.fileName}>{file.fileId.fileName}</span>
        <DownloadSimple
          size={26}
          className={styles.downloadIcon}
          onClick={handleDownload}
        />
      </div>
    </div>
  );
};

export default OrderInvoice;
