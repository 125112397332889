import classNames from 'classnames';
import { EyeClosed, IconProps } from 'phosphor-react';
import { ComponentType, FC } from 'react';
import styles from './EmptyStateComponent.module.css';

interface EmptyStateComponentProps {
  title?: string;
  icon?: ComponentType<IconProps & React.RefAttributes<SVGSVGElement>>;
}

const EmptyStateComponent: FC<EmptyStateComponentProps> = ({
  title,
  children,
  icon: Icon = EyeClosed,
}) => {
  return (
    <div className={styles.container}>
      <Icon className={styles.icon} preserveAspectRatio='none' />
      {title && (
        <h2 className={classNames(styles.title, { [styles.hasIcon]: Icon })}>
          {title}
        </h2>
      )}
      {children}
    </div>
  );
};

export default EmptyStateComponent;
