import { Field } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxComponent from '../common/components/checkboxes/CheckboxComponent';
import CheckoutComponent, { CheckoutComponentProps } from './CheckoutComponent';
import styles from './Delivery.module.css';
import { TFunction } from 'i18next';
import FormikInputField from '../common/components/formik/FormikInputField';

export const getDeliveryValidationSchema = (t: TFunction) => {
  return {
    buyerDetails: Yup.object().shape({
      firstName: Yup.string().required(
        t(
          'order.delivery.buyerDetails.firstNameRequired',
          'First name is required!'
        )
      ),
      lastName: Yup.string().required(
        t(
          'order.delivery.buyerDetails.lastNameRequired',
          'Last name is required!'
        )
      ),
      email: Yup.string().required(
        t(
          'order.delivery.buyerDetails.emailRequired',
          'E-mail address is required!'
        )
      ),
      phoneNumber: Yup.string().required(
        t(
          'order.delivery.buyerDetails.phoneNumberRequired',
          'Phone number is required!'
        )
      ),
    }),
    deliveryAddress: Yup.object().shape({
      streetAddress: Yup.string().required(
        t(
          'order.delivery.address.streetAddressRequired',
          'Street address is required!'
        )
      ),
      country: Yup.string().required(
        t('order.delivery.address.countryRequired', 'Country is required!')
      ),
      city: Yup.string().required(
        t('order.delivery.address.cityRequired', 'City is required!')
      ),
      postalCode: Yup.string().required(
        t(
          'order.delivery.address.postalCodeRequired',
          'Postal code is required!'
        )
      ),
    }),
    billingAddressEqualsDeliveryAddress: Yup.boolean(),
    billingAddress: Yup.object().when('billingAddressEqualsDeliveryAddress', {
      is: false,
      then: Yup.object().shape({
        streetAddress: Yup.string().required(
          t(
            'order.delivery.address.streetAddressRequired',
            'Street address is required!'
          )
        ),
        country: Yup.string().required(
          t('order.delivery.address.countryRequired', 'Country is required!')
        ),
        city: Yup.string().required(
          t('order.delivery.address.cityRequired', 'City is required!')
        ),
        postalCode: Yup.string().required(
          t(
            'order.delivery.address.postalCodeRequired',
            'Postal code is required!'
          )
        ),
      }),
    }),
  };
};

interface DeliveryProps extends CheckoutComponentProps {
  billingAddressEqualsDeliveryAddress?: boolean;
}

const Delivery: FC<DeliveryProps> = observer(
  ({ billingAddressEqualsDeliveryAddress, ...checkoutComponentProps }) => {
    const { t } = useTranslation();

    return (
      <CheckoutComponent {...checkoutComponentProps}>
        <div className={styles.container}>
          <section>
            <h2 className={styles.title}>
              {t('order.delivery.buyerDetails.title', 'Buyer details')}
            </h2>
            <div className={styles.buyerForm}>
              <FormikInputField
                label={t('order.delivery.buyerDetails.firstName', 'First name')}
                name='delivery.customerDetails.buyerDetails.firstName'
                type='text'
                variant='MULTILINE'
              />
              <FormikInputField
                label={t('order.delivery.buyerDetails.lastName', 'Last name')}
                name='delivery.customerDetails.buyerDetails.lastName'
                type='text'
                variant='MULTILINE'
              />
              <FormikInputField
                label={t('order.delivery.buyerDetails.email', 'Email address')}
                name='delivery.customerDetails.buyerDetails.email'
                type='text'
                variant='MULTILINE'
              />
              <FormikInputField
                label={t(
                  'order.delivery.buyerDetails.phoneNumber',
                  'Phone number'
                )}
                name='delivery.customerDetails.buyerDetails.phoneNumber'
                type='text'
                variant='MULTILINE'
              />
            </div>
          </section>
          <section>
            <h2 className={styles.title}>
              {t('order.delivery.deliveryAddress.title', 'Delivery address')}
            </h2>
            <div className={styles.addressForm}>
              <FormikInputField
                label={t(
                  'order.delivery.address.streetAddress',
                  'Street address'
                )}
                name='delivery.customerDetails.deliveryAddress.streetAddress'
                type='text'
                variant='MULTILINE'
              />
              <FormikInputField
                label={t('order.delivery.address.postalCode', 'Postal code')}
                name='delivery.customerDetails.deliveryAddress.postalCode'
                type='text'
                variant='MULTILINE'
              />
              <FormikInputField
                label={t('order.delivery.address.city', 'City')}
                name='delivery.customerDetails.deliveryAddress.city'
                type='text'
                variant='MULTILINE'
              />
              <FormikInputField
                label={t('order.delivery.address.country', 'Country')}
                name='delivery.customerDetails.deliveryAddress.country'
                type='text'
                variant='MULTILINE'
              />
            </div>
          </section>
          <section className={styles.billingAddress}>
            <Field
              as={CheckboxComponent}
              type='checkbox'
              label={
                <span className={styles.billingAddressLabel}>
                  {t(
                    'order.delivery.billingAddressEqualsDeliveryAddress',
                    'Billing address is the same as address'
                  )}
                </span>
              }
              name={
                'delivery.customerDetails.billingAddressEqualsDeliveryAddress'
              }
              theme='LIGHT'
            />
            {!billingAddressEqualsDeliveryAddress && (
              <div className={styles.billingAddressContainer}>
                <h2 className={styles.title}>
                  {t('order.delivery.billingAddress.title', 'Billing address')}
                </h2>
                <div className={styles.addressForm}>
                  <FormikInputField
                    label={t(
                      'order.delivery.address.streetAddress',
                      'Street address'
                    )}
                    name='delivery.customerDetails.billingAddress.streetAddress'
                    type='text'
                    variant='MULTILINE'
                  />
                  <FormikInputField
                    label={t(
                      'order.delivery.address.postalCode',
                      'Postal code'
                    )}
                    name='delivery.customerDetails.billingAddress.postalCode'
                    type='text'
                    variant='MULTILINE'
                  />
                  <FormikInputField
                    label={t('order.delivery.address.city', 'City')}
                    name='delivery.customerDetails.billingAddress.city'
                    type='text'
                    variant='MULTILINE'
                  />
                  <FormikInputField
                    label={t('order.delivery.address.country', 'Country')}
                    name='delivery.customerDetails.billingAddress.country'
                    type='text'
                    variant='MULTILINE'
                  />
                </div>
              </div>
            )}
          </section>
        </div>
      </CheckoutComponent>
    );
  }
);

export default Delivery;
