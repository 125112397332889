import { DistributiveOmit } from '@mui/types';
import React, { createContext, FC, ReactNode, useState } from 'react';
import ModalWrapper from './ModalWrapper';
import CloseModal, {
  CloseModalProps,
} from '../../../project/components/CloseModal';

type OpenConfirmModalArgs = DistributiveOmit<
  CloseModalProps,
  'onConfirm' | 'onBack'
>;

export type ModalResultState = 'CONFIRMED' | 'CANCELED';

export interface ModalManager {
  openConfirmModal(
    confirmArgs: OpenConfirmModalArgs
  ): Promise<ModalResultState>;
}

export const ModalManagerContext = createContext<ModalManager | undefined>(
  undefined
);

type ModalProviderProps = {
  children: ReactNode;
};

export const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
  const promiseDefaultValue = {
    resolve: () => {},
    reject: () => {},
  };

  const [isOpen, setIsOpen] = useState(false);
  const [openConfirmModalArgs, setOpenConfirmModalArgs] =
    useState<OpenConfirmModalArgs | null>(null);
  const [promiseState, setPromiseState] =
    useState<{ resolve: Function; reject: Function }>(promiseDefaultValue);

  const openConfirmModal = (args: OpenConfirmModalArgs) => {
    setOpenConfirmModalArgs(args);
    setIsOpen(true);
    return new Promise<ModalResultState>((resolve, reject) => {
      setPromiseState({ resolve, reject });
    });
  };

  const cancel = () => {
    setIsOpen(false);
    promiseState?.resolve('CANCELED');
    setPromiseState(promiseDefaultValue);
  };

  const confirm = () => {
    setIsOpen(false);
    promiseState?.resolve('CONFIRMED');
    setPromiseState(promiseDefaultValue);
  };

  return (
    <ModalManagerContext.Provider value={{ openConfirmModal }}>
      {children}
      <ModalWrapper open={isOpen} onClose={() => cancel()}>
        {openConfirmModalArgs && (
          <CloseModal
            {...openConfirmModalArgs}
            onConfirm={() => confirm()}
            onBack={() => cancel()}
          ></CloseModal>
        )}
      </ModalWrapper>
    </ModalManagerContext.Provider>
  );
};
