import { useAuth0 } from '@auth0/auth0-react';
import { FC, useEffect } from 'react';
import Loading from './common/components/Loading';

const SignIn: FC = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({});
  }, []);

  return <Loading />;
};

export default SignIn;
