import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Info, ShoppingCartSimple, Truck } from 'phosphor-react';
import { FC, SyntheticEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  IFileIdDto,
  Logger,
  MedialFileAction,
  PriceFormat,
  ProjectDesignFilesProvider,
  useService,
} from 'shared';
import { OrderStore } from './OrderStore';
import styles from './OrderSummary.module.css';

interface OrderSummaryProps {
  projectId: number;
}

const OrderSummary: FC<OrderSummaryProps> = observer(({ projectId }) => {
  const { t } = useTranslation();

  const logger = useService<Logger>(Logger);
  const orderStore = useService<OrderStore>(OrderStore);
  const fileProvider = useService<ProjectDesignFilesProvider>(
    ProjectDesignFilesProvider
  );

  const handlePreviewDocument = async (
    e: SyntheticEvent,
    fileId?: IFileIdDto
  ) => {
    e.preventDefault();
    if (!fileId || !projectId) {
      return;
    }

    try {
      const file = await fileProvider.getFreshFileDownloadData(
        projectId,
        fileId.blobId,
        MedialFileAction.Preview
      );
      if (file) {
        window.open(file.uri, '_blank');
      } else {
        throw new Error(
          `Sas token for desing file ${JSON.stringify(
            fileId.fileName
          )} is empty!`
        );
      }
    } catch (e) {
      logger.logException(e);
    }
  };

  const formatDeliveryDate = (from: moment.Moment, to: moment.Moment) => {
    if (from.isSame(to)) {
      return to.format('DD MMM YYYY');
    }

    if (from.month() === to.month()) {
      return t('order.summary.delivery', 'between {{estimatedDelivery}}', {
        estimatedDelivery: `${from.format('DD')}-${to.format('DD MMM YYYY')}`,
      });
    }

    if (from.year() === to.year()) {
      return t('order.summary.delivery', 'between {{estimatedDelivery}}', {
        estimatedDelivery: `${from.format('DD MMM')}-${to.format(
          'DD MMM YYYY'
        )}`,
      });
    }

    return `${from.format('DD MMM YYYY')} - ${to.format('DD MMM YYYY')}`;
  };

  return (
    <div className={styles.container}>
      <div className={classNames(styles.summaryRow, styles.mainTitle)}>
        <h2 className={styles.title}>
          {t('order.summary.billing', 'Billing')}
        </h2>
      </div>
      <div className={classNames(styles.summaryRow, styles.priceContainer)}>
        <div className={styles.left}>
          <ShoppingCartSimple />
          <span className={styles.text}>
            {t('order.summary.price', 'Total price')}
          </span>
        </div>
        <div className={styles.price}>
          {orderStore.isLoadingOrderSummary && (
            <Skeleton width={'10ch'} height={42} variant='rectangular' />
          )}
          <PriceFormat
            prefix={`${orderStore.orderSummary?.currency} `}
            value={orderStore.orderSummary?.totalPrice}
            className={styles.totalPrice}
            decimalScale={0}
          />
          <span className={styles.taxInfo}>
            <Trans t={t} i18nKey='order.summary.taxInfo'>
              {`incl. VAT `}
              <PriceFormat
                prefix={`${orderStore.orderSummary?.currency} `}
                value={orderStore.orderSummary?.tax}
                decimalScale={0}
              />
            </Trans>
          </span>
        </div>
        {orderStore.orderSummary?.priceDetails && (
          <div
            className={styles.seeDetails}
            onClick={(e) =>
              handlePreviewDocument(e, orderStore.orderSummary?.priceDetails)
            }
          >
            {t('order.summary.seeDetails', 'See details')}
          </div>
        )}
      </div>
      <div className={classNames(styles.summaryRow, styles.deliveryContainer)}>
        <div className={styles.left}>
          <Truck />
          <span className={styles.text}>
            {t('order.summary.estimatedDelivery', 'Estimated delivery')}
          </span>
        </div>
        <span className={styles.text}>
          {orderStore.isLoadingOrderSummary && (
            <Skeleton width={'20ch'} height={18} variant='rectangular' />
          )}
          {orderStore.orderSummary?.plannedDeliveryFrom &&
            orderStore.orderSummary?.plannedDeliveryTo && (
              <>
                {formatDeliveryDate(
                  orderStore.orderSummary?.plannedDeliveryFrom,
                  orderStore.orderSummary?.plannedDeliveryTo
                )}
              </>
            )}
        </span>
      </div>
      {orderStore.orderSummary?.offerValidUntil && (
        <div
          className={classNames(
            styles.summaryRow,
            styles.offerValidationContainer
          )}
        >
          <div className={styles.left}>
            <Info />
            <span className={styles.text}>
              {t('order.summary.offerValidUntil', 'This offer is valid until')}
            </span>
          </div>
          <span className={styles.text}>
            {orderStore.isLoadingOrderSummary && (
              <Skeleton width={'20ch'} height={18} variant='rectangular' />
            )}
            {orderStore.orderSummary?.offerValidUntil?.format('DD MMM YYYY')}
          </span>
        </div>
      )}
    </div>
  );
});

export default OrderSummary;
