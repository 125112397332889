import { FC } from 'react';
import { LinkProps, NavLink } from 'react-router-dom';
import { ArrowLeft } from 'phosphor-react';
import ButtonComponent from './buttons/ButtonComponent';
import styles from './BackLink.module.css';

interface BackLinkProps {
  to: LinkProps['to'];
  text: string;
}

const BackLink: FC<BackLinkProps> = ({ to, text }) => (
  <NavLink to={to} className={styles.root}>
    <ButtonComponent className={styles.button} variant='TEXT' size='SMALL'>
      <ArrowLeft size={18} weight='thin' className={styles.icon} />
      {text}
    </ButtonComponent>
  </NavLink>
);

export default BackLink;
