import { Moment } from 'moment';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './OrderContainer.module.css';

type OrderContainerProps = {
  orderNumber: number;
  submittedAt: Moment;
  children: ReactNode;
};

const OrderContainer: FC<OrderContainerProps> = ({
  orderNumber,
  submittedAt,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h2 className={styles.title}>
          {t('project.order.title', 'Order number #{{orderNumber}}', {
            orderNumber,
          })}
        </h2>
        <p className={styles.date}>
          {submittedAt.local().format('DD.MM.YYYY')}
        </p>
      </div>
      {children}
    </div>
  );
};

export default OrderContainer;
