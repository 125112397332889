import {
  Checkbox,
  FormControl,
  FormControlClassKey,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { FC, InputHTMLAttributes, ReactElement } from 'react';
import styles from './CheckboxComponent.module.css';
import classNames from 'classnames';
import { ClassNameMap } from '@mui/styles/withStyles';

type themeType = 'LIGHT' | 'DARK';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string | ReactElement;
  name: string;
  checked?: boolean;
  theme?: themeType;
  error?: string;
  description?: string;
  formControlClasses?: Partial<ClassNameMap<FormControlClassKey>>;
}

const CheckboxComponent: FC<CheckboxProps> = ({
  label,
  name,
  checked,
  theme = 'LIGHT',
  error,
  description,
  formControlClasses,
  ...rest
}) => {
  const themeClasses = {
    [styles.light]: theme === 'LIGHT',
    [styles.dark]: theme === 'DARK',
  };

  return (
    <FormControl error={!!error} classes={formControlClasses}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            name={name}
            id={name}
            inputProps={{ ...rest }}
            classes={{
              root: classNames(styles.root, themeClasses, {
                [styles.error]: !!error,
              }),
              checked: styles.checked,
            }}
            checkedIcon={<span className={styles.squareCheck} />}
            icon={<span className={styles.square} />}
            disableRipple={true}
          />
        }
        label={label}
        labelPlacement='end'
        classes={{
          label: classNames(styles.label, themeClasses),
        }}
      />
      {error && (
        <FormHelperText
          classes={{
            error: styles.error,
          }}
        >
          {error}
        </FormHelperText>
      )}
      {description && <FormHelperText>{description}</FormHelperText>}
    </FormControl>
  );
};

export default CheckboxComponent;
