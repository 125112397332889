import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ActionFailed from '../../../common/components/alerts/ActionFailed';
import styles from './MeetingBookFailed.module.css';

type MeetingBookResultProps = {
  restartBooking: () => void;
};

const MeetingBookResult: FC<MeetingBookResultProps> = ({ restartBooking }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <ActionFailed
        restartProcess={restartBooking}
        restartProcessText={t('dashboard.meeting.book')}
        textPrimary={t('meeting.summary.failedMain')}
        textSecondary={t('meeting.summary.failedSecondary')}
      />
    </div>
  );
};

export default MeetingBookResult;
