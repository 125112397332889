import classNames from 'classnames';
import React, { ChangeEvent, FC, useState } from 'react';
import styles from './FilePickerButton.module.css';
import { Trans, useTranslation } from 'react-i18next';
import { Plus, Pencil } from 'phosphor-react';
import * as mimeLite from 'mime/lite';
import * as prettyBytes from 'pretty-bytes';
import _ from 'lodash';

type FilePickerButtonProps = {
  shrunk: boolean;
  allowedTypes: string;
  type?: 'BOX' | 'PROFILE_PICTURE' | 'BUTTON';
  theme?: 'LIGHT' | 'DARK';
  size?: 'WHOLE-WIDTH' | 'SMALL';
  inputId?: string;
  maxFileSizeInBytes: number;
  onFileAdd: (event: ChangeEvent<HTMLInputElement>) => void;
  allowMultiple: boolean;
};

const FilePickerButton: FC<FilePickerButtonProps> = ({
  shrunk,
  allowedTypes,
  onFileAdd,
  type = 'BOX',
  theme,
  size,
  inputId,
  maxFileSizeInBytes,
  allowMultiple,
}) => {
  const { t } = useTranslation();
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const fileTypes = {
    fileTypes: _.chain(allowedTypes)
      .split(',')
      .map((mime) => mimeLite.getExtension(mime)?.toLocaleUpperCase())
      .compact()
      .join(', '),
    maxSize: prettyBytes.default(maxFileSizeInBytes),
  };

  const WholeWidth = isDragging ? (
    <p className={styles.uploadTextDrag}>{t('project.uploadFiles.drop')}</p>
  ) : (
    <>
      <div className={styles.upload}>
        <Plus size={32} weight='light' className={styles.uploadIcon} />
      </div>
      <p className={styles.uploadText}>
        <Trans t={t} i18nKey='project.uploadFiles.selectFiles'>
          First translated part
          <span className={styles.browseText}>
            {{ browse: t('project.uploadFiles.browse', 'select more files') }}
          </span>
          Last translated part
        </Trans>
      </p>
      <p className={styles.shortUploadText}>
        <span className={styles.shortBrowseText}>
          {t('project.uploadFiles.shortBrowse')}
        </span>
        <span className={styles.fileTypesText}>
          {t('project.uploadFiles.fileTypes', fileTypes)}
        </span>
      </p>
    </>
  );

  const SmallButton = (
    <div className={styles.smallContainer}>
      <div className={styles.upload}>
        <Plus className={styles.uploadIcon} />
      </div>
      <span className={classNames(styles.mainText)}>
        {t('project.uploadFiles.addFile')}
      </span>
    </div>
  );

  const ProfilePicture = (
    <div className={styles.profilePicture}>
      <Plus size={32} weight='light' className={styles.profileIcon} />
      <span className={styles.profileText}>
        {t('project.uploadFiles.addImage')}
      </span>
    </div>
  );

  const ButtonText = (
    <div className={styles.button}>
      <Pencil size={16} className={styles.btnIcon} />
      <span className={styles.btnLink}>{t('project.uploadFiles.edit')}</span>
    </div>
  );

  return (
    <div>
      <div
        className={classNames(styles.uploadArea, {
          [styles.active]: isDragging,
          [styles.shrinken]: shrunk,
          [styles.dark]: theme === 'DARK',
          [styles.wholeWidth]: size === 'WHOLE-WIDTH',
          [styles.small]: size === 'SMALL',
          [styles.profile]: type === 'PROFILE_PICTURE',
          [styles.buttonText]: type === 'BUTTON',
        })}
      >
        {type === 'BOX' && size === 'WHOLE-WIDTH' && WholeWidth}
        {type === 'BOX' && size === 'SMALL' && SmallButton}
        {type === 'PROFILE_PICTURE' && ProfilePicture}
        {type === 'BUTTON' && ButtonText}
        <input
          id={inputId}
          multiple={allowMultiple}
          hidden
          type='file'
          accept={allowedTypes}
          onChange={onFileAdd}
          onDragEnter={() => setIsDragging(true)}
          onDrop={() => setIsDragging(false)}
          onDragLeave={() => setIsDragging(false)}
        />
      </div>
      {size === 'SMALL' && (
        <span
          className={classNames(styles.fileTypesText, styles.smallFileTypes)}
        >
          {t('project.uploadFiles.fileTypes', fileTypes)}
        </span>
      )}
    </div>
  );
};

FilePickerButton.defaultProps = {
  theme: 'LIGHT',
  size: 'WHOLE-WIDTH',
  inputId: 'floor-plan-upload',
};

export default FilePickerButton;
