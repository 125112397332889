import classNames from 'classnames';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import styles from './MenuLinks.module.css';

const MenuLinks: FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth0();

  const authenticatedLinks = [
    {
      path: '/',
      text: t('dashboard.menuTitle'),
      type: 'primary',
    },
    {
      path: '/user-settings',
      text: t('userSettings.menuTitle'),
      type: 'primary',
    },
  ];

  const notAuthenticatedLinks = [
    {
      path: '/',
      text: t('dashboard.menuTitle'),
      type: 'primary',
    },
    {
      path: '/privacy-policy',
      text: t('privacyPolicy.menuTitle'),
      type: 'secondary',
    },
  ];

  const primaryLinks = (
    isAuthenticated ? authenticatedLinks : notAuthenticatedLinks
  ).filter((link) => link.type === 'primary');

  const secondaryLinks = (
    isAuthenticated ? authenticatedLinks : notAuthenticatedLinks
  ).filter((link) => link.type === 'secondary');

  return (
    <nav>
      <ul className={styles.links}>
        {primaryLinks.map((link) => (
          <li key={link.path} className={styles.item}>
            <NavLink to={link.path} className={styles.link} exact>
              {link.text}
            </NavLink>
          </li>
        ))}
      </ul>
      <ul className={styles.links}>
        {secondaryLinks.map((link) => (
          <li key={link.path} className={styles.item}>
            <NavLink
              to={link.path}
              className={classNames(styles.link, styles.secondary)}
              exact
            >
              {link.text}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default MenuLinks;
