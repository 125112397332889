import React, { FC } from 'react';
import { FieldHookConfig, useField, useFormikContext } from 'formik';
import InputField, { InputProps } from '../inputs/InputField';

type FormikInputFieldProps = FieldHookConfig<string> & InputProps;

const FormikInputField: FC<FormikInputFieldProps> = (props) => {
  const [field, meta] = useField(props);
  const { submitCount } = useFormikContext();
  return (
    <InputField
      error={meta.touched && submitCount > 0 ? meta.error : undefined}
      {...field}
      {...props}
    />
  );
};

export default FormikInputField;
