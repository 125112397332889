import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import OrderNavigation from './OrderNavigation';
import styles from './CheckoutContent.module.css';
import Delivery, { getDeliveryValidationSchema } from './Delivery';
import Payment from './Payment';
import OrderSummary from './OrderSummary';
import FinalAgreement from './FinalAgreement';
import { Form, FormikProvider, useFormik } from 'formik';
import { CustomerService, IDeliveryDetailsDto, useService } from 'shared';
import { OrderStore } from './OrderStore';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { flowResult } from 'mobx';
import { StorageService } from './services/StorageService';
import CancelOrder, { CancelFormValidData } from './CancelOrder';
import ModalWrapper from '../common/components/modals/ModalWrapper';

export interface OrderDataForm {
  delivery: IDeliveryDetailsDto;
  paymentSplitId?: number;
  finalAgreement?: boolean;
}

export interface CancelData {
  cancelReasonId: number;
  cancelDescription?: string;
  delivery: IDeliveryDetailsDto;
  paymentSplitId?: number;
  orderId: string;
}

interface CheckoutContentProps {
  onSubmit: () => Promise<void>;
  onCancel: (cancelData: CancelData) => Promise<void>;
  onCloseCancelOrderModal: () => void;
  onOpenCancelOrderModal: () => void;
  isCancelOrderModalOpen: boolean;
  projectId: number;
}

const CheckoutContent: FC<CheckoutContentProps> = ({
  onSubmit,
  onCancel,
  projectId,
  isCancelOrderModalOpen,
  onCloseCancelOrderModal,
  onOpenCancelOrderModal,
}) => {
  const { t } = useTranslation();

  const { orderId } = useParams<Record<string, string | undefined>>();
  const orderStore = useService<OrderStore>(OrderStore);
  const customerService = useService<CustomerService>(CustomerService);
  const storageService = useService<StorageService>(StorageService);
  const storageKey = `order-checkout/${orderId}/checkout-form`;

  const formik = useFormik<OrderDataForm>({
    validateOnBlur: true,
    validateOnMount: true,
    initialValues: {
      delivery: OrderStore.createDeliveryDetailsDefaultValue(),
      finalAgreement: false,
    },
    validationSchema: Yup.object().shape({
      delivery: Yup.object().shape({
        customerDetails: Yup.object().shape(getDeliveryValidationSchema(t)),
      }),
      paymentSplitId: Yup.number().required(
        t('order.payment.paymentOptionRequired', 'Payment option is required!')
      ),
      finalAgreement: Yup.boolean().oneOf(
        [true],
        t(
          'order.finalAgreement.termsConditionsRequired',
          'You have to agree with Terms & Conditions in order to proceed with your order.'
        )
      ),
    }),
    onSubmit: async (values) => {
      if (!orderId) {
        return;
      }

      await flowResult(
        orderStore.setOrderData(
          values.delivery,
          values.paymentSplitId ?? 0,
          orderId
        )
      );

      await onSubmit();
    },
  });

  const handleCancelOrdering = (cancelForm: CancelFormValidData) => {
    const values = formik.values;
    if (!orderId) {
      return;
    }
    onCancel({
      ...cancelForm,
      ...values,
      orderId,
    });
  };

  const saveDataToLocalStorage = async () => {
    await storageService.saveData(storageKey, formik.values);
  };

  useEffect(() => {
    const fetchCustomerDetailsForOrder = async () => {
      const fromStorage = await storageService.getData<OrderDataForm>(
        storageKey
      );
      if (fromStorage) {
        await formik.setValues(fromStorage, true);
      } else {
        const customerDetails = await customerService.getMyDetailsForOrder();
        const formValues = {
          ...formik.values,
          delivery: { ...formik.values.delivery, customerDetails },
        };
        await formik.setValues(formValues, true);
        await storageService.saveData(storageKey, formValues);
      }
    };

    fetchCustomerDetailsForOrder();
  }, [orderStore, orderId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      <OrderSummary projectId={projectId} />
      <FormikProvider value={formik}>
        <Form onChange={saveDataToLocalStorage}>
          <div className={styles.content}>
            <Delivery
              title={t('order.delivery.title', 'Delivery & Billing')}
              billingAddressEqualsDeliveryAddress={
                formik.values.delivery.customerDetails
                  .billingAddressEqualsDeliveryAddress
              }
            />
            <Payment
              title={t('order.payment.title', 'Payment')}
              description={t(
                'order.payment.description',
                'Choose convinient payment option.'
              )}
              options={[...orderStore.paymentSplitOptions]}
            />
            <FinalAgreement
              termsAndConditionsUrl={orderStore.termsAndConditonsUrl}
            />
          </div>
          <OrderNavigation
            nextStepText={t('order.payment.next', 'Buy your kitchen')}
            onNextStep={formik.submitForm}
            onCancelOrder={onOpenCancelOrderModal}
          />
        </Form>
      </FormikProvider>
      <ModalWrapper
        open={isCancelOrderModalOpen}
        onClose={onCloseCancelOrderModal}
        closeVariant='NONE'
        sizeVariant='SMALL'
      >
        <CancelOrder
          cancelReasons={orderStore.cancelReasons ?? []}
          onCancel={onCloseCancelOrderModal}
          onSubmit={handleCancelOrdering}
          isLoading={orderStore.isOrderCanceling}
        />
      </ModalWrapper>
    </div>
  );
};

export default CheckoutContent;
