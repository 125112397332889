import React, { FC } from 'react';
import styles from './OrderSectionTitle.module.css';

type OrderSectionTitleProps = {
  title: string;
};

const OrderSectionTitle: FC<OrderSectionTitleProps> = ({ title }) => {
  return <h3 className={styles.title}>{title}</h3>;
};

export default OrderSectionTitle;
