import { FC } from 'react';
import styles from './PrivacySection.module.css';
import UserSectionTitle from './UserSectionTitle';

interface PrivacySectionProps {
  title: string;
  text: string;
}

const PrivacySection: FC<PrivacySectionProps> = ({ title, text, children }) => {
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <UserSectionTitle title={title} />
        <p className={styles.text}>{text}</p>
        {children}
      </div>
    </section>
  );
};

export default PrivacySection;
