import { FC, useEffect } from 'react';
import { useService } from 'shared';
import { useTranslation } from 'react-i18next';
import styles from './Design.module.css';
import { flowResult } from 'mobx';
import { DesignIterationsStore } from '../../project/designs/DesignIterationsStore';
import DesignTile from '../../common/components/DesignTile/DesignTile';
import { observer } from 'mobx-react';
import Accordion from '../../common/components/accordion/Accordion';
import AccordionSummary from '../../common/components/accordion/AccordionSummary';
import AccordionDetails from '../../common/components/accordion/AccordionDetails';

interface DesignProps {
  projectId: number;
}

const Design: FC<DesignProps> = observer(({ projectId }) => {
  const { t } = useTranslation();
  const designIterations = useService<DesignIterationsStore>(
    DesignIterationsStore
  );

  useEffect(() => {
    flowResult(designIterations.loadDesignIteration(projectId));
  }, [designIterations, projectId]);

  const sortedIterations = designIterations.sortedIterations;

  if (designIterations?.iterations?.length) {
    return (
      <>
        {sortedIterations.ordered?.map((iteration) => (
          <DesignTile
            key={iteration.id}
            iteration={iteration}
            projectId={projectId}
            className={styles.tile}
          />
        ))}
        {sortedIterations.active?.map((iteration) => (
          <DesignTile
            key={iteration.id}
            iteration={iteration}
            projectId={projectId}
            className={styles.tile}
            allowLinks={
              !(
                sortedIterations.ordered && sortedIterations.ordered?.length > 0
              )
            }
          />
        ))}
        {sortedIterations.archived && sortedIterations.archived.length > 0 && (
          <Accordion>
            <AccordionSummary
              classes={{
                root: styles.accordionHead,
              }}
            >
              <span>{t('dashboard.design.archiveIterations')}</span>
            </AccordionSummary>
            <AccordionDetails>
              {sortedIterations.archived.map((iteration) => (
                <DesignTile
                  key={iteration.id}
                  iteration={iteration}
                  projectId={projectId}
                  className={styles.tile}
                />
              ))}
            </AccordionDetails>
          </Accordion>
        )}
      </>
    );
  }

  return (
    <>
      <p className={styles.intro}>{t('dashboard.design.intro')}</p>
    </>
  );
});

export default Design;
