import { useAuth0 } from '@auth0/auth0-react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { EmailVerificationService, useService } from 'shared';
import { IEmailAddresses } from 'shared/src/dtos';
import ButtonComponent from '../common/components/buttons/ButtonComponent';
import InitialScreen from '../common/components/initial-screen/InitialScreen';
import styles from './VerifyEmail.module.css';

const VerifyEmail: FC = () => {
  const { t } = useTranslation();

  const { loginWithRedirect, error } = useAuth0();

  const history = useHistory();
  const location = useLocation();

  const emailVerificationService = useService<EmailVerificationService>(
    EmailVerificationService
  );

  const [emailAddress, setEmailAddress] = useState<string>('');
  const [emailAddresses, setEmailAddresses] = useState<IEmailAddresses>({
    values: {},
  });

  const linkToEmailAccount = emailAddresses.values[emailAddress.split('@')[1]];

  useEffect(() => {
    const fetchEmailAddresses = async () => {
      const result = await emailVerificationService.getEmailAddresses();
      setEmailAddresses(result);
    };

    fetchEmailAddresses();
  }, [emailVerificationService]);

  useEffect(() => {
    const splitError = error?.message.trim().split(',');
    if (splitError?.length === 2) {
      const email = splitError[1].trim();
      if (email) {
        setEmailAddress(email);
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete('error_description');
        history.replace({ search: queryParams.toString() });
      }
    }
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleResendEmail = async () => {
    if (emailAddress) {
      await emailVerificationService.sendVerificationEmail(emailAddress);
    }
  };

  return (
    <InitialScreen
      title={t('emailVerification.title', 'Verify your email')}
      description={t('emailVerification.text')}
    >
      <div className={styles.container}>
        {linkToEmailAccount && (
          <a href={linkToEmailAccount}>
            <ButtonComponent
              variant='FILLED_IN'
              colorScheme='ACTION'
              size='BIG'
            >
              {t('emailVerification.goToEmail', 'Go to your email')}
            </ButtonComponent>
          </a>
        )}
        <ButtonComponent
          variant='TEXT'
          colorScheme='LIGHT'
          size='SUPER_SMALL'
          onClick={handleResendEmail}
          className={styles.smallButton}
        >
          {t('emailVerification.resend', 'Nothing there? Resend')}
        </ButtonComponent>
        <ButtonComponent
          variant='TEXT'
          colorScheme='LIGHT'
          size='SUPER_SMALL'
          className={styles.smallButton}
          onClick={() => {
            loginWithRedirect({ prompt: 'login' });
          }}
        >
          {t('emailVerification.logIn', 'Log in using different account')}
        </ButtonComponent>
      </div>
    </InitialScreen>
  );
};

export default VerifyEmail;
