import React, { FC, useState } from 'react';
import styles from './ProfilePicture.module.css';
import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';
import { useMediaQuery } from '@react-hook/media-query';
import { customMedia } from '../../assets/styles/mediaQueries';
import {
  ClickAwayListener,
  Fade,
  IconButton,
  Paper,
  Popper,
} from '@mui/material';
import ProfileMenu from './ProfileMenu';
import { useHistory } from 'react-router-dom';
import { User } from 'phosphor-react';

const ProfilePicture: FC = () => {
  const [hasError, setHasError] = useState(false);
  const { user } = useAuth0();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (isMobile) {
      history.push('/user-settings');
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isMobile = !useMediaQuery(customMedia['--screen-md']);
  const open = Boolean(anchorEl);
  const id = open ? 'profile-menu-popover' : undefined;

  return (
    <div>
      <IconButton
        onClick={handleClick}
        className={styles.container}
        size='large'
      >
        {!hasError && user?.picture ? (
          <img
            src={user?.picture}
            alt='Profile'
            className={classNames('rounded-circle', styles.profilePicture)}
            onError={() => setHasError(true)}
          />
        ) : (
          <User size={24} className={styles.icon} />
        )}
      </IconButton>
      {!isMobile && (
        <Popper
          id={id}
          open={open}
          placement='bottom-end'
          anchorEl={anchorEl}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [20, 10],
              },
            },
          ]}
          transition
          style={{
            zIndex: 1,
          }}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClose}>
              <Fade {...TransitionProps} timeout={350}>
                <Paper classes={{ root: styles.paperClasses }}>
                  <div className={styles.arrow} data-popper-arrow></div>
                  <ProfileMenu onClose={handleClose} />
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      )}
    </div>
  );
};

export default ProfilePicture;
