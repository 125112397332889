import React from 'react';
import styles from './EmptyInvoice.module.css';
import { FileDotted } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

const EmptyInvoice = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <FileDotted size={22} className={styles.icon} />
      {t('project.order.invoice.emptyTitle', 'Invoice not ready yet')}
    </div>
  );
};

export default EmptyInvoice;
