import { FC, useEffect, useState } from 'react';
import styles from './Schedule.module.css';
import {
  ChooseLocationStore,
  DesignersStore,
  SelectTimeStore,
} from '../../BookMeetingStore';
import ChooseLocation from '../../components/location/ChooseLocation';
import { MeetingType } from 'shared';
import SelectTime from '../../components/select-time/SelectTime';
import { observer } from 'mobx-react';
import StepWithNavigation from '../../../common/components/CardWithSteps/StepWithNavigation';
import { useTranslation } from 'react-i18next';
import Designers from '../../components/Designers/Designers';

interface ScheduleProps {
  chooseLocationStore: ChooseLocationStore;
  location: string;
  meetingType?: MeetingType;
  selectTimeStore: SelectTimeStore;
  designersStore: DesignersStore;
  isDirty?: boolean;
}

const Schedule: FC<ScheduleProps> = observer(
  ({
    chooseLocationStore,
    location,
    meetingType,
    selectTimeStore,
    designersStore,
    isDirty = false,
  }) => {
    const [visibleNextButton, setVisibleNextButton] = useState(false);
    const { t } = useTranslation();
    const [isMeetingTypePopupShown, setIsMeetingTypePopupShown] =
      useState(false);
    const [isLocationPopupShown, setIsLocationPopupShown] = useState(false);
    const [isMeetingDateErrorShown, setIsMeetingDateErrorShown] =
      useState(false);
    const [isMeetingHourErrorShown, setIsMeetingHourErrorShown] =
      useState(false);

    const canGoNext = () => {
      if (!chooseLocationStore.selectedMeeting) {
        setIsMeetingTypePopupShown(true);
        return false;
      }

      if (
        chooseLocationStore.selectedMeeting === MeetingType.Showroom &&
        chooseLocationStore.selectedLocation === ''
      ) {
        setIsLocationPopupShown(true);
        return false;
      }

      if (!selectTimeStore.selectedDate) {
        setIsMeetingDateErrorShown(true);
        return false;
      }

      if (!selectTimeStore.selectedSlot) {
        setIsMeetingHourErrorShown(true);
        return false;
      }
      return true;
    };

    useEffect(() => {
      if (
        chooseLocationStore.selectedMeeting &&
        designersStore.selectedDesigner !== undefined &&
        selectTimeStore.selectedDate &&
        selectTimeStore.selectedSlot
      ) {
        setVisibleNextButton(true);
        return;
      }
      setVisibleNextButton(false);
    }, [
      chooseLocationStore.selectedMeeting,
      designersStore.selectedDesigner,
      selectTimeStore.selectedDate,
      selectTimeStore.selectedSlot,
    ]);

    return (
      <StepWithNavigation
        canGoNext={canGoNext}
        goNextText={t('meeting.button.summary')}
        hideNextStep={!visibleNextButton}
        disableNextStep={isDirty}
      >
        <div>
          <p className={styles.intro}>{t('meeting.location.intro')}</p>
          <h3 className={styles.title}>{t('meeting.location.title')}</h3>
          <ChooseLocation
            chooseLocationStore={chooseLocationStore}
            isMeetingTypePopupShown={isMeetingTypePopupShown}
            onSetIsMeetingTypePopupShown={setIsMeetingTypePopupShown}
            isLocationPopupShown={isLocationPopupShown}
            onSetIsLocationPopupShown={setIsLocationPopupShown}
          />
        </div>
        {chooseLocationStore.selectedMeeting && (
          <>
            <div className={styles.box}>
              <Designers designersStore={designersStore} />
            </div>
            {designersStore.selectedDesigner !== undefined && (
              <div className={styles.box}>
                <h3 className={styles.title}>{t('meeting.time.title')}</h3>
                <SelectTime
                  location={location}
                  selectTimeStore={selectTimeStore}
                  meetingType={meetingType}
                  designer={designersStore.selectedDesigner}
                  isMeetingDateErrorShown={isMeetingDateErrorShown}
                  setIsMeetingDateErrorShown={setIsMeetingDateErrorShown}
                  isMeetingHourErrorShown={isMeetingHourErrorShown}
                  setIsMeetingHourErrorShown={setIsMeetingHourErrorShown}
                />
              </div>
            )}
          </>
        )}
      </StepWithNavigation>
    );
  }
);

export default Schedule;
