import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DesignStatus, IProjectDto } from 'shared';
import styles from './DesignIterations.module.css';
import DesignProposal from './DesignProposal';
import { IterationStore } from './IterationStore';

interface DesignIterationsProps {
  designs: IterationStore[];
  project: IProjectDto;
}

const DesignIterations: FC<DesignIterationsProps> = ({ designs, project }) => {
  const { t } = useTranslation();

  const statusGroups = _.groupBy(designs, (i) => i.status);

  const isOrdered = designs.find((d) => d.status === DesignStatus.Ordered);

  return (
    <div className={styles.container}>
      {statusGroups[DesignStatus.Ordered] &&
        statusGroups[DesignStatus.Ordered].map((iteration) => (
          <DesignProposal
            defaultExpanded={true}
            key={iteration.id}
            designProposal={iteration}
            project={project}
          />
        ))}
      {statusGroups[DesignStatus.Active] &&
        statusGroups[DesignStatus.Active].map((iteration, index) => {
          return (
            <DesignProposal
              defaultExpanded={
                !statusGroups[DesignStatus.Ordered] && index === 0
              }
              key={iteration.id}
              designProposal={iteration}
              project={project}
              disableActions={!!isOrdered}
            />
          );
        })}

      {statusGroups[DesignStatus.Archival] &&
        statusGroups[DesignStatus.Archival].length && (
          <>
            <h2 className={styles.archivelPrposalsHeader}>
              {t('project.design.archivedProposals', 'Archived proposals')}
            </h2>
            {statusGroups[DesignStatus.Archival].map((iteration) => (
              <DesignProposal
                defaultExpanded={false}
                key={iteration.id}
                designProposal={iteration}
                project={project}
              />
            ))}
          </>
        )}
    </div>
  );
};

export default DesignIterations;
