import React, { FC, useContext } from 'react';
import styles from './StepNavigationBar.module.css';
import { useTranslation } from 'react-i18next';
import { StepsControllerContext } from './CardWithSteps';
import ButtonComponent from '../buttons/ButtonComponent';

export interface StepNavigationBarProps {
  canGoNext?: () => boolean | Promise<boolean>;
  canGoBack?: () => boolean | Promise<boolean>;
  canFinish?: () => boolean | Promise<boolean>;

  goNextText?: string;
  goBackText?: string;

  showCancel?: boolean;
  showFinish?: boolean;
  hideNextStep?: boolean;
  finishActionText?: string;
  disableNextStep?: boolean;
}

const StepNavigationBar: FC<StepNavigationBarProps> = ({
  canGoNext,
  canGoBack,
  canFinish,
  showCancel,
  showFinish,
  finishActionText,
  goNextText = 'next',
  goBackText = 'previous',
  hideNextStep = false,
  disableNextStep = false,
}) => {
  const stepsController = useContext(StepsControllerContext);

  const { t } = useTranslation();

  const onGoBack = async () => {
    if (
      stepsController?.hasPreviousStep &&
      (!canGoBack || (await canGoBack()))
    ) {
      stepsController?.goBack();
    }
  };

  const onGoNext = async () => {
    if (stepsController?.hasNextStep && (!canGoNext || (await canGoNext()))) {
      stepsController?.goNext();
    }
  };

  const onFinish = async () => {
    if (!stepsController?.hasNextStep && (!canFinish || (await canFinish()))) {
      stepsController?.finish();
    }
  };

  return (
    <div className={styles.controls}>
      <div className={styles.controlsLeft}>
        {stepsController?.hasPreviousStep && (
          <>
            <ButtonComponent
              variant='TEXT'
              size='BIG'
              onClick={onGoBack}
              className={styles.prev}
            >
              {goBackText || t('common.previous')}
            </ButtonComponent>
          </>
        )}
      </div>
      <div className={styles.controlsRight}>
        {stepsController?.hasNextStep ? (
          <>
            {!hideNextStep && (
              <ButtonComponent
                size='BIG'
                colorScheme='DARK'
                variant={'FILLED_IN'}
                onClick={onGoNext}
                disabled={disableNextStep}
              >
                {goNextText || t(`common.next`)}
              </ButtonComponent>
            )}
          </>
        ) : (
          <div className={styles.lastStepActions}>
            {showCancel && (
              <ButtonComponent
                variant='OUTLINE'
                size='BIG'
                onClick={stepsController?.onCancel}
              >
                {t('common.cancel')}
              </ButtonComponent>
            )}
            {showFinish && (
              <ButtonComponent
                size='BIG'
                colorScheme='DARK'
                onClick={() => onFinish()}
              >
                {finishActionText}
              </ButtonComponent>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StepNavigationBar;
