import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ActionFailed from '../common/components/alerts/ActionFailed';

type OrderCheckoutFailedProps = {
  restartSubmitOrder: () => void;
};

const OrderCheckoutFailed: FC<OrderCheckoutFailedProps> = ({
  restartSubmitOrder,
}) => {
  const { t } = useTranslation();

  return (
    <ActionFailed
      restartProcess={restartSubmitOrder}
      textPrimary={t(
        'order.submit.failedPrimary',
        'Sorry, we got our wires crossed'
      )}
      textSecondary={t(
        'order.submit.failedSecondary',
        'We couldn’t submit your order because of technical problems. Please try again'
      )}
      restartProcessText={t(
        'order.submit.retrySubmit',
        'Retry placing an order'
      )}
    />
  );
};

export default OrderCheckoutFailed;
