import { Radio } from '@mui/material';
import { FC } from 'react';
import { RadioOption, themeType } from './RadioButton';
import { Circle } from 'phosphor-react';
import styles from './RadioOption.module.css';
import classNames from 'classnames';

interface RadioOptionProps extends RadioOption {
  theme: themeType;
  error?: string;
}

const RadioOptionComponent: FC<RadioOptionProps> = ({
  value,
  theme,
  error,
}) => {
  const themeClasses = {
    [styles.light]: theme === 'LIGHT',
    [styles.dark]: theme === 'DARK',
  };

  return (
    <Radio
      value={value}
      icon={<Circle size={20} />}
      classes={{
        root: classNames(styles.root, themeClasses, {
          [styles.error]: !!error,
        }),
        checked: styles.checked,
      }}
    />
  );
};

export default RadioOptionComponent;
