import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import styles from './LogInLogOutButton.module.css';

const LogInLogOutButton = () => {
  const { t } = useTranslation();
  const { logout, isAuthenticated, loginWithRedirect } = useAuth0();

  if (isAuthenticated) {
    return (
      <button className={styles.button} onClick={() => logout()}>
        {t('userDrawer.logout')}
      </button>
    );
  }

  return (
    <button className={styles.button} onClick={() => loginWithRedirect()}>
      {t('userDrawer.login')}
    </button>
  );
};

export default LogInLogOutButton;
