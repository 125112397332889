import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ActionButtons from '../common/components/modals/ActionButtons';
import styles from './DeleteAccount.module.css';

interface DeleteAccountProps {
  onClose: () => void;
  onSubmit: () => void;
}

const DeleteAccount: FC<DeleteAccountProps> = ({ onClose, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <h2 className={styles.title}>
          {t('userSettings.privacy.account.delete.title', 'Delete account')}
        </h2>
        <p className={styles.text}>
          {t(
            'userSettings.privacy.account.delete.info',
            'By agreeing to delete your account you will loose access to sales portal, as well as to all your progress and designs. Are you sure you want to continue?'
          )}
        </p>
      </div>
      <ActionButtons
        onCancel={onClose}
        onSubmit={onSubmit}
        canSubmit={true}
        onCancelText={t('common.back', 'Go back')}
        onSubmitText={t(
          'userSettings.privacy.account.delete.submit',
          'Delete my account'
        )}
        isDelete={true}
      />
    </div>
  );
};

export default DeleteAccount;
