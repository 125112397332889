import { FC } from 'react';
import { WarningCircle } from 'phosphor-react';
import ButtonComponent from '../buttons/ButtonComponent';
import CardTitle from '../CardWithSteps/CardTitle';
import styles from './ActionFailed.module.css';

type ActionFailedProps = {
  textPrimary: string;
  textSecondary: string;
  restartProcess: () => void;
  restartProcessText: string;
};

const ActionFailed: FC<ActionFailedProps> = ({
  textPrimary,
  textSecondary,
  restartProcess,
  restartProcessText,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.confirmation}>
        <CardTitle title={textPrimary} icon={WarningCircle} />
        <div className={styles.texts}>{textSecondary}</div>
      </div>
      <ButtonComponent
        variant={'FILLED_IN'}
        size='BIG'
        onClick={restartProcess}
      >
        {restartProcessText}
      </ButtonComponent>
    </div>
  );
};

export default ActionFailed;
