import { FormControl, InputLabel, Input, FormHelperText } from '@mui/material';
import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './InputMultiline.module.css';

interface InputMultilineProps {
  name: string;
  label: string;
  value?: string | number;
  error?: string;
  description?: string;
  required?: boolean;
}

const InputMultiline: FC<InputMultilineProps> = ({
  name,
  label,
  error,
  description,
  required,
  ...rest
}) => {
  return (
    <FormControl
      classes={{ root: styles.container }}
      error={!!error}
      required={required}
    >
      <div
        className={classNames(styles.box, {
          [styles.inputError]: error && error.length,
        })}
      >
        <InputLabel
          htmlFor={name}
          classes={{
            root: classNames(styles.label),
            error: classNames({ [styles.labelError]: error }),
          }}
        >
          {label}
        </InputLabel>
        <Input
          id={name}
          name={name}
          inputProps={{ ...rest }}
          aria-describedby={'describe-' + name + '-error'}
          classes={{
            input: classNames(styles.input),
            underline: classNames(styles.inputUnderline),
            error: styles.inputError,
          }}
          className={styles.inputMultiline}
        />
      </div>
      {error && error.length && (
        <FormHelperText
          id={'describe-' + name + '-error'}
          classes={{
            root: styles.helper,
          }}
        >
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default InputMultiline;
