import classNames from 'classnames';
import { FC } from 'react';
import styles from './ProfilePicturePlaceholder.module.css';

interface ProfilePicturePlaceHolderProps {
  size: 'BIG' | 'MEDIUM' | 'SMALL';
  firstName: string | undefined;
  lastName?: string;
  email?: string;
  theme?: 'default' | 'yellow';
}

const ProfilePicturePlaceHolder: FC<ProfilePicturePlaceHolderProps> = ({
  size,
  firstName,
  lastName = '',
  email = '',
  theme = 'default',
}) => {
  const sizeClasses = {
    [styles.big]: size === 'BIG',
    [styles.middle]: size === 'MEDIUM',
    [styles.small]: size === 'SMALL',
  };
  const themeClasses = {
    [styles.themeDefault]: theme === 'default',
    [styles.themeYellow]: theme === 'yellow',
  };

  const getName = () => {
    if (firstName) {
      return firstName + (lastName ? ` ${lastName}` : '');
    } else if (lastName) {
      return lastName;
    } else {
      return email;
    }
  };

  return (
    <div className={classNames(styles.container, sizeClasses, themeClasses)}>
      <abbr title={getName()}>
        {getInitials(firstName || '', lastName, email)}
      </abbr>
    </div>
  );
};

const getInitials = (
  firstName: string,
  lastName: string,
  email: string
): string => {
  if (firstName) {
    if (lastName) {
      return `${firstName.charAt(0)}${lastName.charAt(0)}`;
    } else {
      return `${firstName.charAt(0)}${firstName.charAt(1)}`;
    }
  } else if (lastName) {
    return `${lastName.charAt(0)}${lastName.charAt(1)}`;
  } else {
    return `${email.charAt(0)}${email.charAt(1)}`;
  }
};

export default ProfilePicturePlaceHolder;
