import { FC, ReactNode } from 'react';
import styles from './OrderSectionContent.module.css';

type OrderSectionContentProps = {
  children: ReactNode;
};

const OrderSectionContent: FC<OrderSectionContentProps> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

export default OrderSectionContent;
