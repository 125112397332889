import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import styles from './Loading.module.css';
import { ReactComponent as Logo } from '../../assets/icons/evi_logo.svg';

type LoadingProps = {
  type?: 'BIG' | 'SMALL';
  description?: string;
  verticalPosition?: 'top' | 'center';
  children?: ReactNode;
  isLoading?: boolean;
};

const Loading: FC<LoadingProps> = ({
  type = 'BIG',
  verticalPosition = 'center',
  description,
  children,
  isLoading = true,
}) => {
  if (children && !isLoading) {
    return <>{children}</>;
  }

  const loaders = {
    BIG: (
      <>
        <div style={{ position: 'relative' }}>
          <div className={classNames(styles.circle, styles.spinner)}> </div>
          <Logo className={styles.verticalCenter} />
        </div>
        {description && (
          <span className={styles.description}>{description}</span>
        )}
      </>
    ),
    SMALL: (
      <div className={styles.bounceContainer}>
        <BounceLoader color='#595959' size={36} />
      </div>
    ),
  };

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.wrapper, {
          [styles.verticalTop]: verticalPosition === 'top',
          [styles.verticalCenter]: verticalPosition === 'center',
          [styles.horizontalCenter]: verticalPosition === 'top',
          [styles.small]: type === 'SMALL',
          [styles.big]: type === 'BIG',
        })}
      >
        {loaders[type]}
      </div>
    </div>
  );
};

export default Loading;
