import React, { FC, ReactNode, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { hotjar } from 'react-hotjar';

type HotjarProps = {
  children: ReactNode;
  hjid?: string;
  hjsv?: string;
};

const Hotjar: FC<HotjarProps> = ({ children, hjid, hjsv }) => {
  const { isAuthenticated, user } = useAuth0();

  const hjidNumber = hjid ? parseInt(hjid) : undefined;
  const hjsvNumber = hjsv ? parseInt(hjsv) : undefined;

  useEffect(() => {
    if (hjidNumber && hjsvNumber) {
      hotjar.initialize(hjidNumber, hjsvNumber);
    }
  }, [hjidNumber, hjsvNumber]);

  useEffect(() => {
    if (isAuthenticated && hjidNumber && hjsvNumber) {
      hotjar.identify(user?.email ?? '', {
        name: user?.name,
      });
    }
  }, [isAuthenticated, hjidNumber, hjsvNumber, user?.email, user?.name]);

  return <>{children}</>;
};

export default Hotjar;
