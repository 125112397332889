import { FC } from 'react';
import { ProgressLineStepType } from '../ProgressLine';
import styles from './ProgressLineStep.module.css';
import { Circle, LockKey } from 'phosphor-react';
import classNames from 'classnames';
import ButtonComponent from '../../../common/components/buttons/ButtonComponent';

export type ProgressLineState = 'ACTIVE' | 'OPEN' | 'DISABLED';

interface ProgressLineStepProps {
  step: ProgressLineStepType;
  isLast: boolean;
}

const ProgressLineStep: FC<ProgressLineStepProps> = ({ step }) => {
  return (
    <div
      className={classNames(styles.root, {
        [styles.active]: step.state !== 'DISABLED',
      })}
    >
      <div className={styles.state}>
        {step.state === 'ACTIVE' && (
          <Circle className={styles.icon} size={16} />
        )}
        {step.state === 'OPEN' && (
          <Circle className={styles.icon} size={16} weight='fill' />
        )}
        {step.state === 'DISABLED' && (
          <LockKey
            className={classNames(styles.icon, styles.locker)}
            size={24}
          />
        )}
        <span className={styles.line} />
      </div>
      <div className={styles.content}>
        {!!step.onTitleClick ? (
          <ButtonComponent
            onClick={step.onTitleClick}
            variant={'TEXT'}
            size={'SMALL'}
            className={styles.titleBtn}
          >
            {step.title}
          </ButtonComponent>
        ) : (
          <h4 className={styles.title}>{step.title}</h4>
        )}

        {step.component}
      </div>
    </div>
  );
};

export default ProgressLineStep;
