import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ISlotDto } from 'shared';
import ButtonComponent from '../../../common/components/buttons/ButtonComponent';
import styles from './Slots.module.css';
import classNames from 'classnames';

interface SlotsProps {
  availableSlots: ISlotDto[];
  selectedSlot: ISlotDto | null;
  onSelectSlot: (slot: ISlotDto | null) => void;
  displaySelectedSlot?: boolean;
}

type SortedSlots = {
  morning: ISlotDto[];
  midday: ISlotDto[];
  afternoon: ISlotDto[];
};

const getTime = (date: Moment) => {
  const dateFromMoment = moment(date).local();
  return dateFromMoment.format('HH:mm');
};

const setTime = (date: Moment, hour: number) => {
  return date.clone().hour(hour).minute(0).second(0);
};

const isSameSlot = (first: ISlotDto, second: ISlotDto): boolean => {
  const sameStart =
    first.start.clone().get('hour') === second.start.clone().get('hour') &&
    first.start.clone().get('minute') === second.start.clone().get('minute');
  const sameEnd =
    first.end.clone().get('hour') === second.end.clone().get('hour') &&
    first.end.clone().get('minute') === second.end.clone().get('minute');
  return sameStart && sameEnd;
};

const Slots: FC<SlotsProps> = observer(
  ({
    availableSlots,
    selectedSlot,
    onSelectSlot,
    displaySelectedSlot = false,
  }) => {
    const { t } = useTranslation();

    const sortedSlots: SortedSlots = useMemo(() => {
      const isInSlots = availableSlots.find((s) =>
        s.start.isSame(selectedSlot?.start)
      );

      const slots: ISlotDto[] =
        displaySelectedSlot && selectedSlot && !isInSlots
          ? [...availableSlots, selectedSlot].sort(
              (a, b) => a.start.valueOf() - b.start.valueOf()
            )
          : availableSlots;

      return {
        morning: slots.filter((s) => s.end.isBefore(setTime(s.start, 11))),
        midday: slots.filter(
          (s) =>
            s.start.isSameOrAfter(setTime(s.start, 11)) &&
            s.end.isSameOrBefore(setTime(s.start, 14))
        ),
        afternoon: slots.filter((s) => s.end.isSameOrAfter(setTime(s.end, 14))),
      };
    }, [availableSlots]);

    return (
      <div>
        <div className={styles.box}>
          <div className={styles.item}>
            <span className={styles.title}>{t('meeting.time.morning')}</span>
            {sortedSlots.morning.map((x, i) => {
              const isSelected = selectedSlot && isSameSlot(x, selectedSlot);

              return (
                <ButtonComponent
                  key={`morning_${i}`}
                  variant={'OUTLINE'}
                  size={'BIG'}
                  onClick={() => onSelectSlot(x)}
                  className={classNames(styles.slotButton, {
                    [styles.selected]: isSelected,
                  })}
                >
                  {getTime(x.start)} - {getTime(x.end)}
                </ButtonComponent>
              );
            })}
          </div>
          <div className={styles.item}>
            <span className={styles.title}>{t('meeting.time.midday')}</span>
            {sortedSlots.midday.map((x, i) => {
              const isSelected = selectedSlot && isSameSlot(x, selectedSlot);

              return (
                <ButtonComponent
                  key={`midday_${i}`}
                  variant={'OUTLINE'}
                  size={'BIG'}
                  onClick={() => onSelectSlot(x)}
                  className={classNames(styles.slotButton, {
                    [styles.selected]: isSelected,
                  })}
                >
                  {getTime(x.start)} - {getTime(x.end)}
                </ButtonComponent>
              );
            })}
          </div>
          <div className={styles.item}>
            <span className={styles.title}>{t('meeting.time.afternnon')}</span>
            {sortedSlots.afternoon.map((x, i) => {
              const isSelected = selectedSlot && isSameSlot(x, selectedSlot);

              return (
                <ButtonComponent
                  key={`mafternoon_${i}`}
                  variant={'OUTLINE'}
                  size={'BIG'}
                  onClick={() => onSelectSlot(x)}
                  className={classNames(styles.slotButton, {
                    [styles.selected]: isSelected,
                  })}
                >
                  {getTime(x.start)} - {getTime(x.end)}
                </ButtonComponent>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);

export default Slots;
