import React, { FC, useContext } from 'react';
import { AccordionDetailsProps as MUIAccordionDetailsProps } from '@mui/material';
import { AccordionDetails as MUIAccordionDetails } from '@mui/material';
import { AccordionContext } from './Accordion';
import classNames from 'classnames';
import styles from './AccordionDetails.module.css';
import { mergeMUIClasses } from '../../utils/mergeMUIClasses';

const AccordionDetails: FC<MUIAccordionDetailsProps> = ({ ...props }) => {
  const accordionContext = useContext(AccordionContext);
  const classes = mergeMUIClasses(props.classes, {
    root: classNames({
      [styles.dark]: accordionContext?.colorScheme === 'DARK',
    }),
  });

  return <MUIAccordionDetails classes={classes} {...props} />;
};

export default AccordionDetails;
