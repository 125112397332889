import { Field, Form, Formik, FormikProps } from 'formik';
import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IProjectSimpleDto, IRenameProjectCommandBody } from 'shared';
import ModalWrapper from '../../common/components/modals/ModalWrapper';
import ActionModal from '../designs/ActionModal';
import * as Yup from 'yup';
import { useContext } from 'react';
import { ProjectContext } from '../ProjectStore';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import InputField from '../../common/components/inputs/InputField';
import styles from './ChangeNameModal.module.css';

type ChangeNameModalProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  project?: IProjectSimpleDto | null;
};

const ChangeNameModal: FC<ChangeNameModalProps> = observer(
  ({ isOpen, setIsOpen, project }) => {
    const { t } = useTranslation();

    const formik = useRef<FormikProps<IRenameProjectCommandBody>>(null);

    const projectStore = useContext(ProjectContext);

    return (
      <ModalWrapper
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sizeVariant='SMALL'
      >
        <ActionModal
          onCancel={() => setIsOpen(false)}
          onSubmit={async () => await formik.current?.submitForm()}
          title={t('project.rename.title')}
        >
          <Formik<IRenameProjectCommandBody>
            enableReinitialize
            innerRef={formik}
            initialValues={{ name: project?.name ?? '' }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .required(t('project.rename.nameRequired'))
                .max(
                  64,
                  t(
                    'project.rename.nameMaxLength',
                    'The project name must be less than 64 characters!'
                  )
                ),
            })}
            onSubmit={async (values) => {
              if (project) {
                await flowResult(projectStore?.rename(project?.id, values));
              }
              setIsOpen(false);
            }}
          >
            {(formState) => (
              <Form className={styles.changeNameForm}>
                <Field
                  as={InputField}
                  label={t('project.rename.name')}
                  name='name'
                  type='text'
                  variant='MULTILINE'
                  error={formState.errors.name}
                />
              </Form>
            )}
          </Formik>
        </ActionModal>
      </ModalWrapper>
    );
  }
);

export default ChangeNameModal;
