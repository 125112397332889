import Accordion from '../../common/components/accordion/Accordion';
import AccordionDetails from '../../common/components/accordion/AccordionDetails';
import AccordionSummary from '../../common/components/accordion/AccordionSummary';
import { FC, useState } from 'react';
import { DesignStatus, IProjectDto } from 'shared';
import styles from './DesignProposal.module.css';
import OfferDetails from './OfferDetails';
import ProposalStatus from './ProposalStatus';
import ProjectSectionHeader from '../common/ProjectSectionHeader';
import DesignProposalActions from './DesignProposalActions';
import { observer } from 'mobx-react';
import { IterationStore } from './IterationStore';
import DesignTile from '../../common/components/DesignTile/DesignTile';
import { Grid } from '@mui/material';

interface DesignProposalProps {
  defaultExpanded: boolean;
  project: IProjectDto;
  designProposal: IterationStore;
  disableActions?: boolean;
}

const DesignProposal: FC<DesignProposalProps> = ({
  defaultExpanded,
  designProposal,
  project,
  disableActions = false,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(defaultExpanded);
  const accordionTimeout = 500;

  return (
    <Accordion
      classes={{
        root: styles.accordionRoot,
        rounded: styles.accordionRounded,
      }}
      defaultExpanded={defaultExpanded}
      expanded={isExpanded}
      onChange={(event, expanded) => setIsExpanded(expanded)}
      TransitionProps={{ timeout: accordionTimeout }}
    >
      <AccordionSummary
        classes={{
          root: styles.summaryRoot,
          expanded: styles.expanded,
          content: styles.summaryContent,
        }}
      >
        <ProjectSectionHeader name={designProposal.name} />
        <ProposalStatus status={designProposal.status} />
      </AccordionSummary>
      <AccordionDetails classes={{ root: styles.details }}>
        <div className={styles.content}>
          <section className={styles.section}>
            <Grid container rowGap={4}>
              <Grid item xs={12} md={4}>
                <OfferDetails designProposal={designProposal} />
              </Grid>
              <Grid item xs={12} md={8}>
                <DesignTile
                  projectId={project.id}
                  iteration={designProposal}
                  allowLinks={
                    !disableActions &&
                    designProposal.status !== DesignStatus.Active
                  }
                  size={'BIG'}
                />
              </Grid>
            </Grid>
          </section>
        </div>
        {!disableActions && (
          <DesignProposalActions
            designProposal={designProposal}
            projectId={project.id}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default observer(DesignProposal);
