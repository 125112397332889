import { FC } from 'react';
import { IterationStore } from '../../../project/designs/IterationStore';
import styles from './DesignTile.module.css';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../../components/buttons/ButtonComponent';
import { NavLink } from 'react-router-dom';
import ProposalStatus from '../../../project/designs/ProposalStatus';
import { DesignStatus } from 'shared';
import classNames from 'classnames';

interface DesignTileProps {
  iteration: IterationStore;
  projectId: number;
  className?: string;
  allowLinks?: boolean;
  size?: 'SMALL' | 'BIG';
}

const DesignTile: FC<DesignTileProps> = ({
  iteration,
  projectId,
  className,
  allowLinks = true,
  size = 'SMALL',
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        className,
        styles.root,
        { [styles.small]: size === 'SMALL' },
        { [styles.big]: size === 'BIG' }
      )}
    >
      <h4 className={styles.title}>{iteration.name}</h4>
      <div className={styles.box}>
        <div className={styles.view}>
          {iteration.visualizationPreviewImage?.uri ? (
            <img src={iteration.visualizationPreviewImage?.uri} />
          ) : (
            <span className={styles.imageCover} />
          )}
          {iteration.visualizationPreviewLink && (
            <a
              href={iteration.visualizationPreviewLink}
              target={'_blank'}
              className={styles.link}
            >
              {t('dashboard.design.3dView')}
            </a>
          )}
        </div>
        {iteration.priceDetails[0]?.uri && (
          <div className={styles.pdfBox}>
            <span>{t('dashboard.design.pdfPreview')}</span>
            <a
              href={iteration.priceDetails[0]?.uri}
              target={'_blank'}
              className={styles.pdfLink}
            >
              {t('dashboard.design.pdfOpen')}
            </a>
          </div>
        )}
      </div>
      {allowLinks && (
        <>
          {iteration.status === DesignStatus.Archival ? null : (
            <div className={styles.orderBox}>
              {iteration.status === DesignStatus.Active ? (
                <NavLink
                  to={{
                    pathname: `/project/${projectId}/design`,
                    state: { projectId },
                  }}
                  className={styles.order}
                >
                  <ButtonComponent
                    variant={'TEXT'}
                    size={'SMALL'}
                    className={styles.orderBtn}
                  >
                    {t('dashboard.design.order')}
                  </ButtonComponent>
                </NavLink>
              ) : (
                <NavLink
                  to={{
                    pathname: `/project/${projectId}/order`,
                    state: { projectId },
                  }}
                  className={styles.order}
                >
                  <ProposalStatus status={iteration.status} />
                </NavLink>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DesignTile;
