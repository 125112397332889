import { Info } from 'phosphor-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountTypeDto } from 'shared';
import ButtonComponent from '../common/components/buttons/ButtonComponent';
import styles from './SocialProviderPasswordInfo.module.css';

interface SocialProviderPasswordInfoProps {
  accountType?: AccountTypeDto;
}

const SocialProviderPasswordInfo: FC<SocialProviderPasswordInfoProps> = ({
  accountType,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <Info />{' '}
        {t(
          'userSettings.privacy.password.socialProvider.info',
          'Your account was created using external credentials (e.g. facebook, google). To reset password you need to change it in your social media account.'
        )}
      </div>
      <div className={styles.bottom}>
        {accountType === AccountTypeDto.GoogleAccount && (
          <form action='https://myaccount.google.com/signinoptions/password'>
            <ButtonComponent
              variant='FILLED_IN'
              size='SMALL'
              color='LIGHT'
              type='submit'
            >
              {t(
                'userSettings.privacy.password.socialProvider.google',
                'Go to google account'
              )}
            </ButtonComponent>
          </form>
        )}
      </div>
    </div>
  );
};

export default SocialProviderPasswordInfo;
