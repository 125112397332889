import { useAuth0 } from '@auth0/auth0-react';
import { FC, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { appInsights } from 'shared';
import AppLoggedUser from './AppLoggedUser';

const AppLoggedUserLayout: FC = () => {
  const { user, isLoading } = useAuth0();

  useEffect(() => {
    if (!isLoading) {
      appInsights.setAuthenticatedUserContext(user?.email ?? '');
    }
  }, [isLoading, user]);

  return (
    <>
      <Switch>
        <Route path='/' component={AppLoggedUser} />
      </Switch>
    </>
  );
};

export default AppLoggedUserLayout;
