import { AppState } from '@auth0/auth0-react';
import { createBrowserHistory } from 'history';

export const routerHistory = createBrowserHistory();

export const onRedirectCallback = (appState: AppState) => {
  routerHistory.replace(
    appState?.returnTo || window.location.pathname,
    appState
  );
};
