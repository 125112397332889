import classNames from 'classnames';
import { DownloadSimple } from 'phosphor-react';
import React, { FC } from 'react';
import ButtonComponent from '../common/components/buttons/ButtonComponent';
import styles from './DownloadLink.module.css';

type DownloadLinkProps = {
  text: string;
  link?: string;
  className?: string;
};

const DownloadLink: FC<DownloadLinkProps> = ({ text, link, className }) => {
  return (
    <a
      className={classNames(styles.downloadLink, className)}
      href={link}
      target='_blank'
      rel='noreferrer'
    >
      <ButtonComponent
        variant='TEXT'
        colorScheme='LIGHT'
        size={'SMALL'}
        className={styles.downloadData}
      >
        <DownloadSimple />
        {text}
      </ButtonComponent>
    </a>
  );
};

export default DownloadLink;
