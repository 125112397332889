import React, { createContext, FC } from 'react';
import {
  Accordion as MUIAccordion,
  AccordionProps as MUIAccordionProps,
} from '@mui/material';
import classNames from 'classnames';
import styles from './Accordion.module.css';
import { mergeMUIClasses } from '../../utils/mergeMUIClasses';

export type colorSchemeType = 'LIGHT' | 'DARK';

type AccordionProps = MUIAccordionProps & {
  colorScheme?: colorSchemeType;
};

export const AccordionContext = createContext<AccordionProps | undefined>(
  undefined
);

const Accordion: FC<AccordionProps> = (props) => {
  const { colorScheme, classes, ...muiProps } = props;

  const muiClasses = mergeMUIClasses(props.classes, {
    root: classNames(styles.container),
  });

  return (
    <AccordionContext.Provider value={props}>
      <MUIAccordion classes={muiClasses} {...muiProps} />
    </AccordionContext.Provider>
  );
};

Accordion.defaultProps = {
  colorScheme: 'LIGHT',
};

export default Accordion;
