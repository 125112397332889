import { FC } from 'react';
import { IEmployeeDto, ISlotDto, MeetingType } from 'shared';
import styles from './DesignerCard.module.css';
import ProfilePicturePlaceHolder from '../../../common/components/ProfilePicturePlaceholder';
import { Grid } from '@mui/material';
import { Moment } from 'moment';
import ButtonComponent from '../../../common/components/buttons/ButtonComponent';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { CalendarCheck, Clock, MapPin } from 'phosphor-react';

interface DesignerCardProps {
  designer: IEmployeeDto;
  meetingType: MeetingType;
  location: string | undefined;
  date: Moment;
  slot: ISlotDto;
  onReschedule?: () => void;
  isNegative?: boolean;
  size?: 'SMALL' | 'BIG';
}

const DesignerCard: FC<DesignerCardProps> = ({
  designer,
  meetingType,
  location,
  date,
  slot,
  onReschedule,
  size = 'BIG',
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={classnames(styles.root, styles.negative, {
        [styles.small]: size === 'SMALL',
      })}
    >
      <div className={styles.author}>
        <ProfilePicturePlaceHolder
          size={'SMALL'}
          firstName={designer.displayName}
          email={designer.email}
        />
        <span>{designer.displayName}</span>
      </div>
      <Grid container rowGap={2}>
        <Grid item xs={12} sm={6} md={4}>
          <span className={styles.item}>
            <CalendarCheck size={28} weight='light' className={styles.icon} />
            {date.local().format('DD.MM.YYYY')}
          </span>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <span className={styles.item}>
            <Clock size={28} weight='light' className={styles.icon} />
            {`${slot.start.local().format('HH:mm')} - ${slot.end
              .local()
              .format('HH:mm')}`}
          </span>
        </Grid>
        <Grid item xs={12} md={4}>
          <span className={styles.item}>
            <MapPin size={28} weight='light' className={styles.icon} />
            {location && meetingType === MeetingType.Online ? (
              <a href={location} target={'_blank'} className={styles.teams}>
                {t('meeting.teamsMeeting')}
              </a>
            ) : (
              <span className={styles.name}>{meetingType}</span>
            )}
          </span>
        </Grid>
        {!!onReschedule && (
          <Grid item xs={12}>
            <div className={styles.reschedule}>
              <ButtonComponent
                colorScheme='DARK'
                variant={'TEXT'}
                size='BIG'
                onClick={onReschedule}
                className={styles.link}
              >
                {t('meeting.rescheduleTitle')}
              </ButtonComponent>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default DesignerCard;
