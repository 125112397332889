import { observer } from 'mobx-react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { OrderSummaryStore } from './OrderSummaryStore';
import EmptyOrder from './visual/EmptyOrder';
import OrderContainer from './visual/OrderContainer';
import OrderLoadFailed from './visual/OrderLoadFailed';
import OrderPaymentSection from './payment/OrderPaymentSection';
import OrderInvoiceSection from './invoice/OrderInvoiceSection';
import BackLink from '../../common/components/BackLink';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, useService } from 'shared';
import { Skeleton } from '@mui/material';

const OrderSummary = observer(() => {
  const { t } = useTranslation();
  const { projectId } = useParams<Record<string, string | undefined>>();

  const orderSummaryStore = useService<OrderSummaryStore>(OrderSummaryStore);

  const loadSummary = useCallback(() => {
    if (projectId) {
      orderSummaryStore.loadOrderSummary(parseInt(projectId));
    }
  }, [projectId, orderSummaryStore]);

  useEffect(loadSummary, [loadSummary]);

  if (
    orderSummaryStore.loadingState === 'loading' ||
    orderSummaryStore.loadingState === 'not-initialized'
  ) {
    return <Skeleton variant='rectangular' width='100%' height='500px' />;
  }

  if (orderSummaryStore.loadingState === 'error') {
    return <OrderLoadFailed errorType='loadError' onRetry={loadSummary} />;
  }

  if (
    !projectId ||
    !orderSummaryStore.orderDto ||
    (!orderSummaryStore.orderDto && orderSummaryStore.loadingState === 'loaded')
  ) {
    return <EmptyOrder />;
  }

  return (
    <AppInsightsErrorBoundary
      onError={() => (
        <OrderLoadFailed
          errorType='scriptError'
          onRetry={() => window.location.reload}
        />
      )}
      appInsights={reactPlugin}
    >
      <OrderContainer
        orderNumber={orderSummaryStore.orderDto.number}
        submittedAt={orderSummaryStore.orderDto.submittedAt}
      >
        <OrderPaymentSection
          order={orderSummaryStore.orderDto}
          projectId={parseInt(projectId)}
        />
        <OrderInvoiceSection invoices={orderSummaryStore.orderDto.invoices} />
        <BackLink
          to={`/?projectId=${projectId}`}
          text={t('common.goToHomePage')}
        />
      </OrderContainer>
    </AppInsightsErrorBoundary>
  );
});

export default OrderSummary;
