import { ShoppingCartSimple } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import EmptyStateComponent from '../../../common/components/empty-state-component/EmptyStateComponent';
import styles from './EmptyOrder.module.css';

const EmptyOrder = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <EmptyStateComponent
        icon={ShoppingCartSimple}
        title={t('project.order.emptyState.title', 'No order here yet')}
      >
        <p className={styles.text}>
          {t(
            'project.order.emptyState.description',
            'Here you will find the details of your order and could track its progress'
          )}
        </p>
      </EmptyStateComponent>
    </div>
  );
};

export default EmptyOrder;
