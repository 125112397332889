import { FC } from 'react';
import ComponentWithHeader from '../../common/components/ComponentWithHeader';
import BookMeetingHeader from './BookMeetingHeader';
import BookMeetingPage from './BookMeetingPage/BookMeetingPage';

const BookMeetingWithHeader: FC = () => {
  return (
    <ComponentWithHeader
      header={<BookMeetingHeader />}
      component={<BookMeetingPage />}
    />
  );
};

export default BookMeetingWithHeader;
