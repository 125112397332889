import moment from 'moment';
import { Moment } from 'moment';
import { FC, useEffect, useRef, useState } from 'react';
import {
  areDatesEqual,
  getWeekSpan,
  IAvailableSlotsDto,
  IEmployeeDto,
  MeetingType,
} from 'shared';
import Day from './Day';
import styles from './SelectTime.module.css';
import Slots from './Slots';
import classNames from 'classnames';
import { CalendarBlank, ArrowLeft, ArrowRight } from 'phosphor-react';
import useOnClickOutside from '../../../common/hooks/useOnClickOutside';
import DatePicker from './../DatePicker/DatePicker';
import ValidationPopup from '../../../common/components/ValidationPopup';
import { useTranslation } from 'react-i18next';
import Loading from '../../../common/components/Loading';
import _ from 'lodash';
import { SelectTimeStore } from '../../BookMeetingStore';
import { observer } from 'mobx-react';
import { flowResult } from 'mobx';
import { useSwipeable } from 'react-swipeable';

interface SelectTimeProps {
  location: string;
  selectTimeStore: SelectTimeStore;
  isMeetingHourErrorShown: boolean;
  setIsMeetingHourErrorShown: (v: boolean) => void;
  isMeetingDateErrorShown: boolean;
  setIsMeetingDateErrorShown: (v: boolean) => void;
  designer?: IEmployeeDto | null;
  meetingType?: MeetingType;
}

const getAllDays = (startDate: Moment, endDate: Moment): Moment[] => {
  var days: Moment[] = [];
  var currentDate = startDate.clone().startOf('day');
  var lastDate = endDate.clone().startOf('day');

  while (currentDate.isSameOrBefore(lastDate)) {
    days.push(currentDate.clone());
    currentDate.add(1, 'days');
  }

  return days;
};

const getSlotsForDate = (
  allSlots: IAvailableSlotsDto[] | null,
  date: Moment
) => {
  if (allSlots) {
    const slots = allSlots.find((x) => areDatesEqual(x.date, date))?.slots;
    return slots ?? [];
  }
  return [];
};

const SelectTime: FC<SelectTimeProps> = observer(
  ({
    location,
    selectTimeStore,
    meetingType,
    designer,
    isMeetingHourErrorShown,
    setIsMeetingHourErrorShown,
    isMeetingDateErrorShown,
    setIsMeetingDateErrorShown,
  }) => {
    const { t } = useTranslation();

    const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
    const { from, to } = getWeekSpan(selectTimeStore.selectedDateSpan.from);
    const days = getAllDays(from, to);

    useEffect(() => {
      flowResult(
        selectTimeStore.ensureFetched(location, meetingType, designer)
      );
    }, [location, selectTimeStore, meetingType, designer]);

    const handleOpenCalendar = () => {
      if (selectTimeStore.isLoadingAvailableSpots) return;
      setIsCalendarOpen(true);
    };

    const handleWeekChange = (
      date: Moment | null,
      selectionState?: 'partial' | 'shallow' | 'finish'
    ) => {
      if (selectionState === 'finish') {
        selectTimeStore.setSelectedDateSpan(getWeekSpan(date));
        setIsCalendarOpen(false);
        selectTimeStore.setSelectedDate(null);
      }
    };

    const canSelectPreviousWeek = () => {
      const firstDayOfCurrentWeek = moment().startOf('week');
      return firstDayOfCurrentWeek.diff(from) !== 0;
    };

    const handleSelectPreviousWeek = () => {
      const previousWeek = from.add(-7, 'days');
      selectTimeStore.setSelectedDateSpan(getWeekSpan(previousWeek));
      selectTimeStore.setSelectedDate(null);
    };

    const handleSelectNextWeek = () => {
      const nextWeek = from.add(7, 'days');
      selectTimeStore.setSelectedDateSpan(getWeekSpan(nextWeek));
      selectTimeStore.setSelectedDate(null);
    };

    const handleSelectDay = (day: Moment) => {
      selectTimeStore.setSelectedDate(day);
      selectTimeStore.setSelectedSlot(null);
    };

    const weekChangeHandlers = useSwipeable({
      onSwipedLeft: handleSelectNextWeek,
      onSwipedRight: handleSelectPreviousWeek,
    });

    const calendarRef = useRef(null);
    useOnClickOutside(calendarRef, () => setIsCalendarOpen(false));

    const showNoSlotsWarning =
      !selectTimeStore.isLoadingAvailableSpots &&
      _.every(
        selectTimeStore.availableSlots,
        (slot) => slot.slots.length === 0
      );
    const daysContainer = useRef(null);

    return (
      <>
        <div className={styles.container}>
          <div
            className={classNames(styles.datesContainer, {
              [styles.error]: isMeetingHourErrorShown,
            })}
            {...weekChangeHandlers}
          >
            <div className={styles.datesSelection}>
              <div
                onClick={handleSelectPreviousWeek}
                className={classNames(styles.arrow, {
                  [styles.disabled]:
                    selectTimeStore.isLoadingAvailableSpots ||
                    !canSelectPreviousWeek(),
                })}
              >
                <ArrowLeft size={22} />
              </div>
              <div className={styles.dates}>
                <span
                  onClick={handleOpenCalendar}
                  className={classNames(styles.datesText, {
                    [styles.selected]: isCalendarOpen,
                  })}
                >
                  <CalendarBlank
                    size={22}
                    className={classNames(styles.calendarIcon, {
                      [styles.selected]: isCalendarOpen,
                    })}
                  />
                  {from?.format('DD.MM')}&thinsp;&ndash;&thinsp;
                  {to?.format('DD.MM')}&ensp;
                </span>
                <div ref={calendarRef} className={styles.calendar}>
                  {isCalendarOpen && (
                    <DatePicker date={from} onChangeDate={handleWeekChange} />
                  )}
                </div>
              </div>
              <div
                onClick={handleSelectNextWeek}
                className={classNames(styles.arrow, {
                  [styles.disabled]: selectTimeStore.isLoadingAvailableSpots,
                })}
              >
                <ArrowRight size={22} />
              </div>
            </div>

            <div className={styles.days} ref={daysContainer}>
              <ValidationPopup
                error={t('meeting.validation.date')}
                isOpen={isMeetingDateErrorShown}
                onClose={() => setIsMeetingDateErrorShown(false)}
                element={daysContainer.current!}
              />
              {days.map((x, index) => (
                <Day
                  date={x}
                  numberOfSlots={
                    getSlotsForDate(selectTimeStore.availableSlots, x).length
                  }
                  isSelected={areDatesEqual(x, selectTimeStore.selectedDate)}
                  onSelect={() => handleSelectDay(x)}
                  key={index}
                />
              ))}
              <ValidationPopup
                error={t('meeting.validation.hour')}
                isOpen={isMeetingHourErrorShown}
                onClose={() => setIsMeetingHourErrorShown(false)}
                element={daysContainer.current!}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              />
            </div>
          </div>
          <Loading
            type='SMALL'
            verticalPosition='center'
            isLoading={selectTimeStore.isLoadingAvailableSpots}
          >
            {selectTimeStore.selectedDate && (
              <Slots
                availableSlots={getSlotsForDate(
                  selectTimeStore.availableSlots,
                  selectTimeStore.selectedDate
                )}
                displaySelectedSlot={
                  selectTimeStore.selectedSlot
                    ? selectTimeStore.selectedDate
                        .clone()
                        .isSame(selectTimeStore.selectedSlot.start, 'day')
                    : false
                }
                selectedSlot={selectTimeStore.selectedSlot}
                onSelectSlot={(slot) => selectTimeStore.setSelectedSlot(slot)}
              />
            )}
            {showNoSlotsWarning && (
              <p className={styles.noSlotsWarning}>
                {t('meeting.time.noSlotInWeek')}
              </p>
            )}
          </Loading>
        </div>
      </>
    );
  }
);

export default SelectTime;
