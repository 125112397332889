import { FC, useEffect } from 'react';
import styles from './Dashboard.module.css';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'shared/src/appInsights';
import { observer } from 'mobx-react';
import { flowResult } from 'mobx';
import { DashboardStore } from './DashboardStore';
import { TFunction, useTranslation } from 'react-i18next';
import { Moment } from 'moment';
import {
  IEmployeeDto,
  ISlotDto,
  MeetingType,
  ProjectStatus,
  useService,
} from 'shared';
import { useQuery } from '../common/hooks/useQuery';
import { parseIntOptional } from '../common/utils/parseIntOptional';
import DashboardIntroMenu from './DashboardIntroMenu/DashboardIntroMenu';
import ProgressLine, {
  ProgressLineStepType,
} from './ProgressLine/ProgressLine';
import { flatten } from 'lodash';
import { useDisplayComponent } from '../common/hooks/useDisplayComponent';
import BookMeeting from '../meeting/components/BookMeeting';
import DesignerCard from '../meeting/components/DesignerCard/DesignerCard';
import { ProjectStore } from '../project/ProjectStore';
import Design from './Design/Design';
import { OrderSummaryStore } from '../project/order-summary/OrderSummaryStore';
import Order from './Order/Order';
import { DesignIterationsStore } from '../project/designs/DesignIterationsStore';

export interface DashboardRedirectState {
  restoreBookMeeting?: boolean;
  meetingType?: MeetingType;
  location?: string;
  designer?: IEmployeeDto;
  date?: Moment;
  slot?: ISlotDto;
}

const getSteps = (
  projectStore: ProjectStore,
  orderSummaryStore: OrderSummaryStore,
  designIterationStore: DesignIterationsStore,
  t: TFunction<'translation', undefined>,
  displayBooking: () => void
): ProgressLineStepType[] | null => {
  const { stages, project, projectMeeting } = projectStore;

  if (!stages || !project) {
    return null;
  }

  const bookAMeetingStage = stages.filter(
    (s) => s.name === 'bookAMeeting' || s.name === 'prepareForAMeeting'
  );
  const designStage = stages.filter((s) => s.name === 'designing');
  const orderStage = stages.filter(
    (s) => s.name === 'orderAndPayment' || s.name === 'closed'
  );

  const bookAMeetingStatuses: ProjectStatus[] = flatten(
    bookAMeetingStage.map((stage) => stage.projectStatuses)
  );

  return [
    {
      id: bookAMeetingStage.map((s) => s.id).join('_'),
      title: projectMeeting
        ? t('dashboard.stage.yourMeeting')
        : t('dashboard.stage.prepareMeeting'),
      component: projectMeeting ? (
        <div className={styles.meetingRoot}>
          {projectMeeting && (
            <DesignerCard
              designer={projectMeeting.designer}
              meetingType={projectMeeting.meetingType as MeetingType}
              location={projectMeeting.location}
              date={projectMeeting.startTime.clone().startOf('day')}
              slot={{
                start: projectMeeting.startTime.clone(),
                end: projectMeeting.endTime.clone(),
                designerEmails: [projectMeeting.designer.email],
              }}
              isNegative={true}
              onReschedule={displayBooking}
              size={'SMALL'}
            />
          )}
        </div>
      ) : null,
      state:
        bookAMeetingStatuses.includes(project.status) &&
        project.status === ProjectStatus.DesignerAssignmentPending
          ? 'ACTIVE'
          : 'OPEN',
      onTitleClick: projectMeeting ? undefined : displayBooking,
    },
    {
      id: designStage.map((s) => s.id).join(),
      title: t('dashboard.stage.design'),
      component: <Design projectId={project.id} />,
      state:
        !designStage[0].projectStatuses.includes(project.status) &&
        !projectMeeting
          ? 'DISABLED'
          : designIterationStore.iterations?.length
          ? 'OPEN'
          : 'ACTIVE',
    },
    {
      id: orderStage.map((s) => s.id).join('_'),
      title: t('dashboard.stage.order'),
      component: projectStore.project?.id ? (
        <Order
          orderStore={orderSummaryStore}
          projectId={projectStore.project.id}
        />
      ) : null,
      state:
        project.status === ProjectStatus.Payment
          ? orderSummaryStore.orderDto?.invoices?.length
            ? 'OPEN'
            : 'ACTIVE'
          : 'DISABLED',
    },
  ];
};

const Dashboard: FC = observer(() => {
  const dashboardStore = useService<DashboardStore>(DashboardStore);
  const projectStore = dashboardStore.projectStore;
  const orderSummaryStore = dashboardStore.orderStore;
  const designIterationStore = dashboardStore.designIterationStore;

  const { t } = useTranslation();
  const query = useQuery();
  const [isBookingDisplayed, displayBooking] = useDisplayComponent();

  useEffect(() => {
    const projectId = parseIntOptional(query.get('projectId'));
    flowResult(dashboardStore?.ensureDashboardLoaded(projectId));
  }, [dashboardStore, query]);

  const steps =
    projectStore.stages &&
    projectStore.project &&
    getSteps(
      projectStore,
      orderSummaryStore,
      designIterationStore,
      t,
      displayBooking
    );

  return (
    <section>
      <DashboardIntroMenu />
      {steps && <ProgressLine steps={steps} />}
      {isBookingDisplayed && (
        <BookMeeting isReschedule={projectStore.projectMeeting !== null} />
      )}
    </section>
  );
});

export default withAITracking(
  reactPlugin,
  Dashboard,
  undefined,
  styles.content
);
