import React, { FC } from 'react';
import styles from './ProjectSectionHeader.module.css';

type ProjectSectionHeaderProps = {
  name: string;
};

const ProjectSectionHeader: FC<ProjectSectionHeaderProps> = ({ name }) => {
  return (
    <div className={styles.summaryDetails}>
      <h2 className={styles.title}>{name}</h2>
    </div>
  );
};

export default ProjectSectionHeader;
