import { withAITracking } from '@microsoft/applicationinsights-react-js';
import moment from 'moment';
import { FC, useContext, useEffect, useState } from 'react';
import { reactPlugin } from 'shared/src/appInsights';
import FillContactInfo from './contact-info/FillContactInfo';
import styles from './BookMeeting.module.css';
import MeetingSummary from './summary/MeetingSummary';
import CardWithSteps, {
  StepComponent,
  StepsController,
} from '../../common/components/CardWithSteps/CardWithSteps';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { ProjectContext, ProjectStore } from '../../project/ProjectStore';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import ModalWrapper from '../../common/components/modals/ModalWrapper';
import { useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DashboardRedirectState } from '../../dashboard/Dashboard';
import { Logger, useService } from 'shared';
import Schedule from './schedule/Schedule';

export type ConfirmationState =
  | 'TO_CONFIRM'
  | 'CONFIRMING'
  | 'CONFIRMED'
  | 'FAILED';

interface BookMeetingProps {
  redirectState?: DashboardRedirectState;
  isReschedule?: boolean;
}

const BookMeeting: FC<BookMeetingProps> = observer(
  ({ redirectState, isReschedule = false }) => {
    const { meetingUId: uIdFromParams } =
      useParams<Record<string, string | undefined>>();
    const history = useHistory();
    const { isAuthenticated } = useAuth0();
    const { t } = useTranslation();
    const projectStore = useContext(ProjectContext);
    const { bookMeetingStore, projectMeeting } = projectStore as ProjectStore;
    const [confirmationState, setConfirmationState] =
      useState<ConfirmationState>('TO_CONFIRM');
    const [isModalOpen, setModalOpen] = useState(true);
    const [meetingUId, setMeetingUId] = useState('');
    const stepsController = useRef<StepsController>(null);
    const logger = useService<Logger>(Logger);
    const { chooseLocation, selectTime, designersStore } = bookMeetingStore;

    useEffect(() => {
      if (!redirectState) {
        return;
      }

      const load = async () => {
        const { date, slot, location, meetingType, designer } = redirectState;
        await chooseLocation.restoreRedirectState(meetingType, location);
        await selectTime.restoreRedirectState(date!, slot!, meetingType);
        await designersStore.restoreRedirectState(designer!);
      };

      load();
    }, [redirectState, chooseLocation, selectTime, designersStore]);

    useEffect(() => {
      const meetingUId = uIdFromParams || projectMeeting?.uId;
      const loadMeeting = async (uId: string) =>
        await flowResult(bookMeetingStore.loadMeeting(uId));
      if (meetingUId) {
        setMeetingUId(meetingUId);
        loadMeeting(meetingUId);
      }
    }, [bookMeetingStore, projectMeeting?.uId, uIdFromParams, isAuthenticated]);

    useEffect(() => {
      const loadProfile = async () => {
        await flowResult(bookMeetingStore.loadMyProfile());
      };

      if (isAuthenticated) {
        loadProfile();
      }
    }, [bookMeetingStore, isAuthenticated]);

    const handleConfirmMeeting = async () => {
      try {
        setConfirmationState('CONFIRMING');
        await flowResult(bookMeetingStore?.bookOrRescheduleMeeting());
        setConfirmationState('CONFIRMED');
      } catch (error) {
        logger.logException(error);
        setConfirmationState('FAILED');
      }
    };

    const getMeetingStartDate = () => {
      const startDate = bookMeetingStore.selectTime.selectedSlot?.start;
      return moment(startDate);
    };

    const closeBooking = () => {
      setModalOpen(false);
      history.push('/');
    };

    const steps: StepComponent[] = [
      {
        component: (
          <Schedule
            chooseLocationStore={bookMeetingStore.chooseLocation}
            location={bookMeetingStore.chooseLocation.selectedLocation}
            selectTimeStore={bookMeetingStore.selectTime}
            meetingType={bookMeetingStore.chooseLocation.selectedMeeting}
            designersStore={bookMeetingStore.designersStore}
            isDirty={!bookMeetingStore.isDirty}
          />
        ),
      } as StepComponent,
    ]
      .concat(
        (isAuthenticated || meetingUId) && !bookMeetingStore.isDataMissing
          ? []
          : [
              {
                title: t('meeting.contactInfo.title'),
                subheader: t('meeting.contactInfo.subTitle'),
                component: (
                  <FillContactInfo
                    initialContactInfo={bookMeetingStore.contactInfo}
                    onStepFulfilled={(contactInfo) =>
                      bookMeetingStore.setContactInfo(contactInfo)
                    }
                    meetingType={
                      bookMeetingStore.chooseLocation.selectedMeeting!
                    }
                    location={bookMeetingStore.chooseLocation.selectedLocation}
                    date={bookMeetingStore.selectTime.selectedDate!}
                    slot={bookMeetingStore.selectTime.selectedSlot!}
                    designer={designersStore.selectedDesigner}
                  />
                ),
              },
            ]
      )
      .concat([
        {
          component: (
            <MeetingSummary
              meetingType={bookMeetingStore.chooseLocation.selectedMeeting!}
              location={bookMeetingStore.chooseLocation.selectedLocation}
              date={getMeetingStartDate()}
              confirmationState={confirmationState}
              contactInfo={bookMeetingStore.contactInfo}
              restartBooking={() => {
                setConfirmationState('TO_CONFIRM');
                stepsController.current?.goToStep(0);
              }}
              finishBooking={closeBooking}
              slot={bookMeetingStore.selectTime.selectedSlot!}
              designer={designersStore.selectedDesigner}
              additionalComment={bookMeetingStore.additionalComment}
              onCommentChange={(comment) =>
                bookMeetingStore.setAdditionalComment(comment)
              }
            />
          ),
        } as StepComponent,
      ]);

    const confirmCancelProps =
      confirmationState === 'CONFIRMED'
        ? undefined
        : {
            title: t('meeting.dropBooking.title'),
            content: t('meeting.dropBooking.content'),
            confirmText: t('common.cancel'),
            backText: t('meeting.dropBooking.goBack'),
          };

    return (
      <ModalWrapper
        open={isModalOpen}
        onClose={closeBooking}
        confirmCancel={isReschedule ? undefined : confirmCancelProps}
        sizeVariant={'COVER'}
        closeVariant={'ARROW'}
        title={isReschedule ? t('meeting.rescheduleTitle') : t('meeting.title')}
      >
        <CardWithSteps
          ref={stepsController}
          steps={steps}
          stepUnit={t('project.checklistSteps.steps')}
          showLoadingScreen={
            confirmationState === 'CONFIRMING' ||
            bookMeetingStore.isLoadingMeeting ||
            bookMeetingStore.isLoadingProfile
          }
          loadingDescription={
            confirmationState === 'CONFIRMING'
              ? t('meeting.settingUp')
              : t('meeting.loading')
          }
          onFinishSteps={handleConfirmMeeting}
          onCancel={closeBooking}
        />
      </ModalWrapper>
    );
  }
);

export default withAITracking(
  reactPlugin,
  BookMeeting,
  undefined,
  styles.container
);
