import { FC } from 'react';
import { X } from 'phosphor-react';
import { ReactComponent as Logo } from '../../assets/icons/evi_logo.svg';
import MenuLinks from './MenuLinks';
import LogInLogOutButton from './LogInLogOutButton';
import styles from './UserDrawer.module.css';

type UserDrawerProps = {
  closeDrawer: Function;
};

const UserDrawer: FC<UserDrawerProps> = ({ closeDrawer }) => {
  return (
    <div className={styles.userDrawer}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.close} onClick={() => closeDrawer()}>
          <X weight='thin' />
        </div>
      </div>
      <div className={styles.content}>
        <MenuLinks />
      </div>
      <div className={styles.footer}>
        <LogInLogOutButton />
      </div>
    </div>
  );
};

export default UserDrawer;
