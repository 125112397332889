import { makeAutoObservable } from 'mobx';
import {
  ICancelOrderingProcessBodyDto,
  IDeliveryDetailsDto,
  IOrderCancelReasonDto,
  IOrderSummaryDto,
  IPaymentSplitOptionDto,
  LegalConfigurationService,
  Logger,
  OrderCancelReasonService,
  OrderService,
  OrderType,
  PaymentSplitService,
} from 'shared';
import { singleton } from 'tsyringe';
import { OrderDataForm } from './CheckoutContent';
import { StorageService } from './services/StorageService';

@singleton()
export class OrderStore {
  public static createDeliveryDetailsDefaultValue(): IDeliveryDetailsDto {
    return {
      orderType: OrderType.Personal,
      customerDetails: {
        buyerDetails: {
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          companyName: '',
        },
        deliveryAddress: {
          streetAddress: '',
          country: '',
          city: '',
          postalCode: '',
        },
        billingAddressEqualsDeliveryAddress: true,
        billingAddress: {
          streetAddress: '',
          country: '',
          city: '',
          postalCode: '',
        },
        taxNumber: '',
      },
    };
  }

  deliveryDetails: IDeliveryDetailsDto;
  orderSummary?: IOrderSummaryDto;
  paymentSplitOptions: IPaymentSplitOptionDto[] = [];
  paymentSplitId?: number;
  cancelReasons: IOrderCancelReasonDto[] | null = null;
  termsAndConditonsUrl?: string;

  constructor(
    private orderService: OrderService,
    private orderCancelReasonService: OrderCancelReasonService,
    private paymentSplitService: PaymentSplitService,
    private storageService: StorageService,
    private legalConfigurationService: LegalConfigurationService,
    private logger: Logger
  ) {
    makeAutoObservable(this);
    this.deliveryDetails = OrderStore.createDeliveryDetailsDefaultValue();
  }

  orderSummaryState: 'loading' | 'loaded' | 'error' | 'not-initialized' =
    'not-initialized';
  orderSubmittingState?: 'submitting' | 'submitted' | 'error';
  paymentSplitOptionsState: 'loading' | 'loaded' | 'error' | 'not-initialized' =
    'not-initialized';
  termsAndConditonsLoadedState:
    | 'loading'
    | 'loaded'
    | 'error'
    | 'not-initialized' = 'not-initialized';

  cancelReasonsState: 'loading' | 'loaded' | 'error' | 'not-initialized' =
    'not-initialized';
  orderCancelingState?: 'canceling' | 'canceled' | 'error';

  get isLoadingOrderSummary() {
    return (
      this.orderSummaryState === 'loading' ||
      this.orderSummaryState === 'not-initialized'
    );
  }

  get isLoadingPaymentSplitOptions() {
    return (
      this.paymentSplitOptionsState === 'loading' ||
      this.paymentSplitOptionsState === 'not-initialized'
    );
  }

  get isLoadingOrderCancelReasons() {
    return (
      this.cancelReasonsState === 'loading' ||
      this.cancelReasonsState === 'not-initialized'
    );
  }

  get isOrderSubmitting() {
    return this.orderSubmittingState === 'submitting';
  }

  get isOrderCanceling() {
    return this.orderCancelingState === 'canceling';
  }

  *setDeliveryDetails(deliveryDetails: IDeliveryDetailsDto, orderId: string) {
    yield this.storageService.saveData(
      this.generateStorageKey(orderId),
      deliveryDetails
    );
    this.deliveryDetails = deliveryDetails;
  }

  *setOrderData(
    deliveryDetails: IDeliveryDetailsDto,
    paymentSplitId: number,
    orderId: string
  ) {
    yield this.storageService.saveData(this.generateStorageKey(orderId), {
      deliveryDetails,
      paymentSplitId,
    });

    this.deliveryDetails = deliveryDetails;
    this.paymentSplitId = paymentSplitId;
  }

  public *ensureOrderSummaryLoaded(iterationUId: string) {
    if (this.orderSummaryState === 'not-initialized') {
      yield this.loadOrderSummary(iterationUId);
    }
  }

  public *ensurePaymentSplitOptionsLoaded() {
    if (this.paymentSplitOptionsState === 'not-initialized') {
      yield this.loadPaymentSplitOptions();
    }
  }

  public *ensureOrderCancelReasonsLoaded() {
    if (this.cancelReasonsState === 'not-initialized') {
      yield this.loadOrderCancelReasons();
    }
  }

  public *ensureTermsAndConditionsLoaded() {
    if (this.termsAndConditonsLoadedState === 'not-initialized') {
      yield this.loadtermsAndConditons();
    }
  }

  public *loadLocalData(orderId: string) {
    const data: OrderDataForm | undefined = yield this.storageService.getData<
      OrderDataForm | undefined
    >(this.generateStorageKey(orderId));
    if (data) {
      this.deliveryDetails = data.delivery;
      this.paymentSplitId = data.paymentSplitId;
    }
  }

  public *submit(iterationUId: string) {
    try {
      this.orderSubmittingState = 'submitting';
      yield this.orderService.submit({
        iterationUId,
        deliveryDetails: this.deliveryDetails,
        paymentSplitId: this.paymentSplitId ?? 0,
      });
      this.orderSubmittingState = 'submitted';
    } catch {
      this.orderSubmittingState = 'error';
    }
  }

  public restartSubmit() {
    this.orderSubmittingState = undefined;
  }

  public *cancelOrderingProcess(
    cancelOrderData: ICancelOrderingProcessBodyDto
  ) {
    try {
      this.orderCancelingState = 'canceling';
      yield this.orderService.cancel(cancelOrderData);
      this.orderCancelingState = 'canceled';
    } catch (error) {
      this.logger.logException(error);
      this.orderCancelingState = 'error';
    }
  }

  private generateStorageKey = (orderId: string) =>
    `order-checkout/${orderId}/checkout-store`;

  private *loadOrderSummary(iterationUId: string) {
    this.orderSummaryState = 'loading';
    try {
      this.orderSummary = yield this.orderService.getSummary(iterationUId);
      this.orderSummaryState = 'loaded';
    } catch (error) {
      this.logger.logException(error);
      this.orderSummaryState = 'error';
    }
  }

  private *loadPaymentSplitOptions() {
    this.paymentSplitOptionsState = 'loading';
    try {
      this.paymentSplitOptions = yield this.paymentSplitService.getActive();
      this.paymentSplitOptionsState = 'loaded';
    } catch (error) {
      this.logger.logException(error);
      this.paymentSplitOptionsState = 'error';
    }
  }

  private *loadOrderCancelReasons() {
    this.cancelReasonsState = 'loading';
    try {
      this.cancelReasons = yield this.orderCancelReasonService.getActive();
      this.cancelReasonsState = 'loaded';
    } catch (error) {
      this.logger.logException(error);
      this.cancelReasonsState = 'error';
    }
  }

  private *loadtermsAndConditons() {
    this.termsAndConditonsLoadedState = 'loading';
    try {
      this.termsAndConditonsUrl =
        yield this.legalConfigurationService.getOrderTermsAndConditonsUrl();
      this.termsAndConditonsLoadedState = 'loaded';
    } catch (error) {
      this.logger.logException(error);
      this.termsAndConditonsLoadedState = 'error';
    }
  }
}
