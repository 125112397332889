import styles from './ValidationPopup.module.css';
import { FC, ReactNode } from 'react';
import { Popover, PopoverOrigin } from '@mui/material';
import classNames from 'classnames';

interface ValidationPopupProps {
  error: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  arrowPosition?: 'BOTTOM' | 'TOP';
  element: Element;
}

const ValidationPopup: FC<ValidationPopupProps> = ({
  error,
  isOpen,
  onClose,
  element,
  anchorOrigin = { vertical: 'top', horizontal: 50 },
  transformOrigin = { vertical: 'bottom', horizontal: 50 },
  arrowPosition = 'BOTTOM',
}) => {
  return (
    <Popover
      open={isOpen}
      onClose={onClose}
      anchorEl={element}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={{
        classes: { root: styles.root },
      }}
      classes={{
        paper: classNames(styles.paper, {
          [styles.top]: arrowPosition === 'TOP',
          [styles.bottom]: arrowPosition === 'BOTTOM',
        }),
      }}
    >
      <div className={styles.container} role='alert'>
        {error}
      </div>
    </Popover>
  );
};

export default ValidationPopup;
