import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import ButtonComponent from '../../common/components/buttons/ButtonComponent';
import styles from './DesignProposalActions.module.css';
import { Trans, useTranslation } from 'react-i18next';
import { Info } from 'phosphor-react';
import { IterationStore } from './IterationStore';

type DesignProposalActionsProps = {
  designProposal: IterationStore;
  projectId: number;
};

const DesignProposalActions: FC<DesignProposalActionsProps> = ({
  designProposal,
  projectId,
}) => {
  const offerDisabled = designProposal.isOfferExpired;
  const { t } = useTranslation();

  return (
    <>
      {designProposal.isInOrderableState && (
        <div className={styles.actions}>
          <div className={styles.validTill}>
            {designProposal.isOfferExpired ? (
              <>
                <Info size={24} className={styles.alertIcon} />
                <span className={styles.expired}>
                  <Trans t={t} i18nKey='project.design.actions.expired'>
                    Offer expired
                    <span className={styles.date}>
                      {{ offerValidUntil: designProposal.offerValidUntil }}
                    </span>
                  </Trans>
                </span>
              </>
            ) : (
              <span className={styles.offerValid}>
                <Trans t={t} i18nKey='project.design.actions.offerValid'>
                  Offer valid until
                  <span className={styles.date}>
                    {{ offerValidUntil: designProposal.offerValidUntil }}
                  </span>
                </Trans>
              </span>
            )}
          </div>
          <div className={styles.buttons}>
            <NavLink
              onClick={(e) => offerDisabled && e.preventDefault()}
              to={{
                pathname: `/order-checkout/${designProposal.uId}/order`,
                state: { projectId },
              }}
              className={styles.orderButton}
            >
              <ButtonComponent
                variant='FILLED_IN'
                size='BIG'
                disabled={offerDisabled}
              >
                {t('project.design.actions.order', 'Order')}
              </ButtonComponent>
            </NavLink>
          </div>
        </div>
      )}
    </>
  );
};

export default DesignProposalActions;
