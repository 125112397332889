import classNames from 'classnames';
import { Archive, Check } from 'phosphor-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DesignStatus } from 'shared';
import styles from './ProposalStatus.module.css';

interface ProposalStatusProps {
  status: DesignStatus;
}

const ProposalStatus: FC<ProposalStatusProps> = ({ status }) => {
  const { t } = useTranslation();
  switch (status) {
    case DesignStatus.Archival:
      return (
        <div className={classNames(styles.chip, styles.requiredChanges)}>
          <Archive />
          {t('project.design.statuses.archival', 'Archival')}
        </div>
      );
    case DesignStatus.Ordered:
      return (
        <div className={classNames(styles.chip, styles.accepted)}>
          <Check />
          {t('project.design.statuses.ordered', 'Ordered')}
        </div>
      );
    default:
      return null;
  }
};

export default ProposalStatus;
