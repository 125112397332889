import { flowResult, makeAutoObservable, reaction } from 'mobx';
import moment, { Moment } from 'moment';
import {
  areDatesEqual,
  CustomerService,
  DateSpan,
  EmployeesService,
  getWeekSpan,
  IAvailableSlotsDto,
  IB2CCustomerProfileDto,
  IContactInfoDto,
  IEmployeeDto,
  ILegalDocumentsDto,
  ILocationDto,
  IMeetingDto,
  ISlotDto,
  Logger,
  MeetingService,
  MeetingType,
} from 'shared';
import i18n from '../i18n';
import { ProjectStore } from '../project/ProjectStore';

export interface Meeting {
  meetingType: MeetingType;
  name: string;
}

export class ChooseLocationStore {
  constructor(private meetingService: MeetingService) {
    makeAutoObservable(this);
    this.isDirty = false;
  }

  selectedMeeting?: MeetingType;
  meetings: Meeting[] = [
    {
      meetingType: MeetingType.Showroom,
      name: i18n.t('meeting.location.showroom'),
    },
    {
      meetingType: MeetingType.Online,
      name: i18n.t('meeting.location.onlineMeeting'),
    },
  ];
  locations: ILocationDto[] = [];
  selectedLocation = '';
  isDirty: boolean;

  *fetchLocation() {
    this.locations = yield this.meetingService.getLocations();
  }

  restoreRedirectState(
    meetingType: MeetingType | undefined,
    location: string = ''
  ) {
    this.setSelectedMeeting(meetingType);
    this.setSelectedLocation(location);
    return Promise.resolve();
  }

  setSelectedMeeting(meetingType: MeetingType | undefined) {
    this.isDirty = true;
    this.selectedMeeting = meetingType;

    if (
      this.selectedMeeting === MeetingType.Showroom &&
      this.locations.length === 1
    ) {
      this.selectedLocation = this.locations[0].address;
      return;
    }

    this.selectedLocation = '';
  }

  setSelectedLocation(location: string) {
    this.isDirty = true;
    this.selectedLocation = location;
  }

  clear() {
    this.selectedLocation = '';
    this.selectedMeeting = undefined;
    this.isDirty = false;
  }

  afterLoadData() {
    this.isDirty = false;
  }
}

export class DesignersStore {
  constructor(private employeeService: EmployeesService) {
    makeAutoObservable(this);
    this.fetchDesigners();
    this.isDirty = false;
  }

  selectedDesignerId?: string | null;
  designers: IEmployeeDto[] = [];
  isDirty: boolean;

  get selectedDesigner(): IEmployeeDto | undefined | null {
    if (this.selectedDesignerId === null) {
      return this.selectedDesignerId;
    }

    return this.designers.find(
      (designer) => designer.externalId === this.selectedDesignerId
    );
  }

  restoreRedirectState(designer: IEmployeeDto) {
    this.selectedDesignerId = designer.externalId;
    return Promise.resolve();
  }

  private *fetchDesigners() {
    this.designers = yield this.employeeService.getDesigners();
  }

  setSelectedDesigner(d: IEmployeeDto | undefined | null) {
    this.isDirty = true;
    if (d === null) {
      this.selectedDesignerId = d;
      return;
    }
    this.selectedDesignerId = d?.externalId;
  }

  clear() {
    this.selectedDesignerId = undefined;
    this.isDirty = false;
  }
  afterLoadData() {
    this.isDirty = false;
  }
}

export class SelectTimeStore {
  constructor(private meetingService: MeetingService) {
    makeAutoObservable(this);
    this.selectedDateSpan = {
      from: moment.utc().startOf('day'),
      to: moment.utc().endOf('week').startOf('day'),
    };
    reaction(
      () => this.selectedDateSpan,
      (span) => flowResult(this.fetchSlots())
    );
    this.isDirty = false;
  }

  selectedDate: Moment | null = null;
  selectedSlot: ISlotDto | null = null;
  availableSlots: IAvailableSlotsDto[] | null = null;
  selectedDateSpan: DateSpan;
  isLoadingAvailableSpots = false;
  location = '';
  meetingType?: MeetingType;
  designer?: IEmployeeDto | null;

  preselectedSlot: ISlotDto | null = null;
  isDirty: boolean;

  private setPreselectedSlot(preselectedSlot: ISlotDto | null) {
    this.preselectedSlot = preselectedSlot;
  }

  private restorePreselectedSlot() {
    if (!this.preselectedSlot) {
      return;
    }

    this.findAndSetSlot(this.preselectedSlot);
    this.setPreselectedSlot(null);
  }

  *ensureFetched(
    location: string,
    meetingType?: MeetingType,
    designer?: IEmployeeDto | null
  ) {
    this.meetingType = meetingType;
    if (
      meetingType &&
      ((designer !== undefined && this.designer !== designer) ||
        !this.availableSlots ||
        this.location !== location)
    ) {
      this.designer = designer;
      this.location = location;
      this.preselectedSlot = null;
      yield this.fetchSlots();
    }
  }

  restoreRedirectState(
    date: Moment | null,
    slot: ISlotDto | null,
    meetingType?: MeetingType
  ) {
    this.setPreselectedSlot(slot);
    const weekSpan = getWeekSpan(moment(date).utc());
    this.setSelectedDateSpan(weekSpan);
    this.setSelectedDate(date);
    this.meetingType = meetingType;
    return Promise.resolve();
  }

  setSelectedDate(date: Moment | null) {
    this.selectedDate = date;
    this.isDirty = true;
  }

  private findAndSetSlot(slot: ISlotDto) {
    const slotToSelect =
      this.availableSlots
        ?.find((x) => areDatesEqual(x.date, this.selectedDate))
        ?.slots?.find((x) => areDatesEqual(x.start, slot.start)) ?? null;

    this.setSelectedSlot(slotToSelect);
  }

  setSelectedSlot(slot: ISlotDto | null) {
    this.selectedSlot = slot;
    this.isDirty = true;
  }

  setSelectedDateSpan(dateSpan: DateSpan) {
    this.selectedDateSpan = dateSpan;
  }

  private *fetchSlots() {
    this.isLoadingAvailableSpots = true;
    try {
      this.availableSlots = yield this.meetingService?.getSlots(
        this.selectedDateSpan.from,
        this.selectedDateSpan.to,
        this.meetingType === MeetingType.Showroom ? this.location : '',
        this.designer !== null ? this.designer?.email ?? '' : this.designer
      );

      this.restorePreselectedSlot();
    } finally {
      this.isLoadingAvailableSpots = false;
    }
  }

  clear() {
    this.selectedSlot = null;
    this.selectedDate = null;
    this.selectedDateSpan = {
      from: moment.utc().startOf('day'),
      to: moment.utc().endOf('week').startOf('day'),
    };
    this.isDirty = false;
  }
  afterLoadData() {
    this.isDirty = false;
  }
}

export class BookMeetingStore {
  constructor(
    private projectStore: ProjectStore,
    private meetingService: MeetingService,
    private logger: Logger,
    private customerService: CustomerService,
    private employeeService: EmployeesService
  ) {
    makeAutoObservable(this);
    this.chooseLocation = new ChooseLocationStore(meetingService);
    this.selectTime = new SelectTimeStore(meetingService);
    this.designersStore = new DesignersStore(employeeService);
    this.contactInfo = {
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '+47',
      termsAndConditions: false,
    };
    this.additionalComment = '';
  }

  chooseLocation: ChooseLocationStore;
  selectTime: SelectTimeStore;
  designersStore: DesignersStore;
  contactInfo: IContactInfoDto;
  meetingUId: string | null = null;
  isLoadingMeeting: boolean = false;
  isCanceling: boolean = false;
  additionalComment: string;
  profile?: IB2CCustomerProfileDto;
  isDataMissing: boolean = true;
  isLoadingProfile: boolean = false;
  legalDocuments?: ILegalDocumentsDto;
  isMeetingLoaded:
    | 'loading'
    | 'loaded'
    | 'error'
    | 'renaming'
    | 'not-initialized' = 'not-initialized';

  setContactInfo(contactInfo: IContactInfoDto) {
    this.contactInfo = contactInfo;
    this.isDataMissing = !(
      this.contactInfo?.firstName &&
      this.contactInfo?.lastName &&
      this.contactInfo?.phoneNumber
    );
  }

  setAdditionalComment(comment: string) {
    this.additionalComment = comment;
  }

  clearBookAMeeting() {
    this.chooseLocation.clear();
    this.designersStore.clear();
    this.selectTime.clear();
  }

  get isDirty() {
    return (
      this.chooseLocation.isDirty ||
      this.designersStore.isDirty ||
      this.selectTime.isDirty
    );
  }

  private afterLoadData() {
    this.chooseLocation.afterLoadData();
    this.designersStore.afterLoadData();
    this.selectTime.afterLoadData();
  }

  *bookOrRescheduleMeeting() {
    const meetingInfo = {
      meetingType: this.chooseLocation.selectedMeeting!,
      start: this.selectTime.selectedSlot?.start!,
      end: this.selectTime.selectedSlot?.end!,
      location: this.chooseLocation.selectedLocation,
      additionalComment: this.additionalComment,
      designerEmail:
        this.designersStore.selectedDesigner?.email ??
        (null as unknown as string),
    };

    if (this.meetingUId) {
      yield this.projectStore.rescheduleMeeting({
        ...meetingInfo,
        meetingUId: this.meetingUId,
      });
    } else {
      yield this.projectStore.bookMeeting({
        ...meetingInfo,
        contactInfo: this.contactInfo,
        projectId: this.projectStore.project?.id,
      });
    }
  }

  *cancel(meetingUId: string) {
    this.isCanceling = true;
    try {
      yield this.projectStore.cancelMeeting(meetingUId);
    } catch (ex) {
      this.logger.logException(ex);
    } finally {
      this.isCanceling = false;
    }
  }

  *loadMeeting(meetingUId: string): any {
    this.isMeetingLoaded = 'loading';
    try {
      const meeting: IMeetingDto = yield this.getMeeting(meetingUId);
      this.meetingUId = meetingUId;
      this.chooseLocation.selectedMeeting = meeting.meetingType as MeetingType;

      if (meeting.meetingType !== MeetingType.Online) {
        this.chooseLocation.selectedLocation = meeting.location;
      }

      this.designersStore.setSelectedDesigner(meeting.designer);
      this.setContactInfo(meeting.contactInfo);
      this.additionalComment = meeting.comment;
      this.selectTime.setSelectedDateSpan(getWeekSpan(meeting.startTime));
      this.selectTime.setSelectedDate(meeting.startTime.clone().startOf('day'));
      this.selectTime.setSelectedSlot({
        start: meeting.startTime.clone(),
        end: meeting.endTime.clone(),
        designerEmails: [meeting.designer.email],
      });
      this.afterLoadData();
    } catch (e) {
      this.logger.logException(e);
      this.isMeetingLoaded = 'error';
    } finally {
      this.isMeetingLoaded = 'loaded';
    }
  }

  *loadMyProfile() {
    this.isLoadingProfile = true;
    try {
      this.profile = yield this.customerService.getMyProfile();
      this.setContactInfo({
        phoneNumber: this.profile?.phoneNumber ?? '+47',
        firstName: this.profile?.firstName ?? '',
        lastName: this.profile?.lastName ?? '',
        email: this.profile?.email ?? '',
        termsAndConditions: false,
      });
    } catch (error) {
      this.logger.logException(error);
    } finally {
      this.isLoadingProfile = false;
    }
  }

  private *getMeeting(meetingUId: string) {
    if (this.projectStore.projectMeeting?.uId === meetingUId) {
      return this.projectStore.projectMeeting;
    }
    this.isLoadingMeeting = true;
    try {
      const meeting: IMeetingDto = yield this.meetingService.getByUId(
        meetingUId
      );
      return meeting;
    } finally {
      this.isLoadingMeeting = false;
    }
  }
}
