import React, { FC } from 'react';
import { FormControl, FormHelperText, Input, InputLabel } from '@mui/material';
import classNames from 'classnames';
import styles from './InputRounded.module.css';

interface InputRoundedProps {
  name: string;
  label: string;
  value?: string | number;
  error?: string;
  required?: boolean;
  hideLabel: boolean;
}

const InputRounded: FC<InputRoundedProps> = ({
  name,
  label,
  error,
  required,
  hideLabel,
  ...rest
}) => {
  return (
    <FormControl
      classes={{ root: styles.container }}
      error={!!error}
      required={required}
    >
      <div className={classNames({ visuallyHidden: hideLabel })}>
        <InputLabel
          htmlFor={name}
          classes={{
            root: styles.label,
            error: classNames({ [styles.labelError]: error }),
          }}
        >
          {label}
        </InputLabel>
      </div>
      <Input
        id={name}
        name={name}
        inputProps={{ ...rest }}
        aria-describedby={'describe-' + name + '-error'}
        classes={{
          input: styles.input,
          underline: styles.inputUnderline,
          error: styles.inputError,
        }}
        className={styles.inputRounded}
      />
      {error && error.length && (
        <FormHelperText
          id={'describe-' + name + '-error'}
          classes={{
            root: styles.helper,
          }}
        >
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default InputRounded;
