import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DesignIterations from './DesignIterations';
import { DesignIterationsStore } from './DesignIterationsStore';
import DesignsNotReady from './DesignsNotReady';
import DesignsError from './DesignsError';
import { useService } from 'shared';

const Designs: FC = observer(() => {
  const designIterations = useService<DesignIterationsStore>(
    DesignIterationsStore
  );
  const { projectId } = useParams<Record<string, string>>();

  useEffect(() => {
    flowResult(designIterations.loadDesignIteration(parseInt(projectId)));
  }, [designIterations, projectId]);

  if (designIterations.designIterationsState === 'error') {
    return (
      <DesignsError
        onRetry={() =>
          designIterations.loadDesignIteration(parseInt(projectId))
        }
      />
    );
  }

  if (
    designIterations.iterations === null ||
    designIterations.iterations === [] ||
    !designIterations.project
  )
    return <DesignsNotReady projectId={parseInt(projectId)} />;
  return (
    <DesignIterations
      designs={designIterations.iterations}
      project={designIterations.project}
    />
  );
});

export default Designs;
