import React, { FC } from 'react';
import { FieldHookConfig, useField, useFormikContext } from 'formik';
import CheckboxComponent, {
  CheckboxProps,
} from '../checkboxes/CheckboxComponent';

type FormikCheckboxProps = FieldHookConfig<string> & CheckboxProps;

const FormikCheckbox: FC<FormikCheckboxProps> = (props) => {
  const [field, meta] = useField(props);
  const { submitCount } = useFormikContext();

  return (
    <CheckboxComponent
      error={meta.touched && submitCount > 0 ? meta.error : undefined}
      {...field}
      {...props}
    />
  );
};

export default FormikCheckbox;
