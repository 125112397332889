import classNames from 'classnames';
import { FC } from 'react';
import styles from './HeaderTitles.module.css';

interface HeaderTitlesProps {
  title: string;
  classes?: {
    header?: string;
  };
  titleStyle?: React.CSSProperties;
  shortTitle: string;
}

const HeaderTitles: FC<HeaderTitlesProps> = ({
  title,
  shortTitle,
  classes,
  titleStyle,
}) => {
  return (
    <div className={classNames(styles.header, classes?.header)}>
      <h1 className={styles.title} style={titleStyle}>
        {title}
      </h1>
      <h1 className={styles.shortTitle}>{shortTitle}</h1>
    </div>
  );
};

export default HeaderTitles;
