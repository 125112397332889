import { FC, ReactNode } from 'react';
import ProgressLineStep, {
  ProgressLineState,
} from './ProgressLineStep/ProgressLineStep';
import styles from './ProgressLine.module.css';

export type ProgressLineStepType = {
  id: string;
  title: string;
  component: ReactNode;
  state: ProgressLineState;
  onTitleClick?: () => void;
};

interface ProgressLineProps {
  steps: ProgressLineStepType[];
}

const ProgressLine: FC<ProgressLineProps> = ({ steps }) => {
  return (
    <div className={styles.root}>
      {steps.map((step, index) => (
        <ProgressLineStep
          key={`progress_line_step_${index}`}
          step={step}
          isLast={index === steps.length - 1}
        />
      ))}
    </div>
  );
};

export default ProgressLine;
