import { FC, useCallback, useEffect } from 'react';
import styles from './Privacy.module.css';
import { useTranslation } from 'react-i18next';
import { UserSettingsStore } from './UserSettingsStore';
import { flowResult } from 'mobx';
import { Skeleton } from '@mui/material';
import { observer } from 'mobx-react';
import { AccountTypeDto, useService } from 'shared';
import SocialProviderPasswordInfo from './SocialProviderPasswordInfo';
import PrivacySection from './PrivacySection';
import ResetPassword from './ResetPassword';
import DownloadLink from './DownloadLink';
import ProfileLoadFailed from './ProfileLoadFailed';

const Privacy: FC = observer(() => {
  const { t } = useTranslation();
  const userSettingsStore = useService<UserSettingsStore>(UserSettingsStore);

  const handleChangePasswordRequest = async () => {
    await flowResult(userSettingsStore.changePassword());
  };

  const loadPrivacy = useCallback(() => {
    const fetchPrivacy = async () => {
      await flowResult(userSettingsStore.ensurePrivacyLoaded());
    };

    fetchPrivacy();
  }, [userSettingsStore]);

  useEffect(() => loadPrivacy(), [loadPrivacy]);

  if (userSettingsStore.loadingSettingsState === 'error') {
    return <ProfileLoadFailed onRetry={() => loadPrivacy()} />;
  }

  if (userSettingsStore.isLoadingPrivacy)
    return <Skeleton variant='text' width='100%' height='500px' />;

  return (
    <>
      <PrivacySection
        title={t('userSettings.privacy.privacy.title')}
        text={t('userSettings.privacy.privacy.text')}
      >
        <DownloadLink
          text={t(
            'userSettings.privacy.privacy.downloadPrivacyPolicy',
            'Download privacy policy'
          )}
          link={
            userSettingsStore.legalDocumentStore.legalDocuments
              ?.privacyPolicyUrl
          }
          className={styles.downloadLink}
        />
        <DownloadLink
          text={t(
            'userSettings.privacy.privacy.downloadTermsAndConditions',
            'Download terms & conditions'
          )}
          link={
            userSettingsStore.legalDocumentStore.legalDocuments
              ?.termsAndConditionsUrl
          }
          className={styles.downloadLink}
        />
      </PrivacySection>
      <PrivacySection
        title={t('userSettings.privacy.password.title')}
        text={t('userSettings.privacy.password.text')}
      >
        {userSettingsStore.privacy?.accountType ===
        AccountTypeDto.UsernamePassword ? (
          <div className={styles.resetContainer}>
            <ResetPassword
              onReset={handleChangePasswordRequest}
              resetState={userSettingsStore.passwordResetEmailState}
            />
          </div>
        ) : (
          <div className={styles.socialContainer}>
            <SocialProviderPasswordInfo
              accountType={userSettingsStore.privacy?.accountType}
            />
          </div>
        )}
      </PrivacySection>
    </>
  );
});

export default Privacy;
