import { Skeleton } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import { CheckCircle, X } from 'phosphor-react';
import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { useState } from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IB2CCustomerProfileDto, useService } from 'shared';
import ButtonComponent from '../common/components/buttons/ButtonComponent';
import FilePickerButton from '../common/components/file-pickers/FilePickerButton';
import InputField from '../common/components/inputs/InputField';
import ModalWrapper from '../common/components/modals/ModalWrapper';
import CropProfilePicture from './CropProfilePicture';
import styles from './Profile.module.css';
import { UserSettingsStore } from './UserSettingsStore';
import _ from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';
import ProfileLoadFailed from './ProfileLoadFailed';
import { validateName as validateNameWithResult } from '../common/utils/nameValidation';
import * as Yup from 'yup';
import { formatPhoneNumber } from '../common/utils/phoneNumberFormatter';
import { parsePhoneNumber } from 'libphonenumber-js';
import UserSectionTitle from './UserSectionTitle';

const Profile: FC = observer(() => {
  const userSettingsStore = useService<UserSettingsStore>(UserSettingsStore);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showSavedInfo, setShowSavedInfo] = useState<boolean>(false);
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const loadProfile = useCallback(() => {
    const fetchProfile = async () => {
      await flowResult(userSettingsStore.ensureProfileLoaded());
    };

    fetchProfile();
  }, [userSettingsStore]);

  useEffect(() => loadProfile(), [loadProfile]);

  if (userSettingsStore.loadingProfileState === 'error') {
    return <ProfileLoadFailed onRetry={() => loadProfile()} />;
  }

  const allowedTypes = 'image/png,image/gif,image/jpeg';
  const maxFileSizeInBytes = 5000000;

  const handleProfilePictureSelected = (e: ChangeEvent<HTMLInputElement>) => {
    const userFile = e.target.files?.[0];
    if (
      userFile &&
      allowedTypes.split(',').some((x) => userFile.type === x) &&
      userFile.size <= maxFileSizeInBytes
    ) {
      setSelectedFile(userFile);
    }
  };

  const handleUploadPicture = async (profilePicture: File): Promise<string> => {
    const picture = await userSettingsStore.uploadProfilePicture(
      profilePicture
    );
    setSelectedFile(null);
    return picture;
  };

  const handleCancelUploadPicture = () => {
    setSelectedFile(null);
  };

  function validatePhoneNumber(telephone: string | undefined) {
    try {
      return parsePhoneNumber(telephone ?? '', 'NO')?.isValid() ?? false;
    } catch {
      return false;
    }
  }

  const validateName = (name: string | undefined) => {
    var validationResult = validateNameWithResult(name);
    return !validationResult.containsInvalidCharacters;
  };

  const handleSaveChanges = async (profile: IB2CCustomerProfileDto) => {
    await flowResult(userSettingsStore.saveProfile(profile));
    setShowSavedInfo(true);
    getAccessTokenSilently({ ignoreCache: true });
  };

  if (userSettingsStore.isLoadingProfile)
    return <Skeleton variant='text' width='100%' height='500px' />;
  return (
    <div>
      <UserSectionTitle title={t('userSettings.profile.title')} />
      <Formik<IB2CCustomerProfileDto>
        validateOnChange
        initialValues={userSettingsStore.profile}
        onSubmit={(values, formik) => {
          handleSaveChanges(values);
          formik.resetForm({ values });
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .required(
              t(
                'userSettings.profile.details.errors.required',
                'This field is required'
              )
            )
            .test(
              'firstNameTest',
              t('userSettings.profile.details.errors.name', 'Invalid name'),
              validateName
            )
            .nullable(),
          lastName: Yup.string()
            .required(
              t(
                'userSettings.profile.details.errors.required',
                'This field is required'
              )
            )
            .test(
              'lastNameTest',
              t('userSettings.profile.details.errors.name', 'Invalid name'),
              validateName
            )
            .nullable(),
          phoneNumber: Yup.string()
            .required(
              t(
                'userSettings.profile.details.errors.required',
                'This field is required'
              )
            )
            .test(
              'phoneNumberTest',
              t(
                'userSettings.profile.details.errors.phoneNumber',
                'Invalid phone number'
              ),
              validatePhoneNumber
            )
            .nullable(),
          email: Yup.string()
            .required(
              t(
                'userSettings.profile.details.errors.required',
                'This field is required'
              )
            )
            .email(
              t('userSettings.profile.details.errors.email', 'Invalid email')
            ),
        })}
      >
        {(formState) => {
          const isFormDirty = formState.dirty || !_.isEmpty(formState.touched);
          return (
            <Form className={styles.form}>
              <fieldset
                className={classNames(styles.profileSection, styles.fieldset)}
              >
                <ModalWrapper
                  open={selectedFile !== null}
                  onClose={() => setSelectedFile(null)}
                  sizeVariant='SMALL'
                >
                  {selectedFile && (
                    <CropProfilePicture
                      picture={selectedFile}
                      onCancel={handleCancelUploadPicture}
                      onSubmit={async (picture) => {
                        const uploadedPicture = await handleUploadPicture(
                          picture
                        );
                        formState.setFieldValue('picture', uploadedPicture);
                      }}
                    />
                  )}
                </ModalWrapper>
                <legend className={styles.title}>
                  {t('userSettings.profile.picture.title')}
                </legend>
                <div className={classNames(styles.pictureSelection)}>
                  {formState.values.picture ? (
                    <img
                      alt='profile'
                      src={formState.values.picture}
                      className={styles.profilePicture}
                    />
                  ) : (
                    <FilePickerButton
                      type={'PROFILE_PICTURE'}
                      shrunk={false}
                      allowedTypes={allowedTypes}
                      maxFileSizeInBytes={maxFileSizeInBytes}
                      onFileAdd={handleProfilePictureSelected}
                      allowMultiple={false}
                    />
                  )}
                  <div className={styles.pictureActions}>
                    {formState.values.picture && (
                      <FilePickerButton
                        type={'BUTTON'}
                        shrunk={false}
                        allowedTypes={allowedTypes}
                        maxFileSizeInBytes={maxFileSizeInBytes}
                        onFileAdd={handleProfilePictureSelected}
                        allowMultiple={false}
                      />
                    )}
                    {formState.values.picture && (
                      <ButtonComponent
                        variant='TEXT'
                        colorScheme='LIGHT'
                        className={styles.removePicture}
                        onClick={() => {
                          setSelectedFile(null);
                          formState.setFieldValue('picture', '');
                        }}
                      >
                        <X size={16} />
                        {t('common.remove')}
                      </ButtonComponent>
                    )}
                  </div>
                </div>
              </fieldset>
              <fieldset className={styles.fieldset}>
                <legend className={styles.title}>
                  {t('userSettings.profile.details.title')}
                </legend>
                <Field
                  as={InputField}
                  label={t('userSettings.profile.details.firstName')}
                  name='firstName'
                  type='text'
                  variant='MULTILINE'
                  required={true}
                  error={formState.errors.firstName}
                  onChange={(e: any) => {
                    formState.setFieldTouched('firstName');
                    formState.handleChange(e);
                  }}
                ></Field>
                <Field
                  as={InputField}
                  label={t('userSettings.profile.details.lastName')}
                  name='lastName'
                  type='text'
                  variant='MULTILINE'
                  required={true}
                  error={formState.errors.lastName}
                  onChange={(e: any) => {
                    formState.setFieldTouched('lastName');
                    formState.handleChange(e);
                  }}
                ></Field>
                <Field
                  as={InputField}
                  label={t('userSettings.profile.details.email')}
                  name='email'
                  type='text'
                  variant='MULTILINE'
                  required={true}
                  error={formState.errors.email}
                  onChange={(e: any) => {
                    formState.setFieldTouched('email');
                    formState.handleChange(e);
                  }}
                ></Field>
                <Field
                  as={InputField}
                  label={t('userSettings.profile.details.phone')}
                  name='phoneNumber'
                  type='text'
                  variant='MULTILINE'
                  error={formState.errors.phoneNumber}
                  required={true}
                  onChange={(e: any) => {
                    e.target.value = formatPhoneNumber(e.target.value);
                    formState.setFieldTouched('phoneNumber');
                    formState.handleChange(e);
                  }}
                ></Field>
              </fieldset>
              <fieldset className={styles.fieldset}>
                <legend className={styles.title}>
                  {t('userSettings.profile.address.title')}
                </legend>
                <Field
                  as={InputField}
                  label={t(
                    'userSettings.profile.address.streetAddress',
                    'Street address'
                  )}
                  name='address.streetAddress'
                  type='text'
                  variant='MULTILINE'
                  onChange={(e: any) => {
                    formState.setFieldTouched('address.streetAddress');
                    formState.handleChange(e);
                  }}
                ></Field>
                <Field
                  as={InputField}
                  label={t('userSettings.profile.address.postal')}
                  name='address.postalCode'
                  type='text'
                  variant='MULTILINE'
                  onChange={(e: any) => {
                    formState.setFieldTouched('address.postalCode');
                    formState.handleChange(e);
                  }}
                  formControlClasses={{ root: styles.oneColumn }}
                ></Field>
                <Field
                  as={InputField}
                  label={t('userSettings.profile.address.city')}
                  name='address.city'
                  type='text'
                  variant='MULTILINE'
                  onChange={(e: any) => {
                    formState.setFieldTouched('address.city');
                    formState.handleChange(e);
                  }}
                ></Field>
                <Field
                  as={InputField}
                  label={t('userSettings.profile.address.country')}
                  name='address.country'
                  type='text'
                  variant='MULTILINE'
                  onChange={(e: any) => {
                    formState.setFieldTouched('address.country');
                    formState.handleChange(e);
                  }}
                ></Field>
              </fieldset>
              <div className={styles.buttons}>
                {!isFormDirty && showSavedInfo ? (
                  <div className={styles.savedInfo}>
                    <CheckCircle />{' '}
                    {t(
                      'userSettings.profile.information.saved',
                      'Changes saved'
                    )}
                  </div>
                ) : (
                  <>
                    <ButtonComponent
                      variant='OUTLINE'
                      size='SMALL'
                      colorScheme='LIGHT'
                      onClick={() => {
                        formState.resetForm();
                        userSettingsStore.uploadProfilePicture(null);
                      }}
                      disabled={!isFormDirty}
                    >
                      {t('common.cancel')}
                    </ButtonComponent>
                    <ButtonComponent
                      variant='FILLED_IN'
                      size='SMALL'
                      type='submit'
                      disabled={!formState.isValid || !isFormDirty}
                    >
                      {t('common.submit')}
                    </ButtonComponent>
                  </>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
});

export default Profile;
