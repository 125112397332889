import { CheckCircle, XCircle } from 'phosphor-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { customProperties } from '../assets/styles/variables';
import ButtonComponent from '../common/components/buttons/ButtonComponent';
import styles from './ResetPassword.module.css';

interface ResetPasswordProps {
  onReset: () => void;
  resetState: 'not-requested' | 'sending' | 'sent' | 'error';
}

const ResetPassword: FC<ResetPasswordProps> = ({ onReset, resetState }) => {
  const { t } = useTranslation();
  if (resetState === 'not-requested')
    return (
      <ButtonComponent
        variant='FILLED_IN'
        colorScheme='DARK'
        size='SMALL'
        onClick={onReset}
      >
        {t('userSettings.privacy.password.reset.action', 'Reset password')}
      </ButtonComponent>
    );
  if (resetState === 'sent')
    return (
      <div className={styles.information}>
        <CheckCircle color={customProperties['--color-success-green']} />{' '}
        {t(
          'userSettings.privacy.password.reset.success',
          'Reset link has been sent to your e-mail'
        )}
      </div>
    );

  if (resetState === 'error')
    return (
      <div className={styles.information}>
        <XCircle color={customProperties['--color-error-red']} />{' '}
        {t(
          'userSettings.privacy.password.reset.error',
          'Something went wrong, we did not manage to save changes. Please try again.'
        )}
      </div>
    );

  return null;
};

export default ResetPassword;
