import React, { FC } from 'react';
import { FieldHookConfig, useField, useFormikContext } from 'formik';
import DropdownComponent, {
  DropdownComponentProps,
} from '../dropdowns/DropdownComponent';

type FormikDropdownProps = FieldHookConfig<string> & DropdownComponentProps;

const FormikDropdown: FC<FormikDropdownProps> = (props) => {
  const [field, meta] = useField(props);
  const { submitCount } = useFormikContext();
  return (
    <DropdownComponent
      error={
        submitCount > 0 || (submitCount == 0 && meta.touched)
          ? meta.error
          : undefined
      }
      {...field}
      {...props}
    />
  );
};

export default FormikDropdown;
