import React, { FC } from 'react';
import styles from './OfferDetails.module.css';
import { useTranslation } from 'react-i18next';
import { PriceFormat } from 'shared';
import { IterationStore } from './IterationStore';
import MaterialTooltip from '@mui/material/Tooltip';
import { Question } from 'phosphor-react';

type OfferDetailsProps = {
  designProposal: IterationStore;
};

const OfferDetails: FC<OfferDetailsProps> = ({ designProposal }) => {
  const [priceDetailsDocument] = designProposal.priceDetails;

  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.box}>
        <span className={styles.lead}>
          {t(
            'project.design.sections.offerDetails.offeringPrice',
            'Offering price:'
          )}
        </span>
        <div className={styles.tooltipBox}>
          <PriceFormat
            className={styles.info}
            suffix={` ${designProposal.currencyCode}`}
            value={designProposal.offeringPrice}
          />
          {!priceDetailsDocument?.fileId && (
            <MaterialTooltip
              title={
                t(
                  'project.design.actions.fileUnavailable',
                  'Price details will be available soon.'
                ) as string
              }
              classes={{ tooltip: styles.tooltip }}
              placement='right-end'
            >
              <Question size={24} className={styles.tooltipIcon} />
            </MaterialTooltip>
          )}
        </div>
      </div>
      <div className={styles.box}>
        <span className={styles.lead}>
          {t('project.design.sections.offerDetails.estimatedDelivery')}
        </span>
        <span
          className={styles.info}
        >{`${designProposal.estimatedDeliveryFrom.format(
          'DD MMM'
        )} - ${designProposal.estimatedDeliveryTo.format(
          'DD MMM YYYY'
        )}`}</span>
      </div>
    </div>
  );
};

export default OfferDetails;
