import Privacy from './Privacy';
import Profile from './Profile';
import Settings from './Settings';
import { Grid } from '@mui/material';
import Scroll from 'react-scroll';
import style from './UserSettings.module.css';
import Account from './Account';
import { ReactNode } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

type settingsSection = {
  name: string;
  title: string;
  component: ReactNode;
};

const getSettings = (
  t: TFunction<'translation', undefined>
): settingsSection[] => [
  {
    name: 'profile',
    title: t('userSettings.profile.title'),
    component: <Profile />,
  },
  {
    name: 'settings',
    title: t('userSettings.settings.notifications.title'),
    component: <Settings />,
  },
  {
    name: 'privacy',
    title: t('userSettings.privacy.privacy.title'),
    component: <Privacy />,
  },
  {
    name: 'account',
    title: t('userSettings.privacy.account.title', 'Account'),
    component: <Account />,
  },
];

const UserSettings = () => {
  const { t } = useTranslation();
  const settings = getSettings(t);

  return (
    <div className={style.page}>
      <Grid container spacing={2}>
        <Grid item xs={0} md={4} lg={3} xl={2}>
          <div className={style.menu}>
            {settings.map((e, i) => {
              return (
                <Scroll.Link
                  className={style.item}
                  key={e.name}
                  activeClass={style.itemActive}
                  to={e.name}
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-400}
                >
                  {e.title}
                </Scroll.Link>
              );
            })}
          </div>
        </Grid>
        <Grid item xs={12} md={8} lg={9} xl={8}>
          <div className={style.container}>
            {settings.map((e, i) => {
              return (
                <Scroll.Element key={`${e.name}_${i}`} name={e.name}>
                  {e.component}
                </Scroll.Element>
              );
            })}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserSettings;
