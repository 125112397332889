import { Form, FormikProvider, useFormik } from 'formik';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ActionButtons from '../common/components/modals/ActionButtons';
import styles from './CancelOrder.module.css';
import FormikDropdown from '../common/components/formik/FormikDropdown';
import * as Yup from 'yup';
import Loading from '../common/components/Loading';
import FormikInputField from '../common/components/formik/FormikInputField';
import { IOrderCancelReasonDto } from 'shared';

interface CancelForm {
  cancelReasonId?: number;
  cancelDescription?: string;
}

export interface CancelFormValidData {
  cancelReasonId: number;
  cancelDescription?: string;
}

interface CancelOrderProps {
  isLoading?: boolean;
  cancelReasons: IOrderCancelReasonDto[];
  onCancel: () => void;
  onSubmit: (cancelInfo: CancelFormValidData) => void;
}

const CancelOrder: FC<CancelOrderProps> = observer(
  ({ isLoading, cancelReasons, onCancel, onSubmit }) => {
    const { t } = useTranslation();

    const cancelForm = useFormik<CancelForm>({
      initialValues: { cancelReasonId: undefined, cancelDescription: '' },
      validationSchema: Yup.object().shape({
        cancelReasonId: Yup.string()
          .nullable()
          .required(
            t('order.cancel.reason.required', 'Please choose a reason')
          ),
        cancelDescription: Yup.string().optional(),
      }),
      onSubmit: (values: CancelForm) => onSubmit(values as CancelFormValidData),
    });

    return (
      <div className={styles.container}>
        <Loading
          type='BIG'
          description={t(
            'order.cancel.reason.submitting',
            'Saving canceling reason'
          )}
          isLoading={isLoading}
        >
          <div className={styles.content}>
            <div className={styles.top}>
              <h2 className={styles.title}>
                {t('order.cancel.header', 'Cancel order')}
              </h2>
              <p className={styles.text}>
                {t(
                  'order.cancel.title',
                  'We’re sorry to see you go. Please, let us know why you are cancelling order of Your Kitchen:'
                )}
              </p>
            </div>
            <FormikProvider value={cancelForm}>
              <Form className={styles.form}>
                <FormikDropdown
                  name='cancelReasonId'
                  label={t('order.cancel.reason.label', 'Choose reason here')}
                  theme='LIGHT'
                  options={cancelReasons.map((x) => ({
                    value: x.id,
                    label: t(`order.cancel.reasons.${x.name}`, x.name),
                  }))}
                />
                <FormikInputField
                  variant='MULTILINE'
                  label={t(
                    'order.cancel.reasonOptional',
                    'Describe your reason here (optional)'
                  )}
                  name='cancelDescription'
                />
              </Form>
            </FormikProvider>
          </div>
          <ActionButtons
            onCancel={onCancel}
            onSubmit={cancelForm.submitForm}
            canSubmit={true}
          />
        </Loading>
      </div>
    );
  }
);

export default CancelOrder;
