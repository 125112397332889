import { SwipeableDrawer } from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import { ReactComponent as Logo } from '../../assets/icons/evi_logo.svg';
import ProfilePicture from './ProfilePicture';
import UserDrawer from './UserDrawer';
import styles from './Header.module.css';
import { NavLink, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import classNames from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';
import LanguageSelector from './LanguageSelector';
import { List } from 'phosphor-react';

interface HeaderProps {
  children?: ReactNode;
  className?: string;
  isHeaderBorder?: boolean;
}

const Header: FC<HeaderProps> = ({
  children,
  className,
  isHeaderBorder = true,
}) => {
  const location = useLocation();
  const { isAuthenticated } = useAuth0();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  useEffect(() => setIsDrawerOpen(false), [location]);

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event?.type === 'keydown' &&
      (event?.key === 'Tab' || event?.key === 'Shift')
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  return (
    <header
      className={classNames(className, styles.main, {
        [styles.notAuthenticated]: !isAuthenticated,
        [styles.headerBorder]: isHeaderBorder,
      })}
    >
      <div className={styles.logo}>
        <NavLink to='/'>
          <Logo className={styles.iconMobile} />
        </NavLink>
      </div>
      {children && <div className={styles.titles}>{children}</div>}
      <div className={styles.actionIcons}>
        {isAuthenticated && (
          <>
            <div className={styles.action}>
              <ProfilePicture />
            </div>
            <div
              className={classNames(styles.action, styles.menu)}
              onClick={toggleDrawer(true)}
            >
              <List size={32} />
            </div>
          </>
        )}
        {!isAuthenticated && <LanguageSelector />}
      </div>
      {isAuthenticated && (
        <SwipeableDrawer
          anchor='right'
          classes={{ paper: styles.drawerPaper }}
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <UserDrawer closeDrawer={() => setIsDrawerOpen(false)} />
        </SwipeableDrawer>
      )}
    </header>
  );
};

export default Header;
