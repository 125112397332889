import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-locize-backend';
import { locizePlugin } from 'locize';
import moment from 'moment';

i18n
  // locize-editor
  // InContext Editor of locize
  .use(locizePlugin)
  // i18next-locize-backend
  // loads translations from your project, saves new keys to it (saveMissing: true)
  // https://github.com/locize/i18next-locize-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.NODE_ENV === 'development',
    lng: 'no',
    fallbackLng: 'no',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function (value: any, format: any, lng: any) {
        if (moment.isMoment(value)) {
          return value.format(format);
        }
        return value;
      },
    },
    backend: {
      projectId: process.env.REACT_APP_LOCIZE_PROJECTID as string,
      apiKey: process.env.REACT_APP_LOCIZE_APIKEY, // YOU should not expose your apps API key to production!!!
      referenceLng: process.env.REACT_APP_LOCIZE_REFLNG,
      version: process.env.REACT_APP_LOCIZE_VERSION,
    },
    saveMissing: process.env.NODE_ENV !== 'production',
    react: {
      bindI18n: 'languageChanged editorSaved',
    },
    compatibilityJSON: 'v3',
  });

export default i18n;
