import { FormControlClassKey } from '@mui/material';
import { ClassNameMap } from '@mui/styles/withStyles';
import { FC, SelectHTMLAttributes } from 'react';
import InputBase from '../inputs/InputBase';
import style from './DropdownComponent.module.css';
import classnames from 'classnames';

export type OptionType = { label: string; value: string | number };

export interface DropdownComponentProps
  extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  label: string;
  options: { label: string; value: string | number }[];
  theme: 'LIGHT' | 'DARK';
  value?: string | number;
  description?: string;
  error?: string;
  formControlClasses?: Partial<ClassNameMap<FormControlClassKey>>;
}

const DropdownComponent: FC<DropdownComponentProps> = ({
  name,
  label,
  value,
  options,
  theme,
  description,
  error,
  ...rest
}) => {
  return (
    <div className={classnames(style.root, { [style.error]: !!error })}>
      <InputBase
        name={name}
        label={label}
        theme={theme}
        multiline={false}
        select={true}
        options={options}
        error={error}
        description={description}
        value={value || ''}
        {...rest}
      />
    </div>
  );
};

export default DropdownComponent;
