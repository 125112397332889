import React, { FC } from 'react';
import styles from './CloseModal.module.css';
import classNames from 'classnames';
import ButtonComponent from '../../common/components/buttons/ButtonComponent';

export interface CloseModalProps {
  title: string;
  confirmText: string;
  backText: string;
  content?: string;
  onConfirm?: () => void;
  onBack?: () => void;
  primaryAction?: 'CONFIRM' | 'BACK';
}

const CloseModal: FC<CloseModalProps> = ({
  title,
  confirmText,
  backText,
  content,
  primaryAction,
  onConfirm,
  onBack,
}) => {
  return (
    <div className={styles.container}>
      <h4 className={classNames(styles.title, styles.center)}>{title}</h4>
      <div className={styles.content}>{content}</div>
      <div className={styles.buttonGroup}>
        <ButtonComponent
          colorScheme={'DARK'}
          variant={primaryAction !== 'CONFIRM' ? 'OUTLINE' : undefined}
          size='BIG'
          onClick={() => onBack && onBack()}
        >
          {backText}
        </ButtonComponent>
        <ButtonComponent
          colorScheme={'DARK'}
          variant={primaryAction !== 'BACK' ? 'FILLED_IN' : undefined}
          size='BIG'
          onClick={() => onConfirm && onConfirm()}
        >
          {confirmText}
        </ButtonComponent>
      </div>
    </div>
  );
};

CloseModal.defaultProps = {
  primaryAction: 'BACK',
};

export default CloseModal;
