import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const conditionalRoute = (
  condition: boolean,
  route: string,
  to: string,
  component: React.ComponentType<unknown>
) => {
  return condition ? (
    <Route path={route} component={component} exact />
  ) : (
    <Redirect from={route} to={to} />
  );
};

export default conditionalRoute;
