import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import ButtonComponent from '../../common/components/buttons/ButtonComponent';
import EmptyStateComponent from '../../common/components/empty-state-component/EmptyStateComponent';
import styles from './DesignsNotReady.module.css';

interface DesignsNotReadyProps {
  projectId: number;
}

const DesignsNotReady: FC<DesignsNotReadyProps> = ({ projectId }) => {
  const { t } = useTranslation();

  return (
    <EmptyStateComponent title={t('project.design.notReadyTitle')}>
      <p className={styles.text}>{t('project.design.notReadyText')}</p>
      <NavLink to={'/'}>
        <ButtonComponent size='BIG' variant='FILLED_IN'>
          {t('project.design.dashboard')}
        </ButtonComponent>
      </NavLink>
    </EmptyStateComponent>
  );
};

export default DesignsNotReady;
