import { makeAutoObservable, runInAction } from 'mobx';
import {
  CustomerProfilePicturesService,
  CustomerPushService,
  CustomerService,
  CustomerType,
  IB2CCustomerProfileDto,
  ILanguageDto,
  INotificationsSettingsDto,
  IPrivacyDto,
  ISettingsDto,
  Logger,
} from 'shared';
import { singleton } from 'tsyringe';
import { LegalDocumentStore } from '../privacy-policy/LegalDocumentStore';

@singleton()
export class UserSettingsStore {
  profile: IB2CCustomerProfileDto;
  settings: ISettingsDto;
  privacy: IPrivacyDto | null = null;
  constructor(
    private customerService: CustomerService,
    private profilePicturesService: CustomerProfilePicturesService,
    private logger: Logger,
    private customerPushService: CustomerPushService,
    public legalDocumentStore: LegalDocumentStore
  ) {
    makeAutoObservable(this);
    this.profile = {
      picture: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      address: {
        streetAddress: '',
        country: '',
        city: '',
        postalCode: '',
      },
      isKitchenForThisAddress: false,
      type: CustomerType.B2C,
    };
    this.settings = {
      notificationsSettings: {
        allowNotifications: false,
        allowPushNotifications: false,
        receiveEmailUpdates: false,
      },
      language: '',
      canChangeLanguage: false,
    };
  }

  loadingSettingsState: 'not-initialized' | 'loading' | 'loaded' | 'error' =
    'not-initialized';
  savingSettingsState: 'not-requested' | 'saving' | 'saved' | 'error' =
    'not-requested';
  privacyState: 'loading' | 'loaded' | 'error' | 'not-initialized' | 'saving' =
    'not-initialized';
  loadingProfileState: 'not-initialized' | 'loading' | 'loaded' | 'error' =
    'not-initialized';
  savingProfileState: 'not-requested' | 'saving' | 'saved' | 'error' =
    'not-requested';
  deletingAccountState: 'not-requested' | 'deleting' | 'deleted' | 'error' =
    'not-requested';
  passwordResetEmailState: 'not-requested' | 'sending' | 'sent' | 'error' =
    'not-requested';

  get isLoadingProfile() {
    return (
      this.loadingProfileState === 'loading' ||
      this.loadingProfileState === 'not-initialized'
    );
  }

  get isLoadingSettings() {
    return (
      this.loadingSettingsState === 'loading' ||
      this.loadingSettingsState === 'not-initialized'
    );
  }

  get isLoadingPrivacy() {
    return (
      this.privacyState === 'loading' || this.privacyState === 'not-initialized'
    );
  }

  public *ensureProfileLoaded() {
    if (
      this.loadingProfileState === 'not-initialized' ||
      this.loadingProfileState === 'error'
    ) {
      yield this.loadMyProfile();
      yield this.customerPushService.register(
        'CustomerBasicInfoChanged',
        () => {
          runInAction(() => this.profileChanged());
        }
      );
    }
  }

  public async uploadProfilePicture(picture: File | null) {
    if (picture) {
      return await this.profilePicturesService.uploadFile(picture);
    }
    return '';
  }

  public *saveProfile(profile: IB2CCustomerProfileDto) {
    this.savingProfileState = 'saving';
    try {
      yield this.customerService.editB2CProfile(profile);
      this.profile = profile;
      this.savingProfileState = 'saved';
    } catch (error) {
      this.logger.logException(error);
      this.savingProfileState = 'error';
    }
  }

  public *ensureSettingsLoaded() {
    if (
      this.loadingSettingsState === 'not-initialized' ||
      this.loadingSettingsState === 'error'
    ) {
      yield this.loadMySettings();
    }
  }

  public *editNotificationsSettings(settings: INotificationsSettingsDto) {
    this.savingSettingsState = 'saving';
    try {
      yield this.customerService.editNotificationsSetting(settings);
      this.settings.notificationsSettings = settings;
      this.savingSettingsState = 'saved';
    } catch (error) {
      this.logger.logException(error);
      this.savingSettingsState = 'error';
    }
  }

  public *changeLanguage(language: ILanguageDto) {
    this.savingSettingsState = 'saving';
    try {
      yield this.customerService.changeLanguage(language);
      this.settings.language = language.name;
      this.savingSettingsState = 'saved';
    } catch (error) {
      this.logger.logException(error);
      this.savingSettingsState = 'error';
    }
  }

  public *ensurePrivacyLoaded() {
    if (
      this.privacyState === 'not-initialized' ||
      this.privacyState === 'error'
    ) {
      yield this.loadMyPrivacy();
    }
    yield this.legalDocumentStore.ensureDocumentLoaded();
  }

  public *changePassword() {
    this.passwordResetEmailState = 'sending';
    try {
      yield this.customerService.changePassword();
      this.passwordResetEmailState = 'sent';
    } catch (error) {
      this.logger.logException(error);
      this.passwordResetEmailState = 'error';
    }
  }

  public *deleteAccount() {
    this.deletingAccountState = 'deleting';
    try {
      yield this.customerService.deleteAccount();
      this.deletingAccountState = 'deleted';
    } catch (error) {
      this.logger.logException(error);
      this.deletingAccountState = 'error';
    }
  }

  private profileChanged() {
    this.loadMyProfile();
  }

  private *loadMySettings() {
    this.loadingSettingsState = 'loading';
    try {
      this.settings = yield this.customerService.getMySettings();
      this.loadingSettingsState = 'loaded';
    } catch (error) {
      this.logger.logException(error);
      this.loadingSettingsState = 'error';
    }
  }

  private *loadMyProfile() {
    this.loadingProfileState = 'loading';
    try {
      this.profile = yield this.customerService.getMyProfile();
      this.loadingProfileState = 'loaded';
    } catch (error) {
      this.logger.logException(error);
      this.loadingProfileState = 'error';
    }
  }

  private *loadMyPrivacy() {
    this.privacyState = 'loading';
    try {
      this.privacy = yield this.customerService.getMyPrivacyInformation();
      this.privacyState = 'loaded';
    } catch (error) {
      this.logger.logException(error);
      this.privacyState = 'error';
    }
  }
}
