import { FC } from 'react';
import { OrderSummaryStore } from '../../project/order-summary/OrderSummaryStore';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import ButtonComponent from '../../common/components/buttons/ButtonComponent';
import styles from './Order.module.css';

interface OrderProps {
  orderStore: OrderSummaryStore;
  projectId: number;
}

const Order: FC<OrderProps> = observer(({ orderStore, projectId }) => {
  const { t } = useTranslation();

  if (!orderStore.orderDto) {
    return null;
  }

  return (
    <>
      <NavLink
        to={{
          pathname: `/project/${projectId}/order`,
          state: { projectId },
        }}
      >
        <ButtonComponent
          variant={'TEXT'}
          size={'SMALL'}
          className={styles.link}
        >
          {!orderStore.orderDto?.invoices?.length
            ? t('dashboard.order.approval')
            : t('dashboard.order.approved')}
        </ButtonComponent>
      </NavLink>
      {orderStore.orderDto?.invoices?.length
        ? orderStore.orderDto.invoices.map((i, index) => {
            return (
              <a
                href={i.file.thumbnailUri}
                key={i.id}
                target={'_blank'}
                className={styles.invoice}
              >{`${t('dashboard.order.invoice')} ${index + 1}`}</a>
            );
          })
        : null}
    </>
  );
});

export default Order;
