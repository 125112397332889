import 'reflect-metadata';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter, Router } from 'react-router-dom';
import './i18n';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import ContainerProvider from './common/ContainerProvider';
import { appInsights, reactPlugin } from 'shared';
import moment from 'moment';
import { onRedirectCallback, routerHistory } from './history';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ModalProvider } from './common/components/modals/ModalManager';
import { Suspense } from 'react';
import Loading from './common/components/Loading';
import Hotjar from './common/components/Hotjar';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import AppFailed from './common/components/alerts/AppFailed';
import MomentAdapter from '@mui/lab/AdapterMoment';

if (process.env.REACT_APP_APP_INSIGHTS) {
  appInsights.loadAppInsights();
}

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <AppInsightsErrorBoundary
      onError={() => <AppFailed />}
      appInsights={reactPlugin}
    >
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
        redirectUri={window.location.origin}
        audience={process.env.REACT_APP_API_AUDIENCE ?? ''}
        scope={process.env.REACT_APP_API_SCOPE ?? ''}
        onRedirectCallback={onRedirectCallback}
      >
        <ContainerProvider>
          <Hotjar
            hjid={process.env.REACT_APP_HOTJAR_ID}
            hjsv={process.env.REACT_APP_HOTJAR_SNIPPER_VERSION}
          >
            <Suspense fallback={<Loading />}>
              <BrowserRouter>
                <Router history={routerHistory}>
                  <LocalizationProvider dateAdapter={MomentAdapter}>
                    <ModalProvider>
                      <App />
                    </ModalProvider>
                  </LocalizationProvider>
                </Router>
              </BrowserRouter>
            </Suspense>
          </Hotjar>
        </ContainerProvider>
      </Auth0Provider>
    </AppInsightsErrorBoundary>
  </ThemeProvider>,
  document.getElementById('root')
);

reportWebVitals();
