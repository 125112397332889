import React, { FC, ReactNode, useState } from 'react';
import styles from './DashboardIntroMenu.module.css';
import { useService } from 'shared';
import { DashboardStore } from '../DashboardStore';
import ButtonComponent from '../../common/components/buttons/ButtonComponent';
import { DotsThreeOutlineVertical } from 'phosphor-react';
import { Menu, MenuItem } from '@mui/material';
import ChangeNameModal from '../../project/components/ChangeNameModal';
import { useTranslation } from 'react-i18next';
import ChangeProjectModal from '../../project/components/ChangeProjectModal';

interface DashboardIntroMenuProps {}

enum MenuItemKeys {
  rename = 'rename_project',
  delete = 'delete_project',
  add = 'add_new_project',
}

type MenuOptionsItems = {
  component: ReactNode;
  key: MenuItemKeys;
  onClick: () => void;
};

const DashboardIntroMenu: FC<DashboardIntroMenuProps> = () => {
  const dashboardStore = useService<DashboardStore>(DashboardStore);
  const { projectStore } = dashboardStore;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isChangeNameModalOpen, setChangeNameModalOpen] = useState(false);
  const [isChangeProjectModalOpen, setIsChangeProjectModalOpen] =
    useState(false);
  const { t } = useTranslation();

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuOptions: MenuOptionsItems[] = [
    {
      key: MenuItemKeys.rename,
      component: (
        <div className={styles.item}>{t('dashboard.menu.rename')}</div>
      ),
      onClick: () => {
        handleClose();
        setChangeNameModalOpen(true);
      },
    },
    {
      key: MenuItemKeys.delete,
      component:
        projectStore?.project?.id && projectStore.myProjects.length > 1 ? (
          <div className={styles.item}>{t('dashboard.menu.changeProject')}</div>
        ) : null,
      onClick: () => {
        handleClose();
        setIsChangeProjectModalOpen(true);
      },
    },
  ];

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>{projectStore.project?.name}</h2>
      <ButtonComponent
        size={'SMALL'}
        variant={'ACTION_ICON'}
        onClick={handleOpen}
      >
        <DotsThreeOutlineVertical size={16} />
      </ButtonComponent>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: styles.menu }}
        variant={'menu'}
      >
        {menuOptions.map((option) => {
          return (
            <MenuItem
              key={option.key}
              onClick={option.onClick}
              classes={{ root: styles.itemFocus }}
            >
              {option.component}
            </MenuItem>
          );
        })}
      </Menu>
      <ChangeNameModal
        project={projectStore.project}
        isOpen={isChangeNameModalOpen}
        setIsOpen={setChangeNameModalOpen}
      />
      <ChangeProjectModal
        projectStore={projectStore}
        isOpen={isChangeProjectModalOpen}
        setIsOpen={setIsChangeProjectModalOpen}
      />
    </div>
  );
};

export default DashboardIntroMenu;
