import classNames from 'classnames';
import React, { ChangeEventHandler, FC, ReactNode, useRef } from 'react';
import ValidationPopup from '../../../common/components/ValidationPopup';
import styles from './ContactInfoField.module.css';

type ContactInfoFieldProps = {
  error: string | undefined;
  showValidationPopup: boolean;
  onCloseValidationPopup: () => void;
  validationPopupPosition: 'bottom' | 'top';
  errorComponent?: (error: string) => ReactNode;
  onOverrideChange?: ChangeEventHandler<HTMLInputElement>;
};

const ContactInfoField: FC<ContactInfoFieldProps> = ({
  error,
  showValidationPopup,
  onCloseValidationPopup,
  errorComponent,
  validationPopupPosition,
  children,
}) => {
  const inputRef = useRef(null);

  const anchorOrigin = validationPopupPosition;
  const transformOrigin =
    validationPopupPosition === 'bottom' ? 'top' : 'bottom';
  const arrowPosition = validationPopupPosition === 'bottom' ? 'TOP' : 'BOTTOM';

  return (
    <div
      ref={inputRef}
      className={classNames(styles.formGroup, {
        [styles.hide]: !error && showValidationPopup,
      })}
    >
      {children}
      <ValidationPopup
        error={
          !error ? undefined : errorComponent ? errorComponent(error) : error
        }
        isOpen={Boolean(error) && showValidationPopup}
        onClose={onCloseValidationPopup}
        element={inputRef.current!}
        anchorOrigin={{ vertical: anchorOrigin, horizontal: 'center' }}
        transformOrigin={{ vertical: transformOrigin, horizontal: 'center' }}
        arrowPosition={arrowPosition}
      />
    </div>
  );
};

export default ContactInfoField;
