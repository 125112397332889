import { Route, Switch } from 'react-router-dom';
import styles from './AppNotLoggedUser.module.css';
import BookMeetingWithHeader from './meeting/components/BookMeetingWithHeader';
import ProtectedComponent from './common/components/ProtectedComponent';
import DashboardWithHeader from './dashboard/DashboardWithHeader';
import EmailVerified from './verify-email/EmailVerified';
import VerifyEmailWithHeader from './verify-email/VerifyEmailWithHeader';
import { useIsUserUnverified } from './common/hooks/useIsUserUnverified';
import PrivacyPolicyWithHeader from './privacy-policy/PrivacyPolicyWithHeader';
import Footer from './common/components/Footer';
import SignIn from './SignIn';
import SignUp from './SignUp';

const AppNotLoggedUser = () => {
  const { isUserUnverified } = useIsUserUnverified();

  return (
    <div className={styles.appContainer}>
      <div className={styles.fullColumn}>
        {isUserUnverified ? (
          <VerifyEmailWithHeader />
        ) : (
          <Switch>
            <Route path='/' exact component={SignIn} />
            <Route
              path='/meeting/:meetingUId?'
              component={BookMeetingWithHeader}
            />
            <Route path='/create-account/:email' component={SignUp} />
            <Route path='/email-verified' component={EmailVerified} />
            <Route path='/privacy-policy' component={PrivacyPolicyWithHeader} />
            <Route
              path='/'
              render={() => (
                <ProtectedComponent component={DashboardWithHeader} />
              )}
            />
          </Switch>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default AppNotLoggedUser;
