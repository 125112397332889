import { FC } from 'react';
import style from './ContactInfo.module.css';
import ContentBox from './ContentBox';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DataLead from './DataLead';

interface ContactInfoProps {
  contactInfo: {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    termsAndConditions: boolean;
  };
}

const ContactInfo: FC<ContactInfoProps> = ({ contactInfo }) => {
  const { t } = useTranslation();

  return (
    <ContentBox>
      <h4 className={style.title}>{t('meeting.details.contact.title')}</h4>
      <DataLead
        name={t('meeting.contactInfo.fullName')}
        lead={`${contactInfo.firstName} ${contactInfo.lastName}`}
      />
      <DataLead
        name={t('meeting.contactInfo.email')}
        lead={contactInfo.email}
      />
      <DataLead
        name={t('meeting.contactInfo.phoneNumber')}
        lead={contactInfo.phoneNumber}
      />
      <div className={style.action}>
        <Link to={'/user-settings'} className={style.link}>
          {t('meeting.details.contact.edit')}
        </Link>
      </div>
    </ContentBox>
  );
};

export default ContactInfo;
