import { useAuth0 } from '@auth0/auth0-react';
import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';

const EmailVerified: FC = () => {
  const { loginWithRedirect } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    const logInAfterVerification = async () => {
      await loginWithRedirect();
      history.push('/');
    };

    logInAfterVerification();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default EmailVerified;
