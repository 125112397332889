import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ActionFailed from '../../common/components/alerts/ActionFailed';

type DesignsErrorProps = {
  onRetry: () => void;
};

const DesignsError: FC<DesignsErrorProps> = ({ onRetry }) => {
  const { t } = useTranslation();
  return (
    <ActionFailed
      restartProcess={onRetry}
      textPrimary={t(
        'project.design.failedToLoadPrimary',
        'Sorry, we got our wires crossed'
      )}
      textSecondary={t(
        'project.design.failedToLoadSecondary',
        'We couldn’t load your data because of technical problems. Please try again'
      )}
      restartProcessText={t(
        'project.design.retryLoad',
        'Retry loading the data'
      )}
    />
  );
};

export default DesignsError;
