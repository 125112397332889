import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { FC } from 'react';
import Loading from './Loading';

interface Props {
  component: React.ComponentType;
}

const ProtectedComponent: FC<Props> = ({ component: Component }) => {
  return <Component />;
};

export default withAuthenticationRequired(ProtectedComponent, {
  onRedirecting: () => <Loading />,
});
