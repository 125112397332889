import { createContext } from 'react';
import { flow, makeAutoObservable } from 'mobx';
import {
  CustomerService,
  EmployeesService,
  IBookMeetingCommand,
  IMeetingDto,
  IProjectSimpleDto,
  IProjectStageDto,
  IRenameProjectCommandBody,
  IRescheduleMeetingCommand,
  Logger,
  MeetingService,
  ProjectService,
} from 'shared';
import { IProjectDto } from 'shared';
import { singleton } from 'tsyringe';
import { AuthenticationService } from '../common/AuthenticationService';
import { BookMeetingStore } from '../meeting/BookMeetingStore';
import _ from 'lodash';

@singleton()
export class ProjectStore {
  constructor(
    private projectService: ProjectService,
    private meetingService: MeetingService,
    private authenticationService: AuthenticationService,
    private logger: Logger,
    customerService: CustomerService,
    employeeService: EmployeesService
  ) {
    makeAutoObservable(this, {
      ensureProjectLoaded: flow,
      loadProjectMeeting: flow,
      createProject: flow,
      bookMeeting: flow,
    });
    this.bookMeetingStore = new BookMeetingStore(
      this,
      meetingService,
      logger,
      customerService,
      employeeService
    );
  }

  projectState:
    | 'loading'
    | 'loaded'
    | 'error'
    | 'renaming'
    | 'not-initialized' = 'not-initialized';

  myProjectsState:
    | 'loading'
    | 'loaded'
    | 'creating'
    | 'error'
    | 'not-initialized' = 'not-initialized';

  loadProjectForCustomerGenerator: Generator<any> | null = null;
  loadProjectsForCustomerGenerator: Generator<any> | null = null;

  projectMeetingState: 'loading' | 'loaded' | 'error' | 'not-initialized' =
    'not-initialized';
  projectStagesState: 'loading' | 'loaded' | 'error' | 'not-initialized' =
    'not-initialized';

  public myProjects: IProjectSimpleDto[] = [];
  public project: IProjectDto | null = null;
  public projectMeeting: IMeetingDto | null = null;
  public bookMeetingStore: BookMeetingStore;
  public stages: IProjectStageDto[] | null = null;

  get showIntroduction() {
    return this.project === null && this.projectState === 'loading';
  }

  *ensureMyProjectsLoaded() {
    if (!this.loadProjectsForCustomerGenerator) {
      this.loadProjectsForCustomerGenerator = this.loadProjectsForCustomer();
    }

    yield this.loadProjectsForCustomerGenerator;
  }

  *ensureProjectLoaded(projectId?: number) {
    if (
      this.projectState === 'loading' ||
      this.myProjectsState === 'creating'
    ) {
      yield this.loadProjectForCustomerGenerator;
    }

    yield this.ensureMyProjectsLoaded();
    this.loadProjectForCustomerGenerator =
      this.loadProjectForCustomer(projectId);
    yield this.loadProjectForCustomerGenerator;
  }

  *createProject() {
    yield this.projectService.createFirstProject();
    this.loadProjectForCustomer();
  }

  *loadProjectStages() {
    this.projectStagesState = 'loading';
    try {
      const stages: IProjectStageDto[] =
        yield this.projectService.getProjectStages();
      this.stages = stages;
      this.projectStagesState = 'loaded';
    } catch {
      this.projectStagesState = 'error';
    }
  }

  *loadProjectMeeting() {
    this.projectMeetingState = 'loading';
    try {
      if (this.project?.id) {
        this.projectMeeting = yield this.meetingService.getProjectMeeting(
          this.project?.id
        );
        this.bookMeetingStore.clearBookAMeeting();
      }
      this.projectMeetingState = 'loaded';
    } catch (error) {
      this.logger.logException(error);
      this.projectState = 'error';
    }
  }

  getSimpleProjectById(projectId: number) {
    return _.find(this.myProjects, (p) => p.id === projectId);
  }

  *rename(projectId: number, body: IRenameProjectCommandBody) {
    this.projectState = 'renaming';
    try {
      yield this.projectService.rename(projectId, body);
      if (this.project && this.project.id === projectId) {
        this.project.name = body.name;
      }
      const project = _.find(this.myProjects, (p) => p.id === projectId);
      if (project) {
        project.name = body.name;
      }

      this.projectState = 'loaded';
    } catch (e) {
      this.logger.logException(e);
      this.projectState = 'error';
    }
  }

  *bookMeeting(command: IBookMeetingCommand): any {
    yield this.meetingService.bookMeeting(command);
    const isAuthenticated: boolean =
      yield this.authenticationService.isAuthenticated();
    if (isAuthenticated) {
      yield this.loadProjectMeeting();
    }
    return undefined;
  }

  *rescheduleMeeting(command: IRescheduleMeetingCommand) {
    yield this.meetingService.rescheduleMeeting(command);
    const isAuthenticated: boolean =
      yield this.authenticationService.isAuthenticated();
    if (isAuthenticated) {
      yield this.loadProjectMeeting();
    }
  }

  *cancelMeeting(meetingUId: string) {
    yield this.meetingService.cancelMeeting(meetingUId);
    const isAuthenticated: boolean =
      yield this.authenticationService.isAuthenticated();
    if (isAuthenticated) {
      yield this.loadProjectMeeting();
    }
  }

  private *loadProjectForCustomer(projectId?: number) {
    this.projectState = 'loading';
    try {
      const projectIdToLoad = projectId
        ? this.myProjects.find((p) => p.id === projectId)?.id
        : this.myProjects[0].id;
      this.project = yield this.projectService.getProject(
        projectIdToLoad ?? this.myProjects[0].id
      );
      this.projectState = 'loaded';
    } catch (error) {
      this.logger.logException(error);
      this.projectState = 'error';
    }
  }

  private *loadProjectsForCustomer() {
    this.myProjectsState = 'loading';
    try {
      this.myProjects = yield this.projectService.getMyProjects();
      if (_.isEmpty(this.myProjects)) {
        this.myProjectsState = 'creating';
        yield this.createProject();
        this.myProjects = yield this.projectService.getMyProjects();
      }
      this.myProjectsState = 'loaded';
    } catch (error) {
      this.logger.logException(error);
      this.myProjectsState = 'error';
    }
  }
}

export const ProjectContext = createContext<ProjectStore | undefined>(
  undefined
);
