import { Skeleton } from '@mui/material';
import { useFormik } from 'formik';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { INotificationsSettingsDto, useService } from 'shared';
import { useLanguages } from '../common/hooks/useLanguages';
import DropdownComponent from '../common/components/dropdowns/DropdownComponent';
import ToggleComponent from '../common/components/toggles/ToggleComponent';
import styles from './Settings.module.css';
import { UserSettingsStore } from './UserSettingsStore';
import ProfileLoadFailed from './ProfileLoadFailed';
import UserSectionTitle from './UserSectionTitle';

interface Language {
  name: string;
}

const Settings: FC = observer(() => {
  const userSettingsStore = useService<UserSettingsStore>(UserSettingsStore);
  const { languages, language, changeLanguage } = useLanguages();
  const { t } = useTranslation();

  const notificationsForm = useFormik<INotificationsSettingsDto>({
    enableReinitialize: true,
    initialValues: userSettingsStore.settings.notificationsSettings,
    onSubmit: async (values) => {
      await flowResult(userSettingsStore.editNotificationsSettings(values));
    },
  });

  const languageForm = useFormik<Language>({
    enableReinitialize: true,
    initialValues: { name: language },
    onSubmit: async (values) => {
      await flowResult(userSettingsStore.changeLanguage(values));
    },
  });

  const loadSettings = useCallback(() => {
    const fetchSettings = async () => {
      await flowResult(userSettingsStore.ensureSettingsLoaded());
    };

    fetchSettings();
  }, [userSettingsStore]);

  useEffect(() => loadSettings(), [loadSettings]);

  useEffect(() => {
    const saveChanges = async (values: INotificationsSettingsDto) => {
      await flowResult(userSettingsStore.editNotificationsSettings(values));
    };

    if (notificationsForm.dirty) {
      saveChanges(notificationsForm.values);
    }
  }, [notificationsForm.values, notificationsForm.dirty, userSettingsStore]);

  useEffect(() => {
    const saveChanges = async (values: Language) => {
      await flowResult(userSettingsStore.changeLanguage(values));
      changeLanguage(values.name);
    };

    if (languageForm.dirty) {
      saveChanges(languageForm.values);
    }
  }, [languageForm.values, languageForm.dirty, userSettingsStore]); // eslint-disable-line react-hooks/exhaustive-deps

  if (userSettingsStore.loadingSettingsState === 'error') {
    return <ProfileLoadFailed onRetry={() => loadSettings()} />;
  }

  if (userSettingsStore.isLoadingSettings)
    return <Skeleton variant='text' width='100%' height='500px' />;

  return (
    <form>
      <section className={styles.section}>
        <UserSectionTitle
          title={t('userSettings.settings.notifications.title')}
        />
        <div className={styles.toggleContainer}>
          <ToggleComponent
            type='checkbox'
            label={t('userSettings.settings.notifications.allowNotifications')}
            name='allowNotifications'
            theme='DARK'
            onChange={notificationsForm.handleChange}
            checked={notificationsForm.values.allowNotifications}
          />
        </div>
        <div className={styles.toggleContainer}>
          <ToggleComponent
            type='checkbox'
            label={t('userSettings.settings.notifications.allowPush')}
            name='allowPushNotifications'
            theme='DARK'
            onChange={notificationsForm.handleChange}
            checked={notificationsForm.values.allowPushNotifications}
          />
        </div>
        <div className={styles.toggleContainer}>
          <ToggleComponent
            type='checkbox'
            label={t('userSettings.settings.notifications.email')}
            name='receiveEmailUpdates'
            theme='DARK'
            onChange={notificationsForm.handleChange}
            checked={notificationsForm.values.receiveEmailUpdates}
          />
        </div>
      </section>
      {userSettingsStore.settings.canChangeLanguage && (
        <section className={styles.section}>
          <UserSectionTitle title={t('userSettings.settings.language.title')} />
          <p className={styles.description}>
            {t('userSettings.settings.language.description')}
          </p>
          <DropdownComponent
            name='name'
            theme='LIGHT'
            label={t('userSettings.settings.language.choose')}
            value={languageForm.values.name}
            onChange={languageForm.handleChange}
            options={Object.keys(languages).map((lang) => ({
              value: lang,
              label: lang,
            }))}
          />
        </section>
      )}
    </form>
  );
});

export default Settings;
