import React, { FC, ReactNode } from 'react';
import styles from './OrderSectionParagraph.module.css';

type OrderSectionParagraphProps = {
  children: ReactNode;
};

const OrderSectionParagraph: FC<OrderSectionParagraphProps> = ({
  children,
}) => {
  return <p className={styles.text}>{children}</p>;
};

export default OrderSectionParagraph;
