import { customProperties } from '../../../assets/styles/variables';

export const DatePickerTheme = {
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: customProperties['--font-atlas'],
      },
      body1: {
        fontFamily: customProperties['--font-arizona'],
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: 'unset',
        color: customProperties['--color-alternate-text'],
      },
      body2: {
        fontSize: '12px',
        fontFamily: customProperties['--font-atlas'],
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: customProperties['--color-primary-background'],
        padding: '10px 7px',
      },
      rounded: {
        borderRadius: '10px',
      },
      elevation8: {
        boxShadow: '0 40px 20px rgba(0,0,0,0.15)',
        padding: '5px 10px 0 10px',
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: customProperties['--color-secondary'],
        color: customProperties['--color-primary'],
        '&:hover': {
          color: customProperties['--color-primary'],
        },
      },
      dayLabel: {
        fontFamily: customProperties['--font-atlas'],
        color: customProperties['--color-primary'],
        fontSize: '12px',
        width: 'auto',
        padding: '6px',
      },
      daysHeader: {
        justifyContent: 'space-between',
      },
    },
    MuiPickersDay: {
      day: {
        padding: '4px',
        color: customProperties['--color-primary'],
        opacity: 1,
        '&:hover': {
          color: customProperties['--color-primary'],
        },
      },
      current: {
        color: customProperties['--color-action-background'],
        '&:hover': {
          color: customProperties['--color-action-background'],
        },
      },
      daySelected: {
        backgroundColor: customProperties['--color-action-background'],
        color: customProperties['--color-primary'],
        opacity: 1,
        '&:hover': {
          backgroundColor: customProperties['--color-action-background'],
        },
      },
    },
    MuiPickersCalendar: {
      week: {
        justifyContent: 'space-between',
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: customProperties['--font-atlas'],
        lineHeight: '26px',
        fontSize: '16px',
        color: customProperties['--color-secondary'],
        opacity: 0.5,
        '&$focused': {
          color: customProperties['--color-action-background'],
          opacity: 1,
        },
      },
      filled: {
        opacity: 0.8,
      },
    },
    MuiInput: {
      root: {
        fontFamily: customProperties['--font-atlas'],
        lineHeight: '26px',
        fontSize: '16px',
        color: customProperties['--color-secondary'],
        borderColor: customProperties['--color-secondary'],
      },
      underline: {
        borderBottom: 'none',
        '&::before': {
          borderBottom: `1px solid rgba(${customProperties['--color-primary-background-rgb']}, 0.3)`,
        },
        '&$focused:not(after)': {
          borderBottom: `0px`,
        },
        '&$focused:after': {
          borderBottom: `2px solid ${customProperties['--color-action-background']}`,
          opacity: 1,
        },
        '&:hover:not(.disabled)::before': {
          borderBottom: `2px solid ${customProperties['--color-secondary']}`,
          opacity: 1,
        },
      },
    },
    MuiInputLabel: {
      formControl: {
        transform: 'translateY(19px) scale(1)',
      },
      shrink: {
        transform: 'translateY(0) scale(0.75)',
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
        color: customProperties['--color-primary'],
        opacity: 0.5,
        '&:focus, &:hover': {
          color: customProperties['--color-primary'],
          opacity: 1,
        },
      },
    },
  },
};

export default DatePickerTheme;
