import React, { FC, ReactNode } from 'react';
import styles from './OrderSection.module.css';

type OrderSectionProps = {
  children?: ReactNode;
};

const OrderSection: FC<OrderSectionProps> = ({ children }) => {
  return <section className={styles.main}>{children}</section>;
};

export default OrderSection;
