import { FC } from 'react';
import styles from './MeetingInfo.module.css';
import ContentBox from './ContentBox';
import { ChooseLocationStore } from '../../../BookMeetingStore';
import { useTranslation } from 'react-i18next';

interface MeetingInfoProps {
  chooseLocationStore: ChooseLocationStore;
}

const MeetingInfo: FC<MeetingInfoProps> = ({ chooseLocationStore }) => {
  const { t } = useTranslation();
  const selectedLocation: string[] =
    chooseLocationStore.selectedLocation.split(',') ?? [];
  return (
    <ContentBox>
      <h4 className={styles.title}>
        {chooseLocationStore.selectedLocation
          ? t('meeting.details.location.title')
          : t('meeting.details.location.onlineTitle')}
      </h4>
      {chooseLocationStore.selectedLocation ? (
        <>
          {selectedLocation.map((x, i) => (
            <span key={`location_${i}`} className={styles.location}>
              {x}
            </span>
          ))}
        </>
      ) : (
        <p>{t('meeting.details.location.onlineDescription')}</p>
      )}
      <div className={styles.image} />
    </ContentBox>
  );
};

export default MeetingInfo;
