import React, { FC } from 'react';
import {
  FormControl,
  FormControlClassKey,
  FormHelperText,
  Input,
  InputLabel,
} from '@mui/material';
import styles from './InputUnderline.module.css';
import { ClassNameMap } from '@mui/styles/withStyles';
import { mergeMUIClasses } from '../../utils/mergeMUIClasses';

interface UnderlineInputProps {
  name: string;
  label: string;
  value?: string | number;
  error?: string;
  description?: string;
  required?: boolean;
  formControlClasses?: Partial<ClassNameMap<FormControlClassKey>>;
}

const InputUnderline: FC<UnderlineInputProps> = ({
  name,
  label,
  value,
  error,
  description,
  formControlClasses,
  required,
  ...rest
}) => {
  return (
    <FormControl
      classes={mergeMUIClasses(formControlClasses ?? {}, {
        root: styles.container,
      })}
      error={!!error}
      required={required}
    >
      <InputLabel
        htmlFor={name}
        classes={{
          root: styles.label,
          shrink: styles.labelShrink,
          animated: styles.labelAnimated,
          error: styles.labelError,
          focused: styles.labelFocused,
        }}
      >
        {label}
      </InputLabel>
      <Input
        id={name}
        name={name}
        inputProps={{ ...rest }}
        aria-describedby={'describe-' + name}
        aria-label={label}
        value={value ?? ''}
        classes={{
          input: styles.input,
          underline: styles.inputUnderline,
          focused: styles.inputFocused,
          error: styles.inputError,
          disabled: styles.inputDisabled,
        }}
      />
      <FormHelperText
        id={'describe-' + name}
        classes={{
          root: styles.helper,
        }}
      >
        {description}
      </FormHelperText>
      {error && error.length && (
        <FormHelperText
          id={'describe-' + name + '-error'}
          classes={{
            root: styles.helper,
            error: styles.helperError,
          }}
        >
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default InputUnderline;
