import { makeAutoObservable } from 'mobx';
import {
  AvatarStore,
  CommentsPushService,
  DesignCommentsService,
  DesignStatus,
  IDesignIterationsDto,
  IProjectDto,
  Logger,
  ProjectDesignService,
  ProjectService,
} from 'shared';
import { singleton } from 'tsyringe';
import { IterationStore } from './IterationStore';

@singleton()
export class DesignIterationsStore {
  iterations: IterationStore[] | null;
  project: IProjectDto | null;

  constructor(
    private projectDesignsService: ProjectDesignService,
    private projectService: ProjectService,
    private commentsPushService: CommentsPushService,
    private designCommentsService: DesignCommentsService,
    private avatarsStore: AvatarStore,
    private logger: Logger
  ) {
    makeAutoObservable(this);
    this.iterations = null;
    this.project = null;
  }

  designIterationsState: 'loading' | 'loaded' | 'error' | 'not-initialized' =
    'not-initialized';

  get isLoadingDesignIterations() {
    return (
      this.designIterationsState === 'loading' ||
      this.designIterationsState === 'not-initialized'
    );
  }

  get sortedIterations() {
    return {
      active: this.iterations?.filter((i) => i.status === DesignStatus.Active),
      archived: this.iterations?.filter(
        (i) => i.status === DesignStatus.Archival
      ),
      ordered: this.iterations?.filter(
        (i) => i.status === DesignStatus.Ordered
      ),
    };
  }

  public *loadDesignIteration(projectId: number) {
    yield this.loadDesignIterations(projectId);
  }

  private *loadDesignIterations(projectId: number) {
    try {
      this.designIterationsState = 'loading';
      const [designIterations, project]: [
        IDesignIterationsDto | null,
        IProjectDto | null
      ] = yield Promise.all([
        this.projectDesignsService.getDesignIterations(projectId),
        this.projectService.getProject(projectId),
      ]);
      this.iterations =
        designIterations?.iterations.map(
          (iteration) =>
            new IterationStore(
              this.avatarsStore,
              this.logger,
              iteration,
              projectId
            )
        ) ?? null;
      this.project = project;
      this.designIterationsState = 'loaded';
    } catch (error) {
      this.logger.logException(error);
      this.designIterationsState = 'error';
    }
  }
}
