import { flow, flowResult, makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { Logger } from 'shared';
import { ProjectStore } from '../project/ProjectStore';
import { OrderSummaryStore } from '../project/order-summary/OrderSummaryStore';
import { DesignIterationsStore } from '../project/designs/DesignIterationsStore';

@singleton()
export class DashboardStore {
  constructor(
    public projectStore: ProjectStore,
    public orderStore: OrderSummaryStore,
    public designIterationStore: DesignIterationsStore,
    private logger: Logger
  ) {
    makeAutoObservable(this, {
      ensureDashboardLoaded: flow,
    });
  }

  dashboardState: 'loading' | 'loaded' | 'error' | 'not-initialized' =
    'not-initialized';

  *ensureDashboardLoaded(projectId?: number) {
    if (this.dashboardState === 'loading') {
      return;
    }

    try {
      this.dashboardState = 'loading';
      yield flowResult(this.projectStore.ensureProjectLoaded(projectId));
      flowResult(this.projectStore.loadProjectMeeting());
      flowResult(this.projectStore.loadProjectStages());
      flowResult(
        this.orderStore.loadOrderSummary(
          projectId || this.projectStore.project?.id
        )
      );
      this.dashboardState = 'loaded';
    } catch (error) {
      this.logger.logException(error);
      this.dashboardState = 'error';
    }
  }
}
