import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

type LanguageDto = {
  name: string;
  nativeName: string;
  isReferenceLanguage: boolean;
};

type LanguageDtoMap = Record<string, LanguageDto>;

const momentLocales: Record<string, string> = {
  en: 'en',
  no: 'nb',
};

export const useLanguages = () => {
  const { i18n } = useTranslation();

  const [languages, setLanguages] = useState<LanguageDtoMap>({
    en: {
      name: 'English',
      nativeName: 'English',
      isReferenceLanguage: true,
    },
  });

  useEffect(() => {
    i18n.services.backendConnector.backend.getLanguages(
      (error: any, res: LanguageDtoMap) => {
        if (error) {
          return;
        }

        setLanguages(res);
      }
    );
  }, [i18n]);

  const changeLanguage = (language: string) => {
    moment.locale(momentLocales[language] ?? momentLocales.en);
    return i18n.changeLanguage(language);
  };

  return {
    language: i18n.resolvedLanguage,
    languages,
    changeLanguage,
  };
};
