import {
  container,
  DependencyContainer,
  instanceCachingFactory,
} from 'tsyringe';
import { AxiosInstance } from 'axios';
import {
  ProjectService,
  AxiosInstanceFactory,
  ITokenProvider,
  CustomerService,
  CustomerProfilePicturesService,
  OrderService,
  Logger,
  appInsights,
  ProjectDesignFilesProvider,
  ChecklistService,
  EmailVerificationService,
  LegalConfigurationService,
  CommentsPushService,
  FilesService,
  DesignCommentsService,
  AvatarStore,
  CustomerPushService,
  ChecklistStore,
  MeetingService,
  PaymentSplitService,
  OrderCancelReasonService,
  EmployeesService,
} from 'shared';
import { AuthenticationService } from './AuthenticationService';
import { ProjectDesignService } from 'shared';
import { SignalRLoggerAdapter } from 'shared';
import { SeverityLevel } from '@microsoft/applicationinsights-common';

const customerPortalContainer = container;
const tokenProviderKey = 'tokenProvider';
const axiosInstanceFactoryKey = 'axiosInstanceFactory';

customerPortalContainer.register(axiosInstanceFactoryKey, {
  useFactory: (dependencyContainer: DependencyContainer) => {
    const tokenProvider =
      dependencyContainer.resolve<ITokenProvider>(tokenProviderKey);
    return new AxiosInstanceFactory(tokenProvider);
  },
});

customerPortalContainer.register<AxiosInstance>('SalesPortalAPIAxiosInstance', {
  useFactory: (dependencyContainer: DependencyContainer) => {
    const factory = dependencyContainer.resolve<AxiosInstanceFactory>(
      axiosInstanceFactoryKey
    );
    return factory.create(process.env.REACT_APP_API_SCOPE as string);
  },
});

customerPortalContainer.register<ProjectService>(ProjectService, {
  useFactory: (dependencyContainer: DependencyContainer) => {
    const axiosInstance = dependencyContainer.resolve<AxiosInstance>(
      'SalesPortalAPIAxiosInstance'
    );
    return new ProjectService(axiosInstance);
  },
});

customerPortalContainer.register<MeetingService>(MeetingService, {
  useFactory: (dependencyContainer: DependencyContainer) => {
    const axiosInstance = dependencyContainer.resolve<AxiosInstance>(
      'SalesPortalAPIAxiosInstance'
    );
    return new MeetingService(axiosInstance);
  },
});

customerPortalContainer.register<ProjectDesignService>(ProjectDesignService, {
  useFactory: (dependencyContainer: DependencyContainer) => {
    const axiosInstance = dependencyContainer.resolve<AxiosInstance>(
      'SalesPortalAPIAxiosInstance'
    );
    return new ProjectDesignService(axiosInstance);
  },
});

customerPortalContainer.register<AuthenticationService>(AuthenticationService, {
  useFactory: (dependencyContainer: DependencyContainer) => {
    const tokenProvider =
      dependencyContainer.resolve<ITokenProvider>(tokenProviderKey);
    return new AuthenticationService(tokenProvider);
  },
});

customerPortalContainer.register<ChecklistService>(ChecklistService, {
  useFactory: (dependencyContainer: DependencyContainer) => {
    const axiosInstance = dependencyContainer.resolve<AxiosInstance>(
      'SalesPortalAPIAxiosInstance'
    );
    return new ChecklistService(axiosInstance);
  },
});

customerPortalContainer.register<FilesService>(FilesService, {
  useFactory: (dependencyContainer: DependencyContainer) => {
    const axiosInstance = dependencyContainer.resolve<AxiosInstance>(
      'SalesPortalAPIAxiosInstance'
    );
    return new FilesService(
      axiosInstance,
      dependencyContainer.resolve<Logger>(Logger)
    );
  },
});

customerPortalContainer.register<PaymentSplitService>(PaymentSplitService, {
  useFactory: (dependencyContainer: DependencyContainer) => {
    const axiosInstance = dependencyContainer.resolve<AxiosInstance>(
      'SalesPortalAPIAxiosInstance'
    );
    return new PaymentSplitService(axiosInstance);
  },
});

customerPortalContainer.register<CommentsPushService>(CommentsPushService, {
  useFactory: instanceCachingFactory<CommentsPushService>(
    (dependencyContainer: DependencyContainer) => {
      const tokenProvider =
        dependencyContainer.resolve<ITokenProvider>(tokenProviderKey);
      return new CommentsPushService(
        tokenProvider,
        process.env.REACT_APP_API_SCOPE as string,
        dependencyContainer.resolve<SignalRLoggerAdapter>(SignalRLoggerAdapter),
        undefined
      );
    }
  ),
});

customerPortalContainer.register<CustomerPushService>(CustomerPushService, {
  useFactory: instanceCachingFactory<CustomerPushService>(
    (dependencyContainer: DependencyContainer) => {
      const tokenProvider =
        dependencyContainer.resolve<ITokenProvider>(tokenProviderKey);
      return new CustomerPushService(
        tokenProvider,
        process.env.REACT_APP_API_SCOPE as string,
        dependencyContainer.resolve<SignalRLoggerAdapter>(SignalRLoggerAdapter),
        undefined
      );
    }
  ),
});

customerPortalContainer.register<CustomerService>(CustomerService, {
  useFactory: (dependencyContainer: DependencyContainer) => {
    const axiosInstance = dependencyContainer.resolve<AxiosInstance>(
      'SalesPortalAPIAxiosInstance'
    );
    return new CustomerService(axiosInstance);
  },
});

customerPortalContainer.register<EmployeesService>(EmployeesService, {
  useFactory: (dependencyContainer: DependencyContainer) => {
    const axiosInstance = dependencyContainer.resolve<AxiosInstance>(
      'SalesPortalAPIAxiosInstance'
    );

    return new EmployeesService(axiosInstance);
  },
});

customerPortalContainer.register<CustomerProfilePicturesService>(
  CustomerProfilePicturesService,
  {
    useFactory: (dependencyContainer: DependencyContainer) => {
      const axiosInstance = dependencyContainer.resolve<AxiosInstance>(
        'SalesPortalAPIAxiosInstance'
      );
      return new CustomerProfilePicturesService(axiosInstance);
    },
  }
);

customerPortalContainer.register<OrderService>(OrderService, {
  useFactory: (dependencyContainer: DependencyContainer) => {
    const axiosInstance = dependencyContainer.resolve<AxiosInstance>(
      'SalesPortalAPIAxiosInstance'
    );
    return new OrderService(axiosInstance);
  },
});

customerPortalContainer.register<ProjectDesignFilesProvider>(
  ProjectDesignFilesProvider,
  {
    useFactory: (dependencyContainer: DependencyContainer) => {
      const axiosInstance = dependencyContainer.resolve<AxiosInstance>(
        'SalesPortalAPIAxiosInstance'
      );
      return new ProjectDesignFilesProvider(axiosInstance);
    },
  }
);

customerPortalContainer.register<Logger>(Logger, {
  useFactory: instanceCachingFactory<Logger>(
    () =>
      new Logger(
        appInsights,
        process.env.NODE_ENV === 'development'
          ? SeverityLevel.Information
          : SeverityLevel.Warning
      )
  ),
});

customerPortalContainer.register<SignalRLoggerAdapter>(SignalRLoggerAdapter, {
  useFactory: instanceCachingFactory<SignalRLoggerAdapter>(
    (dc) => new SignalRLoggerAdapter(dc.resolve<Logger>(Logger))
  ),
});

customerPortalContainer.register<EmailVerificationService>(
  EmailVerificationService,
  {
    useFactory: instanceCachingFactory<EmailVerificationService>(
      (dependencyContainer: DependencyContainer) => {
        const axiosInstance = dependencyContainer.resolve<AxiosInstance>(
          'SalesPortalAPIAxiosInstance'
        );
        return new EmailVerificationService(axiosInstance);
      }
    ),
  }
);

customerPortalContainer.register<LegalConfigurationService>(
  LegalConfigurationService,
  {
    useFactory: (dependencyContainer: DependencyContainer) => {
      const axiosInstance = dependencyContainer.resolve<AxiosInstance>(
        'SalesPortalAPIAxiosInstance'
      );
      return new LegalConfigurationService(axiosInstance);
    },
  }
);

customerPortalContainer.register<DesignCommentsService>(DesignCommentsService, {
  useFactory: (dependencyContainer: DependencyContainer) => {
    const axiosInstance = dependencyContainer.resolve<AxiosInstance>(
      'SalesPortalAPIAxiosInstance'
    );
    return new DesignCommentsService(axiosInstance);
  },
});

customerPortalContainer.register<AvatarStore>(AvatarStore, {
  useFactory: instanceCachingFactory(
    (dependencyContainer: DependencyContainer) => {
      return new AvatarStore(
        dependencyContainer.resolve<ProjectService>(ProjectService),
        dependencyContainer.resolve<CustomerPushService>(CustomerPushService)
      );
    }
  ),
});

customerPortalContainer.register<ChecklistStore>(ChecklistStore, {
  useFactory: (dependencyContainer: DependencyContainer) => {
    return new ChecklistStore(
      dependencyContainer.resolve<ChecklistService>(ChecklistService),
      dependencyContainer.resolve<FilesService>(FilesService)
    );
  },
});

customerPortalContainer.register<OrderCancelReasonService>(
  OrderCancelReasonService,
  {
    useFactory: (dependencyContainer: DependencyContainer) => {
      const axiosInstance = dependencyContainer.resolve<AxiosInstance>(
        'SalesPortalAPIAxiosInstance'
      );
      return new OrderCancelReasonService(axiosInstance);
    },
  }
);

export { tokenProviderKey, customerPortalContainer };
