import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../../common/components/buttons/ButtonComponent';
import styles from './ActionModal.module.css';

interface ActionModalProps {
  title: string;
  onCancel: () => void;
  onSubmit: () => void;
}

const ActionModal: FC<ActionModalProps> = ({
  title,
  onCancel,
  onSubmit,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        {children}
      </div>
      <div className={styles.actions}>
        <div className={styles.buttons}>
          <ButtonComponent
            variant='OUTLINE'
            size='SMALL'
            colorScheme='LIGHT'
            onClick={onCancel}
          >
            {t('common.cancel')}
          </ButtonComponent>
          <ButtonComponent
            variant='FILLED_IN'
            size='SMALL'
            colorScheme='DARK'
            onClick={onSubmit}
          >
            {t('common.submit')}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};

export default ActionModal;
