import { Truck, Wallet } from 'phosphor-react';
import React, { FC, SyntheticEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  IFileIdDto,
  IOrderDto,
  Logger,
  MedialFileAction,
  PriceFormat,
  ProjectDesignFilesProvider,
  useService,
} from 'shared';
import IconTextLine from '../../common/IconTextLine';
import OrderSection from '../visual/OrderSection';
import styles from './OrderPaymentSection.module.css';
import OrderSectionContent from '../visual/OrderSectionContent';
import OrderSectionParagraph from '../visual/OrderSectionParagraph';
import OrderSectionTitle from '../visual/OrderSectionTitle';
import OrderSectionGrid from '../visual/OrderSectionGrid';
import { formatPhoneNumber } from '../../../common/utils/phoneNumberFormatter';

type OrderPaymentSectionProps = {
  order: IOrderDto;
  projectId?: number;
};

const OrderPaymentSection: FC<OrderPaymentSectionProps> = ({
  order,
  projectId,
}) => {
  const { t } = useTranslation();

  const logger = useService<Logger>(Logger);
  const fileProvider = useService<ProjectDesignFilesProvider>(
    ProjectDesignFilesProvider
  );

  const handlePreviewDocument = async (
    e: SyntheticEvent,
    fileId?: IFileIdDto
  ) => {
    e.preventDefault();
    if (!fileId || !projectId) {
      return;
    }
    try {
      const file = await fileProvider.getFreshFileDownloadData(
        projectId,
        fileId.blobId,
        MedialFileAction.Preview
      );
      if (file) {
        window.open(file.uri, '_blank');
      } else {
        throw new Error(
          `Sas token for desing file ${JSON.stringify(
            fileId.fileName
          )} is empty!`
        );
      }
    } catch (e) {
      logger.logException(e);
    }
  };

  const formatDeliveryDate = (from: moment.Moment, to: moment.Moment) => {
    if (from.isSame(to)) {
      return to.format('DD MMM YYYY');
    }

    if (from.month() === to.month()) {
      return t(
        'project.order.payment.delivery',
        'between {{estimatedDelivery}}',
        {
          estimatedDelivery: `${from.format('DD')}-${to.format('DD MMM YYYY')}`,
        }
      );
    }

    if (from.year() === to.year()) {
      return t(
        'project.order.payment.delivery',
        'between {{estimatedDelivery}}',
        {
          estimatedDelivery: `${from.format('DD MMM')}-${to.format(
            'DD MMM YYYY'
          )}`,
        }
      );
    }

    return `${from.format('DD MMM YYYY')} - ${to.format('DD MMM YYYY')}`;
  };

  return (
    <OrderSection>
      <OrderSectionTitle title={t('project.order.payment.title', 'Payment')} />
      <OrderSectionGrid>
        <OrderSectionContent>
          <div className={styles.paymentDetails}>
            <IconTextLine icon={Wallet}>
              <div className={styles.price}>
                <PriceFormat
                  prefix={`${order.currency} `}
                  value={order.totalPrice}
                  className={styles.totalPrice}
                  decimalScale={0}
                />
                <span className={styles.taxInfo}>
                  <Trans t={t} i18nKey='project.order.payment.taxInfo'>
                    {`incl. VAT `}
                    <PriceFormat
                      prefix={`${order.currency} `}
                      value={order.tax}
                      decimalScale={0}
                    />
                  </Trans>
                </span>
              </div>
            </IconTextLine>
            {order.priceDetailsFileId && (
              <div
                className={styles.seeDetails}
                onClick={(e) =>
                  handlePreviewDocument(e, order.priceDetailsFileId)
                }
              >
                {t('project.order.payment.seeDetails', 'See details')}
              </div>
            )}
          </div>
        </OrderSectionContent>
        <OrderSectionContent>
          <div className={styles.paymentDetails}>
            <IconTextLine icon={Truck}>
              <span className={styles.delivery}>
                {`${t(
                  'project.order.payment.estimatedDelivery',
                  'Estimated delivery'
                )}: `}
                <span className={styles.deliveryDate}>
                  {formatDeliveryDate(
                    order.plannedDeliveryFrom,
                    order.plannedDeliveryTo
                  )}
                </span>
              </span>
            </IconTextLine>
          </div>
        </OrderSectionContent>
      </OrderSectionGrid>
      <OrderSectionParagraph>
        <span className={styles.contactInfo}>
          <Trans t={t} i18nKey='project.order.payment.contactUs'>
            Contact us by calling
            <a
              href={`tel:${order.contactPhone}`}
              className={styles.phoneNumber}
            >
              {{
                contactPhoneNumber: formatPhoneNumber(order.contactPhone),
              }}
            </a>
            in case any questions.
          </Trans>
        </span>
      </OrderSectionParagraph>
    </OrderSection>
  );
};

export default OrderPaymentSection;
