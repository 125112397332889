import React, { FC, ReactNode } from 'react';
import { ButtonBase } from '@mui/material';
import classNames from 'classnames';
import styles from './OutlineButton.module.css';

interface OutlineButtonProps {
  children: ReactNode;
  classes: {
    base: { [prop: string]: boolean };
    colorScheme: { [prop: string]: boolean };
    size: { [prop: string]: boolean };
  };
}

const OutlineButton: FC<OutlineButtonProps> = ({
  children,
  classes,
  ...rest
}) => {
  return (
    <ButtonBase
      classes={{
        root: classNames(
          styles.root,
          classes.base,
          classes.colorScheme,
          classes.size
        ),
        disabled: styles.disabled,
        focusVisible: styles.focus,
      }}
      {...rest}
    >
      {children}
    </ButtonBase>
  );
};

export default OutlineButton;
