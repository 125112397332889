import { useContext } from 'react';
import MUIAccordionSummary, {
  AccordionSummaryTypeMap,
} from '@mui/material/AccordionSummary';
import styles from './AccordionSummary.module.css';
import { CaretDown } from 'phosphor-react';
import classNames from 'classnames';
import { ExtendButtonBase } from '@mui/material';
import { AccordionContext } from './Accordion';
import { mergeMUIClasses } from '../../utils/mergeMUIClasses';

type AccordionSummaryLocalProps = {};

const AccordionSummary: ExtendButtonBase<
  AccordionSummaryTypeMap<AccordionSummaryLocalProps>
> = ({ children, ...props }: any) => {
  const accordionContext = useContext(AccordionContext);
  const classes = mergeMUIClasses(props.classes, {
    root: classNames(styles.summary, {
      [styles.dark]: accordionContext?.colorScheme === 'DARK',
    }),
  });

  return (
    <MUIAccordionSummary classes={classes} {...props}>
      <div className={styles.summaryContent}>
        <div className={styles.summaryLeft}>{children}</div>
        <div className={styles.summaryRight}>
          <CaretDown
            className={classNames(styles.icon, {
              [styles.expanded]: accordionContext?.expanded,
            })}
            size={16}
          />
        </div>
      </div>
    </MUIAccordionSummary>
  );
};

export default AccordionSummary;
