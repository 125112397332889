import { Form, Formik, FormikProps, FormikErrors } from 'formik';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import {
  IContactInfoDto,
  IEmployeeDto,
  ISlotDto,
  MeetingService,
  MeetingType,
  useService,
} from 'shared';
import styles from './FillContactInfo.module.css';
import * as Yup from 'yup';
import ContactInfoField from './ContactInfoField';
import { Trans, useTranslation } from 'react-i18next';
import StepWithNavigation from '../../../common/components/CardWithSteps/StepWithNavigation';
import { useAuth0 } from '@auth0/auth0-react';
import ButtonComponent from '../../../common/components/buttons/ButtonComponent';
import { formatPhoneNumber } from '../../../common/utils/phoneNumberFormatter';
import { parsePhoneNumber } from 'libphonenumber-js';
import { DashboardRedirectState } from '../../../dashboard/Dashboard';
import { Moment } from 'moment';
import FormikCheckbox from '../../../common/components/formik/FormikCheckbox';
import ContactInfoInput from './ContactInfoInput';
import { LegalDocumentStore } from '../../../privacy-policy/LegalDocumentStore';
import { validateName as validateNameWithResult } from '../../../common/utils/nameValidation';
import DesignerCard from '../DesignerCard/DesignerCard';

export type FillContactInfoProps = {
  initialContactInfo: IContactInfoDto;
  onStepFulfilled: (contactInfo: IContactInfoDto) => void;
  meetingType: MeetingType;
  location: string;
  date: Moment;
  slot: ISlotDto;
  designer?: IEmployeeDto | null;
};

export type FillContactInfoHandle = {
  submit: () => boolean;
};

const FillContactInfo: FC<FillContactInfoProps> = ({
  initialContactInfo,
  onStepFulfilled,
  meetingType,
  location,
  date,
  slot,
  designer,
}) => {
  const formRef = useRef<FormikProps<IContactInfoDto>>(null);
  const { t } = useTranslation();

  const [showValidationPopup, setShowValidationPopup] = useState(false);
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const meetingService = useService<MeetingService>(MeetingService);
  const legalDocumentStore = useService<LegalDocumentStore>(LegalDocumentStore);

  useEffect(() => {
    legalDocumentStore.ensureDocumentLoaded();
  }, [legalDocumentStore]);

  const findFirstError = (errors: FormikErrors<IContactInfoDto>) => {
    if (errors) {
      const e: any = errors;
      for (const filed of [
        'firstName',
        'lastName',
        'email',
        'phoneNumber',
        'termsAndConditions',
      ]) {
        if (e[filed]) {
          return filed;
        }
      }
    }
    return '';
  };

  function validateEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function validatePhoneNumber(telephone: string | undefined) {
    try {
      return parsePhoneNumber(telephone ?? '', 'NO')?.isValid() ?? false;
    } catch {
      return false;
    }
  }

  const validateName = (name: string | undefined) => {
    var validationResult = validateNameWithResult(name);
    return !validationResult.containsInvalidCharacters;
  };

  const validateIsMeetingBooked = async (
    value: string | undefined
  ): Promise<Yup.ValidationError | boolean> => {
    if (!isAuthenticated && value && validateEmail(value)) {
      const { isAccountCreated, isMeetingBooked } =
        await meetingService.isMeetingBooked(value);

      if (isAccountCreated && isMeetingBooked) {
        return new Yup.ValidationError(
          'account-created-meeting-booked',
          value,
          'email',
          'account-created-meeting-booked'
        );
      }

      if (isAccountCreated) {
        return new Yup.ValidationError(
          'account-created',
          value,
          'email',
          'account-created'
        );
      }

      if (isMeetingBooked) {
        return new Yup.ValidationError(
          'meeting-booked',
          value,
          'email',
          'meeting-booked'
        );
      }

      return true;
    }
    return true;
  };

  const canGoNext = async () => {
    await formRef.current?.submitForm();

    if (!formRef.current?.isValid) {
      setShowValidationPopup(true);
      return false;
    }
    return true;
  };

  const displayEmailError = (error: string): ReactNode => {
    const appState: DashboardRedirectState = {
      restoreBookMeeting: true,
      meetingType,
      date,
      location,
      slot,
    };

    switch (error) {
      case 'account-created-meeting-booked':
        return (
          <>
            {t(
              'meeting.validation.email.accountCreatedMeetingBooked',
              'You have already booked a meeting. Log in and manage your meeting'
            )}
            <br />
            <ButtonComponent
              style={{ marginTop: '14px' }}
              size='SUPER_SMALL'
              onClick={() =>
                loginWithRedirect({
                  login_hint: formRef.current?.values.email,
                })
              }
            >
              {t('userDrawer.login')}
            </ButtonComponent>
          </>
        );
      case 'account-created':
        return (
          <>
            {t('meeting.validation.email.accountCreated')}
            <br />
            <ButtonComponent
              style={{ marginTop: '14px' }}
              size='SUPER_SMALL'
              onClick={() =>
                loginWithRedirect({
                  login_hint: formRef.current?.values.email,
                  appState,
                })
              }
            >
              {t('userDrawer.login')}
            </ButtonComponent>
          </>
        );
      case 'meeting-booked':
        return (
          <>
            {t('meeting.validation.email.meetingBooked')}
            <br />
            <ButtonComponent
              style={{ marginTop: '14px' }}
              size='SUPER_SMALL'
              onClick={() =>
                loginWithRedirect({
                  login_hint: formRef.current?.values.email,
                  screen_hint: 'signup',
                })
              }
            >
              {t('common.createAccount')}
            </ButtonComponent>
          </>
        );
      default:
        return error;
    }
  };

  const renderForm = (formState: FormikProps<IContactInfoDto>) => {
    const errors = formState.errors;
    const firstErrorFiled = findFirstError(errors);
    return (
      <Form className={styles.contactForm}>
        <ContactInfoInput
          inputName='firstName'
          label={t('meeting.contactInfo.firstName', 'First name')}
          error={firstErrorFiled === 'firstName' ? errors.firstName : undefined}
          showValidationPopup={showValidationPopup}
          onCloseValidationPopup={() => setShowValidationPopup(false)}
          validationPopupPosition='bottom'
        />
        <ContactInfoInput
          inputName='lastName'
          label={t('meeting.contactInfo.lastName', 'Last name')}
          error={firstErrorFiled === 'lastName' ? errors.lastName : undefined}
          showValidationPopup={showValidationPopup}
          onCloseValidationPopup={() => setShowValidationPopup(false)}
          validationPopupPosition='bottom'
        />
        <ContactInfoInput
          inputName='email'
          label={t('meeting.contactInfo.email', 'Email')}
          error={firstErrorFiled === 'email' ? errors.email : undefined}
          showValidationPopup={!isAuthenticated && showValidationPopup}
          onCloseValidationPopup={() => setShowValidationPopup(false)}
          validationPopupPosition='bottom'
          inputType='email'
          errorComponent={displayEmailError}
          disabled={isAuthenticated}
        />
        <ContactInfoInput
          inputName='phoneNumber'
          inputType='tel'
          onOverrideChange={(e) => {
            e.target.value = formatPhoneNumber(e.target.value);
            formState.handleChange(e);
          }}
          label={t('meeting.contactInfo.phoneNumber', 'Phone number')}
          error={
            firstErrorFiled === 'phoneNumber' ? errors.phoneNumber : undefined
          }
          showValidationPopup={showValidationPopup}
          onCloseValidationPopup={() => setShowValidationPopup(false)}
          validationPopupPosition='top'
        />
        {!isAuthenticated && (
          <ContactInfoField
            error={
              firstErrorFiled === 'termsAndConditions'
                ? errors.termsAndConditions
                : undefined
            }
            showValidationPopup={showValidationPopup}
            onCloseValidationPopup={() => setShowValidationPopup(false)}
            validationPopupPosition='top'
          >
            <FormikCheckbox
              label={
                <Trans t={t} i18nKey='meeting.contactInfo.termsAndConditions'>
                  I have read
                  <a
                    href={legalDocumentStore.legalDocuments?.privacyPolicyUrl}
                    target='_blank'
                    rel='noreferrer'
                    className={styles.labelLink}
                  >
                    Privacy policy
                  </a>
                  and accepted
                  <a
                    href={
                      legalDocumentStore.legalDocuments?.termsAndConditionsUrl
                    }
                    target='_blank'
                    rel='noreferrer'
                    className={styles.labelLink}
                  >
                    Terms & Conditions
                  </a>
                </Trans>
              }
              type='checkbox'
              name='termsAndConditions'
              theme='DARK'
            />
          </ContactInfoField>
        )}
      </Form>
    );
  };

  return (
    <StepWithNavigation
      canGoNext={canGoNext}
      goBackText={t('meeting.contactInfo.edit')}
      goNextText={t('meeting.contactInfo.confirm')}
    >
      {designer && (
        <DesignerCard
          designer={designer}
          meetingType={meetingType}
          location={location}
          date={date}
          slot={slot}
        />
      )}
      <div className={styles.container}>
        <Formik<IContactInfoDto>
          initialValues={initialContactInfo}
          validateOnChange={false}
          validateOnBlur={false}
          innerRef={formRef}
          validationSchema={Yup.object().shape({
            firstName: Yup.string()
              .required(
                t(
                  'meeting.validation.firstName.required',
                  'First name is required'
                )
              )
              .test(
                'firstNameTest',
                t(
                  'meeting.validation.firstName.invalid',
                  'First name is invalid'
                ),
                validateName
              ),
            lastName: Yup.string()
              .required(
                t(
                  'meeting.validation.lastName.required',
                  'Last name is required'
                )
              )
              .test(
                'lastNameTest',
                t(
                  'meeting.validation.lastName.invalid',
                  'Last name is invalid'
                ),
                validateName
              ),
            email: Yup.string()
              .email(t('meeting.validation.email.invalid'))
              .required(t('meeting.validation.email.required'))
              .test(
                'meetingBooked',
                'meeting.validation.email.isBooked',
                validateIsMeetingBooked
              ),
            phoneNumber: Yup.string()
              .required(t('meeting.validation.phoneNumber.required'))
              .test(
                'phoneNumberTest',
                t('meeting.validation.phoneNumber.invalid'),
                validatePhoneNumber
              ),
            termsAndConditions: !isAuthenticated
              ? Yup.boolean().oneOf(
                  [true],
                  t(
                    'meeting.validation.termsAndConditions.requried',
                    'You have to agree with Terms & Conditions in order to book a meeting.'
                  )
                )
              : Yup.boolean(),
          })}
          onSubmit={(values, actions) => onStepFulfilled(values)}
        >
          {(formState) => renderForm(formState)}
        </Formik>
      </div>
    </StepWithNavigation>
  );
};

export default FillContactInfo;
