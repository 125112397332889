import ComponentWithHeader from '../../common/components/ComponentWithHeader';
import HeaderTitles from '../../common/components/HeaderTitles';
import Project from './Project';
import styles from './ProjectWithHeader.module.css';
import { useContext } from 'react';
import { ProjectContext } from '../ProjectStore';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

const ProjectWithHeader = observer(() => {
  const projectStore = useContext(ProjectContext);
  const { projectId } = useParams<Record<string, string>>();

  const project = projectStore?.getSimpleProjectById(parseInt(projectId));

  return (
    <ComponentWithHeader
      header={
        <HeaderTitles
          classes={{ header: styles.header }}
          title={project?.name ?? ''}
          shortTitle={''}
        />
      }
      component={<Project />}
    />
  );
});

export default ProjectWithHeader;
