import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useAuth0 } from '@auth0/auth0-react';
import { CustomerPushService, useService } from 'shared';
import { ProjectStore } from './project/ProjectStore';
import Intro from './common/components/Intro';
import Routes from './common/components/Routes';
import Footer from './common/components/Footer';
import styles from './AppLoggedUser.module.css';

const AppLoggedUser: FC = observer(() => {
  const { getAccessTokenSilently } = useAuth0();
  const projectStore = useService<ProjectStore>(ProjectStore);
  const customerPushService =
    useService<CustomerPushService>(CustomerPushService);

  useEffect(() => {
    customerPushService.register('CustomerBasicInfoChanged', () => {
      getAccessTokenSilently({ ignoreCache: true });
    });
  }, [customerPushService]);

  return (
    <div className={styles.appContainer}>
      <Intro isVisible={projectStore?.showIntroduction} />
      <Routes />
      <Footer />
    </div>
  );
});

export default AppLoggedUser;
