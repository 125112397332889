import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ActionFailed from '../../../common/components/alerts/ActionFailed';

type OrderLoadFailedProps = {
  onRetry: () => void;
  errorType: 'loadError' | 'scriptError';
};

const OrderLoadFailed: FC<OrderLoadFailedProps> = ({ onRetry, errorType }) => {
  const { t } = useTranslation();

  const description =
    errorType === 'scriptError'
      ? t(
          'project.order.loadErrorDescription',
          'We couldn’t load your order because of technical problems. Please try again'
        )
      : t(
          'project.order.scriptErrorDescription',
          'There was some technincal error during processing your order. Please try again'
        );

  return (
    <ActionFailed
      restartProcess={() => onRetry()}
      restartProcessText={t('project.order.reloadDesign', 'Refresh')}
      textPrimary={t(
        'project.order.loadError',
        'Sorry, we got our wires crossed '
      )}
      textSecondary={description}
    />
  );
};

export default OrderLoadFailed;
