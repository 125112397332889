import { flowResult } from 'mobx';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/lab';
import Designs from '../designs/Designs';
import { useParams } from 'react-router-dom';
import useUserConfirmed from '../../common/hooks/useUserConfirmed';
import { useContext, useEffect } from 'react';
import { ProjectContext } from '../ProjectStore';
import styles from './Project.module.css';
import BackLink from '../../common/components/BackLink';

const Project = () => {
  const { t } = useTranslation();

  const projectStore = useContext(ProjectContext);
  const userConfirmed = useUserConfirmed();
  const { projectId } = useParams<Record<string, string>>();

  useEffect(() => {
    flowResult(projectStore?.ensureProjectLoaded(parseInt(projectId)));
  }, [projectStore, projectId]);

  return (
    <div className={styles.root}>
      {!userConfirmed && (
        <Alert variant='outlined' severity='warning'>
          {t(
            'project.tabs.verifyMailAlert',
            'Verify your email to enable design and order stages'
          )}
        </Alert>
      )}
      <Designs />
      <BackLink
        to={`/?projectId=${projectId}`}
        text={t('common.goToHomePage')}
      />
    </div>
  );
};

export default Project;
