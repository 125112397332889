import styles from './ComponentWithHeader.module.css';
import { FC, ReactNode } from 'react';
import Header from './Header';
import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';

interface ComponentWithHeaderProps {
  header?: ReactNode;
  subHeader?: ReactNode;
  component: ReactNode;
  beforeContent?: ReactNode;
  isHeaderBorder?: boolean;
}

const ComponentWithHeader: FC<ComponentWithHeaderProps> = ({
  header,
  component,
  subHeader,
  beforeContent,
  isHeaderBorder,
}) => {
  const { isAuthenticated } = useAuth0();

  return (
    <section className={styles.container}>
      <Header isHeaderBorder={isHeaderBorder}>{header ? header : ''}</Header>
      {subHeader && <div className={styles.subheader}>{subHeader}</div>}
      {beforeContent && <>{beforeContent}</>}
      <section
        className={classNames(styles.content, {
          [styles.margin]: !isAuthenticated,
        })}
      >
        {component}
      </section>
    </section>
  );
};

export default ComponentWithHeader;
