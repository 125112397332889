import { IconProps } from 'phosphor-react';
import React, { ComponentType, FC, ReactNode } from 'react';
import styles from './IconTextLine.module.css';

type IconTextLineProps = {
  icon: ComponentType<IconProps & React.RefAttributes<SVGSVGElement>>;
  children: ReactNode;
};

const IconTextLine: FC<IconTextLineProps> = ({ icon: Icon, children }) => {
  return (
    <div className={styles.item}>
      <div className={styles.iconBox}>
        <Icon className={styles.icon} size={20} weight='light' />
      </div>
      <div className={styles.description}>{children}</div>
    </div>
  );
};

export default IconTextLine;
