import { observer } from 'mobx-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Payment.module.css';
import CheckoutComponent, { CheckoutComponentProps } from './CheckoutComponent';
import FormikDropdown from '../common/components/formik/FormikDropdown';
import { IPaymentSplitOptionDto } from 'shared';

interface PaymentProps extends CheckoutComponentProps {
  options: IPaymentSplitOptionDto[];
}

const Payment: FC<PaymentProps> = ({ options, ...props }) => {
  const { t } = useTranslation();

  return (
    <CheckoutComponent {...props}>
      <div className={styles.container}>
        <FormikDropdown
          name='paymentSplitId'
          label={t(
            'order.payment.selectSplitOption',
            'How you want to pay for your kitchen?'
          )}
          theme='LIGHT'
          options={options.map((split) => ({
            value: split.id,
            label: t(`order.payment.splitOptions.${split.name}`),
          }))}
        />
      </div>
    </CheckoutComponent>
  );
};

export default observer(Payment);
