import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../buttons/ButtonComponent';
import styles from './ActionButtons.module.css';
import classNames from 'classnames';

interface ActionButtonProps {
  canSubmit: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  onSubmitText?: string;
  onCancelText?: string;
  isDelete?: boolean;
}

const ActionButtons: FC<ActionButtonProps> = ({
  canSubmit,
  onCancel,
  onSubmit,
  onSubmitText,
  onCancelText,
  isDelete = false,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        <ButtonComponent
          variant='OUTLINE'
          size='SMALL'
          colorScheme='LIGHT'
          onClick={onCancel}
        >
          {onCancelText ? onCancelText : t('common.cancel')}
        </ButtonComponent>
        <ButtonComponent
          variant='FILLED_IN'
          size='SMALL'
          onClick={onSubmit}
          disabled={!canSubmit}
          className={classNames({ [styles.delete]: isDelete })}
        >
          {onSubmitText ? onSubmitText : t('common.submit')}
        </ButtonComponent>
      </div>
    </div>
  );
};

export default ActionButtons;
