import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ComponentWithHeader from '../common/components/ComponentWithHeader';
import HeaderTitles from '../common/components/HeaderTitles';
import UserSettings from './UserSettings';
import styles from './UserSettingsWithHeader.module.css';

const UserSettingsWithHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <ComponentWithHeader
      header={
        <HeaderTitles
          classes={{ header: styles.header }}
          title={t('userSettings.title')}
          shortTitle={t('userSettings.title')}
        />
      }
      component={<UserSettings />}
    />
  );
};

export default UserSettingsWithHeader;
