import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderTitles from '../../common/components/HeaderTitles';

const BookMeetingHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <HeaderTitles
      title={t('meeting.details.title')}
      shortTitle={t('meeting.shortTitle')}
    />
  );
};

export default BookMeetingHeader;
