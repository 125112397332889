import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DashboardWithHeader from '../../dashboard/DashboardWithHeader';
import ProjectWithHeader from '../../project/components/ProjectWithHeader';
import UserSettingsWithHeader from '../../user-settings/UserSettingsWithHeader';
import OrderCheckoutWithHeader from '../../order/OrderCheckoutWithHeader';
import OrderSummaryWithHeader from '../../project/order-summary/OrderSummaryWithHeader';
import useUserConfirmed from '../hooks/useUserConfirmed';
import conditionalRoute from '../utils/conditionalRoute';
import BookMeetingWithHeader from '../../meeting/components/BookMeetingWithHeader';

const Routes: FC = () => {
  const userConfirmed = useUserConfirmed();

  return (
    <Switch>
      <Route path='/' exact component={DashboardWithHeader} />
      <Route
        path='/order-checkout/:orderId'
        component={OrderCheckoutWithHeader}
      />
      <Route path='/meeting/:meetingUId?' component={BookMeetingWithHeader} />
      {conditionalRoute(
        userConfirmed,
        '/project/:projectId/order',
        '/',
        OrderSummaryWithHeader
      )}
      {conditionalRoute(
        userConfirmed,
        '/project/:projectId/design',
        '/',
        ProjectWithHeader
      )}
      <Redirect from='/project/:projectId' to='/project/:projectId/design' />
      <Route path='/user-settings' component={UserSettingsWithHeader} />
      <Redirect path='/privacy-policy' to='/user-settings' />
      <Redirect from='/create-account' to='/' />
      <Redirect from='/' to='/' />
    </Switch>
  );
};

export default Routes;
