import { ButtonBase } from '@mui/material';
import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import styles from './IconActionButton.module.css';
import { sizeType } from '../ButtonComponent';

export interface IconActionButtonProps {
  children: ReactNode;
  className?: string;
  size: sizeType;
  classes: {
    base: { [prop: string]: boolean };
  };
}

const IconActionButton: FC<IconActionButtonProps> = ({
  children,
  classes,
  className,
  size = 'BIG',
  ...rest
}) => {
  const containerStyle = classNames(
    styles.container,
    {
      [styles.big]: size === 'BIG',
      [styles.small]: size === 'SMALL',
      [styles.superSmall]: size === 'SUPER_SMALL',
    },
    className
  );

  return (
    <div className={containerStyle}>
      <ButtonBase
        classes={{
          root: classNames(styles.root, classes.base),
          disabled: styles.disabled,
          focusVisible: styles.focus,
        }}
        {...rest}
      >
        {children}
      </ButtonBase>
    </div>
  );
};

export default IconActionButton;
