import classNames from 'classnames';
import { IconProps, Info } from 'phosphor-react';
import React, { ComponentType, FC } from 'react';
import styles from './CardTitle.module.css';

type CardTitleProps = {
  title: string;
  subheader?: string;
  icon?: ComponentType<IconProps & React.RefAttributes<SVGSVGElement>>;
};

const CardTitle: FC<CardTitleProps> = ({ title, icon: Icon, subheader }) => {
  return (
    <div className={styles.container}>
      {Icon && <Icon weight='thin' className={styles.icon} />}
      <h3
        className={classNames(styles.title, styles.center, {
          [styles.hasIcon]: Icon,
        })}
      >
        {title}
      </h3>
      {subheader && (
        <div className={styles.subheader}>
          <Info className={styles.info} />
          <span>{subheader}</span>
        </div>
      )}
    </div>
  );
};

export default CardTitle;
