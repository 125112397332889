import { singleton } from 'tsyringe';

@singleton()
export class StorageService {
  saveData<T>(key: string, data: T): Promise<any> {
    return new Promise((resolve, reject) => {
      localStorage.setItem(key, JSON.stringify(data));
      resolve({});
    });
  }

  getData<T>(key: string): Promise<T | undefined> {
    return new Promise((resolve, reject) => {
      var data = localStorage.getItem(key);
      if (data) {
        resolve(JSON.parse(data) as T);
      }
      resolve(undefined);
    });
  }
}
