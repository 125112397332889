import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LegalDocumentStore } from './LegalDocumentStore';
import ButtonComponent from '../common/components/buttons/ButtonComponent';
import InitialScreen from '../common/components/initial-screen/InitialScreen';
import styles from './PrivacyPolicy.module.css';
import { observer } from 'mobx-react';
import { useService } from 'shared';

const PrivacyPolicy: FC = observer(() => {
  const { t } = useTranslation();

  const legalDocumentStore = useService<LegalDocumentStore>(LegalDocumentStore);

  useEffect(() => {
    legalDocumentStore.ensureDocumentLoaded();
  }, [legalDocumentStore]);

  return (
    <InitialScreen
      title={t('privacyPolicy.title', 'Privacy policy')}
      description={t(
        'privacyPolicy.description',
        'Check out our privacy policy'
      )}
    >
      <div className={styles.container}>
        {legalDocumentStore.legalDocuments && (
          <>
            <a
              href={legalDocumentStore.legalDocuments.privacyPolicyUrl}
              target='_blank'
              rel='noreferrer'
            >
              <ButtonComponent
                variant='TEXT'
                colorScheme='LIGHT'
                size='SUPER_SMALL'
                className={styles.smallButton}
              >
                {t('privacyPolicy.download', 'Download privacy policy')}
              </ButtonComponent>
            </a>
            <a
              href={legalDocumentStore.legalDocuments.termsAndConditionsUrl}
              target='_blank'
              rel='noreferrer'
            >
              <ButtonComponent
                variant='TEXT'
                colorScheme='LIGHT'
                size='SUPER_SMALL'
                className={styles.smallButton}
              >
                {t(
                  'termsAndConditions.download',
                  'Download terms and conditions'
                )}
              </ButtonComponent>
            </a>
          </>
        )}
      </div>
    </InitialScreen>
  );
});

export default PrivacyPolicy;
