import { useAuth0 } from '@auth0/auth0-react';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from './common/components/Loading';

const SignUp: FC = () => {
  const { email } = useParams<Record<string, string | undefined>>();
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({ login_hint: email, screen_hint: 'signup' });
  }, [email]);

  return <Loading />;
};

export default SignUp;
