import React, { FC, ReactNode } from 'react';
import { ButtonBase } from '@mui/material';
import classNames from 'classnames';
import styles from './ActionButton.module.css';
import { colorSchemeType, sizeType } from '../ButtonComponent';

interface ActionButtonProps {
  children: ReactNode;
  size: sizeType;
  colorScheme: colorSchemeType;
  classes: {
    base: { [prop: string]: boolean };
    colorScheme: { [prop: string]: boolean };
    size: { [prop: string]: boolean };
  };
}

const ActionButton: FC<ActionButtonProps> = ({
  children,
  size = 'BIG',
  colorScheme,
  classes,
  ...rest
}) => {
  return (
    <ButtonBase
      classes={{
        root: classNames(
          styles.root,
          { [styles.superSmall]: size === 'SUPER_SMALL' },
          { [styles.dark]: colorScheme === 'DARK' },
          { [styles.light]: colorScheme === 'LIGHT' },
          { [styles.action]: colorScheme === 'ACTION' },
          classes.base,
          classes.colorScheme,
          classes.size
        ),
        disabled: styles.disabled,
        focusVisible: styles.focus,
      }}
      {...rest}
    >
      {children}
    </ButtonBase>
  );
};

export default ActionButton;
