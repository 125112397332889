import { FC } from 'react';
import styles from './DataLead.module.css';

interface DataLeadProps {
  name: string;
  lead: string;
}

const DataLead: FC<DataLeadProps> = ({ name, lead }) => {
  return (
    <div className={styles.root}>
      <span className={styles.name}>{name}</span>
      <span className={styles.lead}>{lead}</span>
    </div>
  );
};

export default DataLead;
