import { FC, useEffect, useState } from 'react';
import {
  useHistory,
  matchPath,
  useParams,
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OrderStore } from './OrderStore';
import { flowResult } from 'mobx';
import Loading from '../common/components/Loading';
import { observer } from 'mobx-react';
import OrderCheckoutFailed from './OrderCheckoutFailed';
import CheckoutContent, { CancelData } from './CheckoutContent';
import OrderConfirmation from './OrderConfirmation';
import { useService } from 'shared';

interface LocationState {
  projectId: number;
}

const OrderCheckout: FC = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<LocationState>();
  const orderStore = useService<OrderStore>(OrderStore);
  const { orderId } = useParams<Record<string, string | undefined>>();

  const [isCancelOrderModalOpen, setIsCancelOrderModalOpen] = useState(false);
  const handleCloseCancelOrderModal = () => setIsCancelOrderModalOpen(false);

  const submitOrder = async () => {
    await flowResult(orderStore.submit(orderId as string));
    if (orderStore.orderSubmittingState === 'submitted') {
      history.push('./confirmation', { projectId: location.state.projectId });
    }
  };

  const restartSubmit = () => {
    orderStore.restartSubmit();
  };

  useEffect(() => {
    const fetchOrderSummary = async (id: string) => {
      if (id) {
        await Promise.all([
          flowResult(orderStore.ensureOrderSummaryLoaded(id)),
          flowResult(orderStore.ensurePaymentSplitOptionsLoaded()),
          flowResult(orderStore.ensureTermsAndConditionsLoaded()),
          flowResult(orderStore.loadLocalData(id)),
          flowResult(orderStore.ensureOrderCancelReasonsLoaded()),
        ]);
      }
    };
    fetchOrderSummary(orderId ?? '');
  }, [orderStore, orderId]);

  const isConfirmationPage = () =>
    matchPath(
      history.location.pathname,
      '/order-checkout/:orderId/confirmation'
    );

  const handleCancelOrdering = async (data: CancelData) => {
    await flowResult(
      orderStore.cancelOrderingProcess({
        cancelDescription: data.cancelDescription ?? '',
        cancelReasonId: data.cancelReasonId,
        deliveryDetails: data.delivery,
        iterationUId: orderId ?? '',
        paymentSplitId: data.paymentSplitId,
      })
    );

    if (orderStore.orderCancelingState === 'canceled') {
      history.push(`/projectId=${location.state.projectId}`);
    }
  };

  return (
    <div>
      {orderStore.orderSubmittingState === 'error' ? (
        <OrderCheckoutFailed restartSubmitOrder={restartSubmit} />
      ) : (
        <Loading
          type='BIG'
          description={t(
            'order.submit.inProgress',
            'Your order is almost there'
          )}
          isLoading={
            orderStore.isLoadingOrderSummary ||
            orderStore.isLoadingPaymentSplitOptions ||
            orderStore.isOrderSubmitting ||
            orderStore.isLoadingOrderCancelReasons
          }
        >
          {isConfirmationPage() ? (
            <OrderConfirmation
              projectId={location.state.projectId}
              file={orderStore.orderSummary?.visualizations?.[0]}
            />
          ) : (
            <CheckoutContent
              onSubmit={submitOrder}
              onCancel={handleCancelOrdering}
              isCancelOrderModalOpen={isCancelOrderModalOpen}
              onCloseCancelOrderModal={handleCloseCancelOrderModal}
              onOpenCancelOrderModal={() => setIsCancelOrderModalOpen(true)}
              projectId={location.state.projectId}
            />
          )}
        </Loading>
      )}
    </div>
  );
});

export default OrderCheckout;
