import React, { FC } from 'react';
import ActionFailed from '../common/components/alerts/ActionFailed';
import { useTranslation } from 'react-i18next';

type ProfileLoadFailedProps = {
  onRetry: () => void;
};

const ProfileLoadFailed: FC<ProfileLoadFailedProps> = ({ onRetry }) => {
  const { t } = useTranslation();

  return (
    <ActionFailed
      restartProcess={() => onRetry()}
      restartProcessText={t('common.refresh', 'Refresh')}
      textPrimary={t(
        'profile.userSettings.profile.loadError',
        "Sorry, we couldn't load your profile "
      )}
      textSecondary={t(
        'appError.description',
        'We are having some technical difficulties at our end. Please try again later.'
      )}
    />
  );
};

export default ProfileLoadFailed;
