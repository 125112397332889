import { FC, useContext, useEffect } from 'react';
import styles from './BookMeetingPage.module.css';
import { ProjectContext, ProjectStore } from '../../../project/ProjectStore';
import { useDisplayComponent } from '../../../common/hooks/useDisplayComponent';
import BookMeeting from '../BookMeeting';
import DesignerCard from '../DesignerCard/DesignerCard';
import moment from 'moment';
import Loading from '../../../common/components/Loading';
import { observer } from 'mobx-react';
import { flowResult } from 'mobx';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ContactInfo from './components/ContactInfo';
import MeetingInfo from './components/MeetingInfo';
import { useHistory } from 'react-router';

interface BookMeetingPageProps {}

const BookMeetingPage: FC<BookMeetingPageProps> = observer(() => {
  const projectStore = useContext(ProjectContext);
  const { meetingUId: uIdFromParams } =
    useParams<Record<string, string | undefined>>();
  const [isBookingDisplayed, displayBooking] = useDisplayComponent();
  const { isAuthenticated } = useAuth0();
  const history = useHistory();

  const { bookMeetingStore, projectMeeting } = projectStore as ProjectStore;
  const { designersStore, selectTime, chooseLocation } = bookMeetingStore;

  const meetingUId = uIdFromParams || projectMeeting?.uId;

  useEffect(() => {
    if (meetingUId) {
      flowResult(bookMeetingStore.loadMeeting(meetingUId));
      return;
    }

    if (isAuthenticated) {
      history.push('/');
    } else {
      displayBooking();
    }
  }, [bookMeetingStore, projectMeeting?.uId, uIdFromParams, isAuthenticated]);

  return (
    <div className={styles.root}>
      <Loading
        type='BIG'
        verticalPosition='center'
        isLoading={
          meetingUId ? bookMeetingStore.isMeetingLoaded !== 'loaded' : true
        }
      >
        {designersStore.selectedDesigner && selectTime.selectedSlot && (
          <DesignerCard
            designer={designersStore.selectedDesigner!}
            slot={selectTime.selectedSlot!}
            meetingType={chooseLocation.selectedMeeting!}
            date={moment(selectTime.selectedSlot?.start.clone().startOf('day'))}
            location={chooseLocation.selectedLocation}
            onReschedule={displayBooking}
          />
        )}
        <MeetingInfo chooseLocationStore={chooseLocation} />
        {isAuthenticated && (
          <ContactInfo contactInfo={bookMeetingStore.contactInfo} />
        )}
      </Loading>

      {isBookingDisplayed && <BookMeeting isReschedule={!!meetingUId} />}
    </div>
  );
});

export default BookMeetingPage;
