import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormikCheckbox from '../common/components/formik/FormikCheckbox';
import CheckoutComponent from './CheckoutComponent';
import styles from './FinalAgreement.module.css';

type FinalAgreementProps = {
  termsAndConditionsUrl?: string;
};

const FinalAgreement: FC<FinalAgreementProps> = ({ termsAndConditionsUrl }) => {
  const { t } = useTranslation();

  return (
    <CheckoutComponent
      title={t('order.finalAgreement.title', 'Final agreement')}
    >
      <div className={styles.container}>
        <FormikCheckbox
          type='checkbox'
          label={
            <div className={styles.label}>
              {`${t(
                'order.finalAgreement.readAndAcceptContract',
                'I have read the contract and accept its'
              )} `}
              <a href={termsAndConditionsUrl} target='_blank' rel='noreferrer'>
                <span className={styles.termsAndConditions}>
                  {t(
                    'order.finalAgreement.termsConditions',
                    'Terms & Conditions'
                  )}
                </span>
              </a>
            </div>
          }
          name={'finalAgreement'}
          theme='LIGHT'
        />
      </div>
    </CheckoutComponent>
  );
};

export default FinalAgreement;
