import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './Footer.module.css';

const Footer: FC = () => {
  const { t } = useTranslation();

  return (
    <footer className={styles.footer}>
      &copy;&ensp;{t('footer.copyright')}&ensp;|&ensp;
      <NavLink to='/privacy-policy'>
        {t('footer.privacyPolicy', 'Privacy policy')}
      </NavLink>
    </footer>
  );
};

export default Footer;
