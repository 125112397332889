import { useEffect, useState } from 'react';

export function useDisplayComponent(): [boolean | undefined, () => void] {
  const [isDisplayed, setIsDisplayed] = useState<boolean>();

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isDisplayed === false)
      timeout = setTimeout(() => setIsDisplayed(true), 0);

    return () => {
      if (timeout) window.clearTimeout(timeout);
    };
  }, [isDisplayed]);

  const display = () => {
    setIsDisplayed(false);
  };

  return [isDisplayed, display];
}
