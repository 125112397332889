import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';
import { OrderService, IOrderDto, Logger } from 'shared';

@singleton()
export class OrderSummaryStore {
  constructor(private orderService: OrderService, private logger: Logger) {
    makeAutoObservable(this);
  }

  orderDto?: IOrderDto;

  loadingState: 'not-initialized' | 'loading' | 'loaded' | 'error' =
    'not-initialized';

  *loadOrderSummary(projectId?: number) {
    if (!projectId) {
      return;
    }

    try {
      this.loadingState = 'loading';
      this.orderDto = yield this.orderService.getSummaryByProject(projectId);
      this.loadingState = 'loaded';
    } catch (error) {
      this.logger.logException(error);
      this.loadingState = 'error';
    }
  }
}
