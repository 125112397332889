import { FC, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styles from './Intro.module.css';
import Loading from './Loading';

interface IntroProps {
  isVisible?: boolean;
  duration?: number;
}

const Intro: FC<IntroProps> = ({ isVisible, duration = 2000 }) => {
  const [isAnimating, setIsAnimating] = useState(true);

  useEffect(() => {
    const animationTimeout = setTimeout(() => setIsAnimating(false), duration);
    return () => clearTimeout(animationTimeout);
  }, []);

  return (
    <AnimatePresence>
      {(isVisible || isAnimating) && (
        <motion.div
          key='container'
          className={styles.container}
          animate={{ opacity: 1 }}
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Loading />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Intro;
