import _ from 'lodash';

export function mergeMUIClasses(
  classesOne: Record<string, string> | undefined,
  classesTwo: Record<string, string> | undefined
) {
  return _.mergeWith(
    classesOne ?? {},
    classesTwo ?? {},
    (objVal, srcVal) => `${objVal ?? ''} ${srcVal ?? ''}`
  );
}
