import { CalendarPicker, PickersDayProps } from '@mui/lab';
import { IconButton, Paper } from '@mui/material';
import { createTheme, ThemeOptions, adaptV4Theme } from '@mui/material/styles';
import { withStyles, WithStyles } from '@mui/styles';
import { createStyles } from '@mui/styles';
import { ThemeProvider } from '@mui/material/styles';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import { FC } from 'react';
import 'moment/min/locales';
import { customProperties } from '../../../assets/styles/variables';
import DatePickerTheme from '../../../common/components/pickers/DatePickerTheme';
import { merge } from 'lodash';
import { areDatesEqual } from 'shared';

interface DatePickerProps extends WithStyles<typeof styles> {
  date: Moment;
  onChangeDate: (
    date: Moment | null,
    selectionState?: 'partial' | 'shallow' | 'finish'
  ) => void;
}

const DatePicker: FC<DatePickerProps> = ({ date, onChangeDate, classes }) => {
  const renderWrappedWeekDay = (
    date: Moment | null,
    selectedDate: (Moment | null)[],
    pickersDayProps: PickersDayProps<Moment>
  ) => {
    const temp = selectedDate[0];

    const dateClone = date?.clone();
    const selectedDateClone = temp?.clone();

    const start = temp?.startOf('week').startOf('day');
    const end = selectedDateClone?.endOf('week').startOf('day');

    const dayIsBetween = dateClone ? dateClone.isBetween(start, end) : false;
    const isFirstDay =
      dateClone && start ? areDatesEqual(dateClone, start) : false;
    const isLastDay = dateClone && end ? areDatesEqual(dateClone, end) : false;
    const isPreviousDate = dateClone
      ?.startOf('day')
      .isBefore(moment().startOf('day'));

    return (
      <div
        key={date?.valueOf()}
        className={classNames({
          [classes.highlight]: dayIsBetween || isFirstDay || isLastDay,
          [classes.firstHighlight]: isFirstDay,
          [classes.endHighlight]: isLastDay,
        })}
      >
        <IconButton
          className={classNames(classes.day, {
            [classes.disabled]:
              pickersDayProps.outsideCurrentMonth || isPreviousDate,
          })}
          onClick={() => onChangeDate(date, 'finish')}
        >
          <span> {dateClone?.format('D')} </span>
        </IconButton>
      </div>
    );
  };

  const materialTheme = createTheme(
    adaptV4Theme(
      merge(DatePickerTheme as ThemeOptions, {
        overrides: {
          MuiTypography: {
            body1: {
              fontFamily: customProperties['--font-atlas'],
              fontSize: '14px',
              fontWeight: 'bold',
            },
          },
          MuiPaper: {
            elevation1: {
              backgroundColor: customProperties['--color-white'],
              boxShadow: '0 40px 20px rgba(0,0,0,0.15)',
              border: '1px solid #000',
              borderRadius: '4px',
              padding: '0 0',
              minHeight: 'auto',
            },
          },
          MuiPickersCalendarHeader: {
            iconButton: {
              backgroundColor: customProperties['--color-primary'],
            },
            daysHeader: {
              justifyContent: 'center',
            },
          },
          MuiPickersCalendar: {
            week: {
              justifyContent: 'center',
            },
          },
        },
      })
    )
  );

  return (
    <ThemeProvider theme={materialTheme}>
      <Paper>
        <CalendarPicker
          date={date}
          onChange={onChangeDate}
          disablePast={true}
          renderDay={renderWrappedWeekDay}
        />
      </Paper>
    </ThemeProvider>
  );
};

const styles = () =>
  createStyles({
    dayWrapper: {
      position: 'relative',
    },
    day: {
      width: 36,
      height: 36,
      fontSize: '12px',
      margin: '0 2px',
      color: customProperties['--color-primary'],
      fontFamily: customProperties['--font-atlas'],
      opacity: 1,
      '&:hover': {
        color: customProperties['--color-primary'],
      },
    },
    disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    highlight: {
      background: customProperties['--color-action-background'],
      color: customProperties['--color-alternate-text'],
    },
    firstHighlight: {
      extend: 'highlight',
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    },
    endHighlight: {
      extend: 'highlight',
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    },
  });

export default withStyles(styles)(DatePicker);
