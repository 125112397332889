import { useAuth0 } from '@auth0/auth0-react';
import { errorMessage } from '../../verify-email/ErrorMessage';

export const useIsUserUnverified = () => {
  const { error } = useAuth0();

  return {
    isUserUnverified: error?.message
      .toLowerCase()
      .includes(errorMessage.toLocaleLowerCase()),
  };
};
