import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Popper } from '@mui/material';
import CookieConsent from 'react-cookie-consent';
import ButtonComponent from '../buttons/ButtonComponent';
import styles from './CookiePopup.module.css';

const AcceptButton: FC = (props) => {
  const { t } = useTranslation();

  return (
    <ButtonComponent colorScheme='DARK' size='SUPER_SMALL' {...props}>
      {t('cookieConsent.acceptLabel', 'Got it!')}
    </ButtonComponent>
  );
};

const CookiePopup: FC = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [bodyRef, setBodyRef] = useState<HTMLBodyElement | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!bodyRef) {
      const elemRef = document.querySelector('body');
      setBodyRef(elemRef);
    }
  }, [bodyRef]);

  const onCookieConsentClose = () => {
    setIsOpen(false);
  };

  return bodyRef ? (
    <Popper
      anchorEl={bodyRef}
      open={isOpen}
      placement='right-end'
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: ({ placement, reference, popper }: any) => {
              if (placement === 'right-end') {
                return [-15, -popper.width - 15];
              } else {
                return [];
              }
            },
          },
        },
        {
          name: 'preventOverflow',
          options: {
            rootBoundary: 'viewport',
          },
        },
      ]}
      className={styles.container}
    >
      <CookieConsent
        disableStyles={true}
        containerClasses={styles.innerContainer}
        contentClasses={styles.outerContent}
        buttonWrapperClasses={styles.buttonWrapper}
        ButtonComponent={AcceptButton}
        cookieName='CookieConsentAllowed'
        expires={150}
        onAccept={onCookieConsentClose}
      >
        <h2 className={styles.header}>
          {t('cookieConsent.header', 'This website uses cookies')}
        </h2>
        <p className={styles.content}>
          {t(
            'cookieConsent.content',
            'to ensure you get the best experience on our website.'
          )}
        </p>
        <NavLink to='/privacy-policy' className={styles.link}>
          {t('cookieConsent.linkLabel', 'Learn more')}
        </NavLink>
      </CookieConsent>
    </Popper>
  ) : (
    <></>
  );
};

export default CookiePopup;
