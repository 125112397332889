module.exports = {
  customProperties: {
    '--color-primary': '#333333',
    '--color-primary-black': '#292a2c',
    '--color-secondary': '#f5eee6',
    '--color-primary-background': '#ffffff',
    '--color-secondary-background': '#F5F5F5',
    '--color-action-background': '#B7E1FF',
    '--color-action-text': '#333333',
    '--color-secondary-text': '#f5eee6',
    '--color-alternate-text': '#2f3338',
    '--color-complemantary-background': '#d9d9d1',
    '--color-overlay-dark': '#121212',
    '--color-selected-item-light': '#eee',
    '--color-light-black': '#030303',
    '--color-error-red': '#e04040',
    '--color-success-green': '#43a252',
    '--color-action-button-disabled': '#D9DCDE',
    '--color-success-background': '#FBFEFB',
    '--color-accordion-separator-light': '#E2E0DD',
    '--color-accordion-separator-dark': '#464645',
    '--color-information': '#1F73B7',

    '--color-black': '#000000',
    '--color-white': '#ffffff',
    '--color-beige': '#f9f4f2',

    '--color-light-blue': '#b7e1ff',
    '--color-middle-blue': '#6cb8e6',
    '--color-main-blue': '#0e4d94',

    '--color-complementary-purple': '#623e9c',
    '--color-complementary-green': '#58d262',
    '--color-complementary-red': '#fc3c3c',

    '--color-gray-500': '#595959',
    '--color-gray-400': '#979292',
    '--color-gray-300': '#c4c4c4',
    '--color-gray-200': '#d8d8d8',
    '--color-gray-100': '#f5f5f5',

    '--color-primary-rgb': '51, 51, 51',
    '--color-primary-black-rgb': '41, 42, 44',
    '--color-primary-background-rgb': '255, 255, 255',
    '--color-secondary-rgb': '245, 238, 230',
    '--color-gray-100-rgb': '245, 245, 245',
    '--color-gray-500-rgb': '89, 89, 89',
    '--color-complemantary-background-rgb': '217, 217, 209',
    '--color-black-rgb': '0, 0, 0',
    '--color-white-rgb': '255, 255, 255',
    '--color-light-gray-rgb': '151, 146, 146',
    '--color-light-black-rgb': '3, 3, 3',

    '--font-atlas': 'AtlasGroteskWeb',
    '--font-arizona': 'ArizonaFlare',
    '--disabled-opacity': 0.2,

    '--sm-width-max': '767px',
    '--md-width-min': '768px',
    '--md-width-max': '1023px',
    '--l-width-min': '1024px',
    '--l-width-max': '1439px',
    '--xl-width-min': '1440px',
    '--sider-sm-width': '0px',
    '--sider-md-width': '154px',
    '--sider-l-width': '154px',
    '--sider-xl-width': '210px',

    '--narrow-width': '645px',

    '--app-containrt-sm-horizontal-space': '15px',
    '--app-containrt-md-horizontal-space': '20px',
    '--app-containrt-xl-horizontal-space': '30px',
    '--app-containrt-l-horizontal-space': '20px',
    '--header-sm-top-margin': '0px',
    '--header-sm-top-left': '15px',
    '--header-md-top-margin': '40px',
    '--header-l-top-margin': '40px',
    '--header-xl-top-margin': '40px',
    '--header-action-button-separator': '15px',
    '--project-outline-input-separator': '20px',

    '--header-height-sm': '64px',
    '--header-height-md': '100px',

    '--modal-small-max-width': '820px',
    '--modal-small-min-height': '420px',
    '--modal-large-max-width': '1170px',
    '--modal-large-max-height': '668px',

    '--rounded-corner-radius': '10px',

    '--zindex-intro': '1210',

    '--padding-0': '0px',
    '--padding-8': '8px',
    '--padding-16': '16px',
    '--padding-24': '24px',
    '--padding-48': '48px',

    '--default-border-width': '1px',
    '--active-border-width': '2px',

    '--box-size-24': 24,

    '--padding-sm': '8px',
    '--padding-md': '16px',
    '--padding-lg': '24px',

    '--default-border-radius': '4px',

    '--box-shadow-light': '3px 4px 20px 1px rgba(0, 0, 0, 0.15)',
  },
};
