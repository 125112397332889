import { FC, ReactNode } from 'react';
import Intro from '../Intro';
import styles from './InitialScreen.module.css';
import CookiePopup from '../cookie-popup/CookiePopup';

interface InitialScreenProps {
  title: string;
  description: string | ReactNode;
}

const InitialScreen: FC<InitialScreenProps> = ({
  title,
  description,
  children,
}) => {
  return (
    <div className={styles.container}>
      <Intro />
      <div className={styles.actionPanel}>
        <div className={styles.header}>
          <h1 className={styles.title}>{title}</h1>
          <div className={styles.separator}></div>
          <p className={styles.description} role='doc-subtitle'>
            {description}
          </p>
        </div>
        {children}
      </div>
      <CookiePopup />
    </div>
  );
};

export default InitialScreen;
