import { FC, ReactNode, useState } from 'react';
import Modal from '@mui/material/Modal';
import styles from './ModalWrapper.module.css';
import classNames from 'classnames';
import { Backdrop, Fade } from '@mui/material';
import { X, ArrowLeft } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import CloseModal, {
  CloseModalProps,
} from '../../../project/components/CloseModal';
import ButtonComponent from '../buttons/ButtonComponent';

type sizeVariantType = 'SMALL' | 'LARGE' | 'COVER';
type closeBtnVariant = 'NONE' | 'DEFAULT' | 'ARROW';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  confirmCancel?: CloseModalProps;
  sizeVariant?: sizeVariantType;
  className?: string;
  title?: string;
  closeVariant?: closeBtnVariant;
}

const ModalWrapper: FC<ModalProps> = ({
  open,
  onClose,
  children,
  confirmCancel,
  sizeVariant = 'LARGE',
  className,
  title,
  closeVariant = 'DEFAULT',
}) => {
  const { t } = useTranslation();
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const transitionDuration = 500;

  const openConfirmDialog = () => {
    if (confirmCancel) {
      setConfirmDialogOpen(true);
    } else {
      onClose();
    }
  };

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const handleClose = () => {
    onClose();
  };

  const renderCloseButton = () => {
    if (closeVariant === 'NONE') {
      return null;
    }

    return confirmCancel && isConfirmDialogOpen ? (
      <div className={styles.confirmDialog}>
        <CloseModal
          {...confirmCancel}
          onBack={closeConfirmDialog}
          onConfirm={handleClose}
        />
      </div>
    ) : (
      <div className={styles.closeWrapper}>
        {title || closeVariant === 'ARROW' ? (
          <ButtonComponent
            variant={'TEXT'}
            colorScheme={'DARK'}
            onClick={openConfirmDialog}
            className={styles.closeTitle}
          >
            <ArrowLeft size={24} />
            {title}
          </ButtonComponent>
        ) : (
          <ButtonComponent
            variant='ACTION'
            colorScheme='ACTION'
            title={t('common.cancel')}
            className={styles.closeButton}
            onClick={openConfirmDialog}
          >
            <X />
          </ButtonComponent>
        )}
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onClose={confirmCancel ? openConfirmDialog : handleClose}
      closeAfterTransition={true}
      BackdropComponent={Backdrop}
      BackdropProps={{
        transitionDuration,
        classes: {
          root: styles.backdrop,
        },
      }}
      className={classNames(styles.modal, {
        [styles.modalCover]: sizeVariant === 'COVER',
      })}
    >
      <Fade in={open} timeout={transitionDuration}>
        <div
          className={classNames(
            styles.wrapper,
            {
              [styles.sizeSmall]: sizeVariant === 'SMALL',
              [styles.sizeLarge]: sizeVariant === 'LARGE',
              [styles.sizeCover]: sizeVariant === 'COVER',
            },
            className
          )}
        >
          {renderCloseButton()}
          <div className={styles.content}>{children}</div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalWrapper;
