import {
  FormControl,
  FormControlClassKey,
  FormHelperText,
  MenuItem,
  TextField,
} from '@mui/material';
import classNames from 'classnames';
import { CaretDown } from 'phosphor-react';
import { FC } from 'react';
import { OptionType } from '../dropdowns/DropdownComponent';
import { themeType } from './InputField';
import styles from './InputBase.module.css';
import { ClassNameMap } from '@mui/styles/withStyles';
import { mergeMUIClasses } from '../../utils/mergeMUIClasses';

interface InputBaseProps {
  name: string;
  label: string;
  theme: themeType;
  multiline: boolean;
  select: boolean;
  options?: OptionType[];
  value?: string | number;
  required?: boolean;
  error?: string;
  description?: string;
  formControlClasses?: Partial<ClassNameMap<FormControlClassKey>>;
}

const InputBase: FC<InputBaseProps> = ({
  name,
  label,
  theme,
  multiline,
  select,
  options,
  required,
  error,
  description,
  formControlClasses,
  ...rest
}) => {
  const themeClasses = {
    [styles.light]: theme === 'LIGHT',
    [styles.dark]: theme === 'DARK',
  };

  return (
    <FormControl
      classes={mergeMUIClasses({ root: styles.container }, formControlClasses)}
      error={!!error}
      required={required}
    >
      <TextField
        id={name}
        name={name}
        multiline={multiline}
        select={select}
        inputProps={{ ...rest }}
        aria-describedby={'describe-' + name}
        aria-label={label}
        label={label}
        variant='standard'
        SelectProps={{
          IconComponent: CaretDown,
          classes: {
            icon: classNames(styles.icon, themeClasses),
            iconOpen: styles.iconOpen,
          },
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            PopoverClasses: {
              paper: classNames(styles.paper, themeClasses),
            },
          },
        }}
        InputLabelProps={{
          error: !!error,
          classes: {
            root: classNames(styles.label, themeClasses),
            shrink: styles.labelShrink,
            animated: styles.labelAnimated,
            error: styles.labelError,
            focused: styles.labelFocused,
          },
        }}
        InputProps={{
          error: !!error,
          classes: {
            input: classNames(styles.input, themeClasses),
            underline: classNames(styles.inputUnderline, themeClasses),
            focused: classNames(styles.inputFocused, themeClasses),
            error: styles.inputError,
            disabled: styles.inputDisabled,
          },
        }}
      >
        {select &&
          options &&
          options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              classes={{
                root: classNames(styles.menuItem, themeClasses),
              }}
            >
              {option.label}
            </MenuItem>
          ))}
      </TextField>
      <FormHelperText
        id={'describe-' + name}
        classes={{
          root: classNames(styles.helper, themeClasses),
        }}
      >
        {description}
      </FormHelperText>
      {error && error.length && (
        <FormHelperText
          id={'describe-' + name + '-error'}
          classes={{
            root: styles.helper,
            error: styles.helperError,
          }}
        >
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default InputBase;
