import parsePhoneNumberFromString, { AsYouType } from 'libphonenumber-js';

export function formatPhoneNumber(input: string) {
  if (input) {
    const phoneNumber = parsePhoneNumberFromString(input);

    if (phoneNumber && phoneNumber?.isValid) {
      return new AsYouType().input(input);
    }
  }
  return input;
}
