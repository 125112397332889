import { useContext } from 'react';
import { ProjectContext, ProjectStore } from './project/ProjectStore';
import AppNotLoggedUser from './AppNotLoggedUser';
import { ContainerContext, CustomerService, Logger, useService } from 'shared';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './common/components/Loading';
import { useEffect } from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import { useLanguages } from './common/hooks/useLanguages';

import AppLoggedUserLayout from './AppLoggedUserLayout';

function App() {
  const { isLoading, isAuthenticated } = useAuth0();
  const { changeLanguage } = useLanguages();

  const container = useContext(ContainerContext);
  const customerService = useService<CustomerService>(CustomerService);
  const logger = useService<Logger>(Logger);

  useEffect(() => {
    const getCustomerLanguage = async () => {
      try {
        const language = await customerService.getMyLanguage();
        if (language?.name) {
          changeLanguage(language.name);
        }
      } catch (error) {
        logger.logException(error);
      }
    };

    if (!isLoading && isAuthenticated) {
      getCustomerLanguage();
    }
  }, [isLoading, isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight / 100;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      document.documentElement.style.setProperty(
        '--100vh',
        `${window.innerHeight}px`
      );
    };

    window.addEventListener('resize', setVh);

    setVh();

    return () => {
      window.removeEventListener('resize', setVh);
    };
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ProjectContext.Provider
        value={container.resolve<ProjectStore>(ProjectStore)}
      >
        <Loading isLoading={isLoading}>
          {isAuthenticated ? <AppLoggedUserLayout /> : <AppNotLoggedUser />}
        </Loading>
      </ProjectContext.Provider>
    </StyledEngineProvider>
  );
}

export default App;
