import { FC, useState } from 'react';
import UserSectionTitle from './UserSectionTitle';
import styles from './Account.module.css';
import ModalWrapper from '../common/components/modals/ModalWrapper';
import DeleteAccount from './DeleteAccount';
import ButtonComponent from '../common/components/buttons/ButtonComponent';
import { X } from 'phosphor-react';
import { flowResult } from 'mobx';
import { useAuth0 } from '@auth0/auth0-react';
import { useService } from 'shared';
import { UserSettingsStore } from './UserSettingsStore';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

interface AccountProps {}

const Account: FC<AccountProps> = observer(() => {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const userSettingsStore = useService<UserSettingsStore>(UserSettingsStore);

  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] =
    useState<boolean>(false);

  const handleCloseDeleteAccountModal = () =>
    setIsDeleteAccountModalOpen(false);
  const handleDeleteAccount = async () => {
    await flowResult(userSettingsStore.deleteAccount());
    handleCloseDeleteAccountModal();
    logout();
  };

  return (
    <section className={styles.account}>
      {
        <ModalWrapper
          open={isDeleteAccountModalOpen}
          onClose={handleCloseDeleteAccountModal}
          sizeVariant='SMALL'
        >
          <DeleteAccount
            onClose={handleCloseDeleteAccountModal}
            onSubmit={handleDeleteAccount}
          />
        </ModalWrapper>
      }
      <UserSectionTitle
        title={t('userSettings.privacy.account.title', 'Account')}
      />
      <ButtonComponent
        variant='OUTLINE'
        size='SMALL'
        onClick={() => setIsDeleteAccountModalOpen(true)}
        className={styles.accountButton}
        colorScheme='LIGHT'
      >
        <X className={styles.icon} />
        {t('userSettings.privacy.account.delete.menuTitle', 'Delete account')}
      </ButtonComponent>
    </section>
  );
});

export default Account;
