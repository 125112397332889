import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionFailed from './ActionFailed';
import styles from './AppFailed.module.css';

const AppFailed = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <ActionFailed
          restartProcess={() => window.location.reload()}
          restartProcessText={t('common.refresh', 'Refresh')}
          textPrimary={t('appError.title', 'Sorry, we got our wires crossed.')}
          textSecondary={t(
            'appError.description',
            'We are having some technical difficulties at our end. Please try again later.'
          )}
        />
      </div>
    </div>
  );
};

export default AppFailed;
