interface NameValidationResult {
  isValid: boolean;
  isEmpty: boolean;
  containsInvalidCharacters: boolean;
}

export const validateName = (
  name: string | undefined
): NameValidationResult => {
  if (!name)
    return { isValid: false, isEmpty: true, containsInvalidCharacters: false };
  const re = /^[\p{L} \.'\-]+$/gu;
  if (!re.test(name)) {
    return { isValid: false, isEmpty: false, containsInvalidCharacters: true };
  }
  return { isValid: true, isEmpty: false, containsInvalidCharacters: false };
};
