import React, { FC, ReactNode } from 'react';
import { ButtonBase } from '@mui/material';
import classNames from 'classnames';

interface FilledInButtonProps {
  children: ReactNode;
  classes: {
    base: { [prop: string]: boolean };
    colorScheme: { [prop: string]: boolean };
    size: { [prop: string]: boolean };
  };
}

const FilledInButton: FC<FilledInButtonProps> = ({
  children,
  classes,
  ...rest
}) => {
  return (
    <ButtonBase
      classes={{
        root: classNames(classes.base, classes.colorScheme, classes.size),
      }}
      {...rest}
    >
      {children}
    </ButtonBase>
  );
};

export default FilledInButton;
