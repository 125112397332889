import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CaretRight } from 'phosphor-react';
import { NavLink } from 'react-router-dom';
import ButtonComponent from './buttons/ButtonComponent';
import styles from './ProfileMenu.module.css';
import { useAuth0 } from '@auth0/auth0-react';

interface ProfileMenuProps {
  onClose: () => void;
}

const ProfileMenu: FC<ProfileMenuProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  return (
    <div className={styles.container}>
      <NavLink
        to='/user-settings/profile'
        className={styles.profileButton}
        onClick={onClose}
      >
        <div className={styles.profile}>
          {t('userDrawer.editProfile', 'Edit profile')} <CaretRight />
        </div>
      </NavLink>
      <div className={styles.logout}>
        <ButtonComponent
          size='SMALL'
          variant='TEXT'
          color='DARK'
          onClick={() => logout()}
          className={styles.logoutButton}
        >
          {t('userDrawer.logout')}
        </ButtonComponent>
      </div>
    </div>
  );
};

export default ProfileMenu;
