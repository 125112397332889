import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import OrderSection from '../visual/OrderSection';
import OrderSectionGrid from '../visual/OrderSectionGrid';
import OrderSectionContent from '../visual/OrderSectionContent';
import OrderSectionParagraph from '../visual/OrderSectionParagraph';
import OrderSectionTitle from '../visual/OrderSectionTitle';
import OrderInvoice from './OrderInvoice';
import EmptyInvoice from './EmptyInvoice';
import { IFileDto, IInvoiceDto } from 'shared';
import { saveAs } from 'file-saver';

interface OrderInvoiceSectionProps {
  invoices?: IInvoiceDto[];
}

const OrderInvoiceSection: FC<OrderInvoiceSectionProps> = ({ invoices }) => {
  const { t } = useTranslation();
  const handleDownload = (file: IFileDto) => {
    saveAs(file.uri, file.fileId.fileName);
  };

  return (
    <OrderSection>
      <OrderSectionTitle title={t('project.order.invoice.title', 'Invoice')} />
      <OrderSectionGrid>
        <OrderSectionContent>
          <OrderSectionParagraph>
            {invoices && !!invoices.length
              ? t('project.order.invoice.description', 'Here are your invoices')
              : t(
                  'project.order.invoice.descriptionEmpty',
                  'Here you’ll find your invoice. We will notify you when it’s ready (ususally takes up to 24h).'
                )}
          </OrderSectionParagraph>
        </OrderSectionContent>
        <OrderSectionContent>
          {invoices && !!invoices.length ? (
            invoices.map((x, index) => (
              <OrderInvoice
                key={index}
                handleDownload={() => handleDownload(x.file)}
                {...x}
              />
            ))
          ) : (
            <EmptyInvoice />
          )}
        </OrderSectionContent>
      </OrderSectionGrid>
    </OrderSection>
  );
};

export default OrderInvoiceSection;
