import classNames from 'classnames';
import { Moment } from 'moment';
import { FC } from 'react';
import styles from './Day.module.css';

interface DayProps {
  date: Moment;
  numberOfSlots: number;
  onSelect: () => void;
  isSelected: boolean;
}

const Day: FC<DayProps> = ({ date, numberOfSlots, isSelected, onSelect }) => {
  return (
    <div className={styles.item}>
      <span
        className={classNames(styles.dayName, {
          [styles.withSlots]: numberOfSlots > 0,
        })}
      >
        {date.format('dddd').substring(0, 3)}
      </span>
      <div
        className={classNames(styles.oval, {
          [styles.withSlots]: numberOfSlots > 0,
          [styles.selected]: isSelected,
        })}
        onClick={onSelect}
      >
        <span className={styles.ovalContent}>{date.format('DD')}</span>
      </div>
    </div>
  );
};

export default Day;
