import { FC } from 'react';
import style from './UserSectionTitle.module.css';

interface UserSectionTitleProps {
  title: string;
}

const UserSectionTitle: FC<UserSectionTitleProps> = ({ title }) => {
  return <h4 className={style.title}>{title}</h4>;
};

export default UserSectionTitle;
