import React, { FC } from 'react';
import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react';
import {
  customerPortalContainer,
  tokenProviderKey,
} from './customerPortalContainer';
import { ContainerContext } from 'shared';
import { ITokenProvider, TokenRequest, TokenResponse } from 'shared';

type ContainerProviderProps = {
  children: React.ReactNode;
};

class Auth0TokenProvider implements ITokenProvider {
  constructor(
    private getAccessTokenSilently: (
      options?: GetTokenSilentlyOptions
    ) => Promise<string>,
    private isAuth: boolean
  ) {}

  setIsAuthenticated(isAuth: boolean) {
    this.isAuth = isAuth;
  }

  isAuthenticated(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      resolve(this.isAuth);
    });
  }

  getToken(tokenRequest: TokenRequest): Promise<TokenResponse> {
    return this.getAccessTokenSilently(tokenRequest).then((accessToken) => ({
      accessToken,
      expiresOn: undefined,
    }));
  }
}

const ContainerProvider: FC<ContainerProviderProps> = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  if (!customerPortalContainer.isRegistered(tokenProviderKey)) {
    customerPortalContainer.registerInstance(
      tokenProviderKey,
      new Auth0TokenProvider(getAccessTokenSilently, isAuthenticated)
    );
  } else {
    const tokenProvider = customerPortalContainer.resolve(
      tokenProviderKey
    ) as Auth0TokenProvider;
    tokenProvider.setIsAuthenticated(isAuthenticated);
  }

  return (
    <ContainerContext.Provider value={customerPortalContainer}>
      {children}
    </ContainerContext.Provider>
  );
};

export default ContainerProvider;
