import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CheckoutComponent.module.css';

export interface CheckoutComponentProps {
  title: string;
  description?: string;
}

const CheckoutComponent: FC<CheckoutComponentProps> = ({
  title,
  description,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.header}>
        <div>
          <h2 className={styles.title}>{title}</h2>
          {description && (
            <span className={styles.description}>{description}</span>
          )}
        </div>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default CheckoutComponent;
