import { Field } from 'formik';
import React, { ChangeEventHandler, FC, ReactNode } from 'react';
import InputField from '../../../common/components/inputs/InputField';
import ContactInfoField from './ContactInfoField';

type ContactInfoInputProps = {
  error: string | undefined;
  showValidationPopup: boolean;
  onCloseValidationPopup: () => void;
  inputName: string;
  label: string;
  validationPopupPosition: 'bottom' | 'top';
  inputType?: string;
  errorComponent?: (error: string) => ReactNode;
  onOverrideChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
};

const ContactInfoInput: FC<ContactInfoInputProps> = (props) => {
  const {
    inputName,
    inputType,
    label,
    error,
    showValidationPopup,
    disabled,
    onOverrideChange,
  } = props;
  const commonFieldProps = {
    as: InputField,
    type: inputType,
    name: inputName,
    label,
    error: error && showValidationPopup,
    hideLabel: false,
    variant: 'MULTILINE',
    disabled,
  };

  return (
    <ContactInfoField {...props}>
      <>
        {onOverrideChange ? (
          <Field onChange={onOverrideChange} {...commonFieldProps} />
        ) : (
          <Field {...commonFieldProps} />
        )}
      </>
    </ContactInfoField>
  );
};

ContactInfoInput.defaultProps = {
  inputType: 'text',
};

export default ContactInfoInput;
