import { FC } from 'react';
import styles from './MeetingSummary.module.css';
import { Moment } from 'moment';
import { ConfirmationState } from '../BookMeeting';
import { useTranslation } from 'react-i18next';
import { MeetingType, IContactInfoDto, ISlotDto, IEmployeeDto } from 'shared';
import { useAuth0 } from '@auth0/auth0-react';
import CreateAccountCallToAction from './CreateAccountCallToAction';
import StepWithNavigation from '../../../common/components/CardWithSteps/StepWithNavigation';
import ButtonComponent from '../../../common/components/buttons/ButtonComponent';
import CardTitle from '../../../common/components/CardWithSteps/CardTitle';
import MeetingBookFailed from './MeetingBookFailed';
import InputField from '../../../common/components/inputs/InputField';
import DesignerCard from '../../components/DesignerCard/DesignerCard';

interface MeetingSummaryProps {
  meetingType: MeetingType;
  location: string | undefined;
  date: Moment;
  confirmationState: ConfirmationState;
  contactInfo: IContactInfoDto | null;
  restartBooking: () => void;
  finishBooking: () => void;
  slot: ISlotDto;
  designer?: IEmployeeDto | null;
  additionalComment?: string;
  onCommentChange?: (notes: string) => void;
}

const MeetingSummary: FC<MeetingSummaryProps> = ({
  meetingType,
  location,
  contactInfo,
  date,
  confirmationState,
  restartBooking,
  finishBooking,
  designer,
  slot,
  additionalComment,
  onCommentChange,
}) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth0();

  if (confirmationState === 'FAILED') {
    return <MeetingBookFailed restartBooking={restartBooking} />;
  }

  return (
    <>
      <CardTitle
        title={
          confirmationState === 'TO_CONFIRM'
            ? t('meeting.summary.title')
            : t('meeting.summary.confirmedMain')
        }
      />
      <StepWithNavigation
        hideNavigation={confirmationState === 'CONFIRMED'}
        showCancel={true}
        showFinish={true}
        goBackText={t('meeting.summary.previous', 'Previous')}
        finishActionText={t('meeting.summary.confirm')}
      >
        {designer && (
          <DesignerCard
            designer={designer}
            meetingType={meetingType}
            location={location}
            date={date}
            slot={slot}
          />
        )}
        <div className={styles.container}>
          {confirmationState === 'TO_CONFIRM' && (
            <div className={styles.itemContainer}>
              <InputField
                variant='MULTILINE'
                name='additionalComment'
                label={t('meeting.summary.comment', 'Additional comment')}
                placeholder={t('meeting.summary.comment', 'Additional comment')}
                value={additionalComment || ''}
                onChange={(e) => onCommentChange?.(e.target.value)}
              />
            </div>
          )}
          {confirmationState === 'CONFIRMED' && (
            <h3 className={styles.confirmed}>
              {t('meeting.summary.confirmedMessage')}
            </h3>
          )}
          {confirmationState === 'CONFIRMED' && (
            <CreateAccountCallToAction email={contactInfo?.email} />
          )}
          {confirmationState === 'CONFIRMED' && additionalComment && (
            <div className={styles.itemContainer}>
              <h4 className={styles.title}>
                {t('meeting.summary.comment', 'Additional comment')}
              </h4>
              <p className={styles.comment}>{additionalComment}</p>
            </div>
          )}
          {isAuthenticated && confirmationState === 'CONFIRMED' && (
            <div className={styles.backToHome}>
              <ButtonComponent
                colorScheme='DARK'
                variant={'TEXT'}
                size='BIG'
                onClick={finishBooking}
              >
                {t('meeting.summary.homePage')}
              </ButtonComponent>
            </div>
          )}
        </div>
      </StepWithNavigation>
    </>
  );
};

export default MeetingSummary;
