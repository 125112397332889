import { FC } from 'react';
import ComponentWithHeader from '../common/components/ComponentWithHeader';
import Dashboard from './Dashboard';
import DashboardIntro from './DashboardIntro';

const DashboardWithHeader: FC = () => {
  return (
    <ComponentWithHeader
      component={<Dashboard />}
      beforeContent={<DashboardIntro />}
      isHeaderBorder={false}
    />
  );
};

export default DashboardWithHeader;
