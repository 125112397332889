import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../common/components/buttons/ButtonComponent';
import styles from './OrderNavigation.module.css';

export interface OrderNavigationProps {
  onCancelOrder?: () => void;
  onPreviousStep?: () => void;
  onNextStep?: () => void;
  canGoNext?: () => boolean | Promise<boolean>;
  nextStepText?: string;
}

const OrderNavigation: FC<OrderNavigationProps> = ({
  onCancelOrder,
  onPreviousStep,
  onNextStep,
  canGoNext,
  nextStepText,
}) => {
  const { t } = useTranslation();

  const handleGoToNextStep = async () => {
    if (!canGoNext || (await canGoNext())) {
      if (onNextStep) onNextStep();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        {onPreviousStep && (
          <ButtonComponent
            variant='OUTLINE'
            colorScheme='LIGHT'
            size='BIG'
            onClick={onPreviousStep}
          >
            {t('order.navigation.previous', 'Previous step')}
          </ButtonComponent>
        )}
      </div>
      <div className={styles.right}>
        <ButtonComponent
          variant='OUTLINE'
          colorScheme='LIGHT'
          size='SMALL'
          onClick={onCancelOrder}
        >
          {t('order.navigation.cancel', 'Cancel order')}
        </ButtonComponent>
        {onNextStep && (
          <ButtonComponent
            variant='FILLED_IN'
            colorScheme='DARK'
            size='SMALL'
            onClick={handleGoToNextStep}
          >
            {nextStepText
              ? nextStepText
              : t('order.navigation.next', 'Next step')}
          </ButtonComponent>
        )}
      </div>
    </div>
  );
};

export default OrderNavigation;
